import React, { Component, useState, useCallback } from "react";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import getStyles from "styles/SubPageHeader";
import { MenuButton, BackButton } from "elements/Buttons";
// import NotificationIcon from "./NotificationIcon";
import { View, Box, Button, Text, Icon } from "native-base";
import { AntDesign } from "@expo/vector-icons";
import theme from "services/theme";

export default function SubPageHeader(props) {
  const navigation = useNavigation();
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  const goBack = () => {
    try {
      navigation.navigate("Games");
    } catch (ex) {}
  };

  const openLeftMenu = () => {
    navigation.openDrawer();
  };

  return (
    <Box
      style={[styles.subpageHeader, props.style ? props.style : {}]}
      onLayout={onLayoutChange}
    >
      <Button
        style={styles.backIcon}
        onPress={props.openLeftMenuOnBack ? openLeftMenu : goBack}
      >
        <Icon
          as={<AntDesign name="left" />}
          size="sm"
          m={2}
          color={props.invertedColors ? theme.buttonTextColor : theme.textColor}
        />
      </Button>
      <Text
        style={[
          styles.pageName,
          {
            color: props.invertedColors
              ? theme.buttonTextColor
              : theme.textColor,
          },
          props.rightIcon ? styles.pageNameWithRightIcon : {},
        ]}
      >
        {props.pageName}
      </Text>
      {props.rightIcon}
    </Box>
  );
}
