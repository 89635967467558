import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import { orientation } from "services/scaleFactor";

export default function getStyles() {
  var styles = {
    fullWidth: {
      width: Platform.OS === "web" ? "100vw" : "100%",
    },
    fullHeight: {
      height: Platform.OS === "web" ? window.innerHeight : "100%",
    },
    ...Platform.select({
      web: {
        webView: {
          flex: 1,
          border: "none",
          backgroundColor: "white",
          marginTop: 0,
        },
      },
      default: {
        webView: {
          flex: 1,
          backgroundColor: "white",
          marginTop: 0,
        },
      },
    }),
  };

  if (Platform.OS !== "web") {
    styles = StyleSheet.create(styles);
  }

  return styles;
}

// export default styles;
