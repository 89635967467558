import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";

export default function getStyles() {
  const gameImgWidth = (windowWidth() - 10) / 2 - 10;
  const gameImgHeight = gameImgWidth * 0.65;
  const buttonHeight = 40;
  const buttonGap = 10;
  const buttonsTop = (gameImgHeight - buttonHeight * 2 - buttonGap) / 2 + 3;
  const singleButonTop = (buttonGap + buttonHeight) / 2;

  var styles = StyleSheet.create({
    singleGame: {
      width: "50%",
      padding: 5,
    },
    gameImg: {
      width: gameImgWidth,
      height: gameImgHeight,
      borderRadius: 10,
    },
    gameButtons: {
      position: "absolute",
      top: buttonsTop,
      left: 0,
      right: 0,
    },
    button: {
      width: "70%",
      height: 40,
      borderWidth: 1,
      borderColor: theme.darkerColor,
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
    lastButton: {
      marginTop: buttonGap,
    },
    singleButton: {
      marginTop: singleButonTop,
    },
    buttonText: {
      fontFamily: "GilroyLight",
      fontSize: 14,
      color: theme.lighterColor,
    },
    gameName: {
      fontFamily: "GilroyLight",
      fontSize: 14,
      color: theme.textColor,
      textAlign: 'center',
      marginTop: 4
    },
  });

  return styles;
}
