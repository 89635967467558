import ls from "services/ls";

class AUTH {
  constructor() {
    ls.on("userObj", async (value) => {
      if (!(await this.isAuthenticated())) {
        console.log("Logged Out");
      } else {
        console.log("Logged In");
      }
    });
  }

  async login(userObj) {
    if (userObj.userId) {
      var ts = Math.round(new Date().getTime() / 1000);
      //10 seconds less for safety time.
      //Otherwise, frontend may send a request with a near expired token
      //which will be expired by the time it reaches the server
      userObj.expiry = ts + userObj.lifetime - 10;
      ls.set("userObj", userObj);
      return true;
    } else {
      return false;
    }
  }

  async isAuthenticated() {
    let userObj = await ls.get("userObj");
    if (userObj && userObj.userId) {
      return true;
    }
    return false;
  }

  async isTokenExpired() {
    let userObj = await ls.get("userObj");
    if (userObj && userObj.accessToken) {
      var ts = Math.round(new Date().getTime() / 1000);
      if (ts > userObj.expiry) {
        return true;
      }
    }
    return false;
  }

  async getUserId() {
    let userObj = await ls.get("userObj");
    if (userObj && userObj.userId) {
      return userObj.userId;
    }
    return null;
  }

  async getUser() {
    let userObj = await ls.get("userObj");
    if (userObj && userObj.userId) {
      return userObj;
    }
    return null;
  }

  async getAccessToken() {
    let userObj = await ls.get("userObj");
    if (userObj && userObj.accessToken) {
      return userObj.accessToken;
    }
    return null;
  }

  async getRefreshToken() {
    let userObj = await ls.get("userObj");
    if (userObj && userObj.refreshToken) {
      return userObj.refreshToken;
    }
    return null;
  }

  async getUserFullname() {
    let userObj = await ls.get("userObj");
    if (userObj && userObj.userId) {
      return userObj.name;
    }
    return null;
  }

  logout() {
    ls.remove("userObj");
  }
}

export default new AUTH();
