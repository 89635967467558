// var configEnv = "development";
// var configEnv = "staging";
var configEnv = "production";

var config = {
  //##########Live config##########
  production: {
    appUrl: "https://xbitplatform.com",
    apiUrl: "https://api.xbitplatform.com/api/v1",
    staticUrl: "https://api.xbitplatform.com/api/v1",
    liveChatUrl:
      "https://bossoffice.xbitplatform.com/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "https://bossoffice.blizz.io/public",
    socketUrl: "wss://api.xbitplatform.com:7002",
    debug: false,
  },

  //#########Stage config##########
  staging: {
    apiUrl: "https://xbit.chimptestserver.com",
    apiUrl: "https://xbitapi.chimptestserver.com/api/v1",
    staticUrl: "https://xbitapi.chimptestserver.com/api/v1",
    liveChatUrl:
      "https://xbitbossoffice.chimptestserver.com/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "https://xbitbossoffice.chimptestserver.com/public",
    socketUrl: "wss://xbitapi.chimptestserver.com:7002",
    debug: false,
  },

  //##########Local config##########
  development: {
    appUrl: "http://localhost",
    apiUrl: "http://localhost/casinoapi/api/v1",
    staticUrl: "http://localhost/casinoapi/api/v1",
    liveChatUrl:
      "http://localhost/casinoadmin/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "http://localhost/casinoadmin/public",
    socketUrl: "ws://localhost:7002",
    debug: true,
  },
};

var finalConfig = config[configEnv];

finalConfig.defaultLanguage = {
  code: "en",
  language: "English",
  flag_path: finalConfig.fileSourceUrl + "/images/flags/svg/gb.svg",
};

export default finalConfig;
