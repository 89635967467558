import AsyncStorage from "@react-native-async-storage/async-storage";

class LS {
  constructor() {
    this.watches = {};
  }

  on(key, cb) {
    if (!this.watches[key]) {
      this.watches[key] = [];
    }
    this.watches[key].push(cb);
  }

  off(key) {
    if (this.watches[key]) {
      delete this.watches[key];
    }
  }

  async get(key) {
    try {
      const value = await AsyncStorage.getItem(key);
      var newvalue = null;
      if (value !== null && value !== undefined) {
        var newvalue = JSON.parse(value);
      }
      return newvalue;
    } catch (e) {
      // error reading value
      return null;
    }
  }

  async set(key, value) {
    try {
      var prevVal = this.get(key);
      await AsyncStorage.setItem(key, JSON.stringify(value));
      if (prevVal !== value && this.watches[key] && this.watches[key].length) {
        for (var func of this.watches[key]) {
          func.call(this, value, prevVal);
        }
      }
      return true;
    } catch (e) {
      // saving error
      return false;
    }
  }

  async remove(key) {
    try {
      return await this.set(key, null);
    } catch (e) {
      // saving error
      return false;
    }
  }
}

export default new LS();
