import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import {
  windowHeight,
  windowWidth,
  scaleFactor,
  orientation,
} from "services/scaleFactor";

export default function getStyles() {
  var styles = StyleSheet.create({
    header: {
      flexDirection: "row",
      paddingBottom: scaleFactor(4, 5),
      paddingTop: scaleFactor(4, 8),
      paddingBottom: scaleFactor(4, 8),
      paddingLeft: scaleFactor(10, 15),
      paddingRight: scaleFactor(10, 15),
      // height: scaleFactor(52, 60),
      height: 52,
      borderColor: theme.shadowColor,
      borderWidth: 0,
      borderBottomWidth: 1,
    },
    mainMenuButton: {
      backgroundColor: theme.lighterColor,
      borderRadius: 50,
      width: scaleFactor(40, 45),
      height: scaleFactor(40, 45),
    },
    icon: {
      color: theme.darkerColor,
      fontSize: scaleFactor(12, 19),
      fontWeight: "700",
      position: "relative",
      marginLeft: "auto",
      marginRight: "auto",
    },
    headerTextBox: {
      width: windowWidth() - scaleFactor(40, 45) * 2,
      height: 52,
      textAlign: "center",
    },
    headerText: {
      fontFamily: "GilroyLight",
      fontSize: 20,
      lineHeight: 45,
      textAlign: "center",
    },
  });
  return styles;
}
