import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import {
  windowHeight,
  windowWidth,
  scaleFactor,
  scrollViewStyle,
} from "services/scaleFactor";

export default function getStyles() {
  var styles = StyleSheet.create({
    flexBox: {
      flexDirection: "row",
    },
    fullHeight: {
      height: Platform.OS === "web" ? window.innerHeight : "100%",
    },
    fullWidth: {
      width: Platform.OS === "web" ? "100vw" : "100%",
    },
    levelProgress: {
      backgroundColor: theme.lighterColor,
      width: "100%",
      paddingTop: 15,
      paddingBottom: 25,
      paddingLeft: 15,
      paddingRight: 15,
    },
    levelRow: {},
    progressBar: {
      width: "80%",
      marginLeft: "10%",
      marginTop: 3,
    },
    levelHeading: {
      fontFamily: "GilroyLight",
      fontSize: 17,
    },
    progressPercentage: {
      textAlign: "center",
      fontFamily: "GilroyLight",
      fontSize: 14,
      marginTop: 3,
    },
    form: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    stepHeading: {
      fontFamily: "GilroyLight",
      fontSize: 18,
      color: theme.textColor,
      marginTop: 15,
    },
    bonusOfferBox: {
      borderRadius: 10,
      overflow: "hidden",
      borderWidth: 1,
      borderColor: theme.primaryColor,
      marginTop: 15,
    },
    inputHelpText: {
      fontFamily: "GilroyLight",
      fontSize: 12,
      color: theme.textColor,
      paddingLeft: 5,
      paddingRight: 13,
      marginTop: 4,
    },
    submitBtn: {
      color: theme.buttonTextColor,
      fontFamily: "GilroyLight",
      fontSize: 13,
      marginTop: scaleFactor(10),
      marginBottom: scaleFactor(10),
      paddingLeft: 60,
      paddingRight: 60,
      borderRadius: 15,
    },
    divider: {
      marginTop: 20,
      marginBottom: 20,
    },
  });

  return styles;
}
