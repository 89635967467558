import React, { useState, forwardRef, useRef, useEffect, memo } from "react";
import { StyleSheet, Platform } from "react-native";
import { Text, Select, Box, CheckIcon } from "native-base";
import theme from "services/theme";

const SelectBox = (props, ref) => {
  var styles = {
    inputBox: {
      width: "100%",
      paddingTop: props.noPadding ? 0 : Platform.OS === "web" ? "9px" : 12,
      marginTop: props.label ? 5 : 0,
    },
    input: {
      width: "100%",
      borderColor: theme.primaryColor,
      color: theme.inputTextColor,
      height: 45,
      fontSize: 15
    },
    inputFocused: {
      color: theme.inputTextColor,
      width: "100%",
      borderColor: theme.darkerColor,
    },
    label: {
      fontSize: 14,
      color: theme.primaryColor,
      position: "absolute",
      top: 0,
      marginLeft: 30,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 5,
      paddingRight: 5,
      backgroundColor: "white",
      zIndex: 4,
    },
  };

  styles = StyleSheet.create(styles);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (Platform.OS === "web") {
      wrapperRef.current.style.borderWidth = 0;
    }
  }, [wrapperRef]);

  return (
    <Box style={styles.inputBox}>
      {props.label && <Text style={styles.label}>{props.label}</Text>}
      <Select
        ref={ref}
        variant="rounded"
        wrapperRef={wrapperRef}
        borderWidth={1}
        borderColor={theme.primaryColor}
        style={styles.input}
        _selectedItem={{
          bg: theme.shadowColor,
          endIcon: <CheckIcon size={4} />,
        }}
        {...props}
      >
        {props.items.map((item, index) => {
          return (
            <Select.Item label={item.label} value={item.value} key={index} />
          );
        })}
      </Select>
    </Box>
  );
};

export default memo(forwardRef(SelectBox));
