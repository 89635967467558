import React, { Component, useState, useEffect, useMemo } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Image, Platform, SafeAreaView } from "react-native";
import { StdAlert, BinaryAlert } from "elements/Alert";
import getStyles from "styles/Login";
import theme from "services/theme";
import api from "services/api";
import {
  View,
  Center,
  Button,
  Text,
  Icon,
  Input,
  Box,
  KeyboardAvoidingView,
} from "native-base";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";
import { AntDesign } from "@expo/vector-icons";

import Hr from "elements/Hr";
import InputBox from "elements/InputBox";
import AppHeaderLight from "elements/AppHeaderLight";
import FullHeightScrollView from "elements/FullHeightScrollView";
import Spinner from "elements/Spinner";

export default function ActivateAccount(props) {
  const [styles, setStyles] = useState(getStyles());
  const [email, setEmail] = useState(
    props.route && props.route.params && props.route.params.email
      ? props.route.params.email
      : ""
  );
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
const { __ } = React.useContext(require("contexts/LangContext").default);
  const navigation = props.navigation;

  const gotoLogin = () => {
    navigation.navigate("Login");
  };

  const [spinner, setSpinner] = useState(false);

  const resendActivationCode = async () => {
    setSpinner(true);
    var response = await api.post("/resend-activation-mail", { email: email });
    setSpinner(false);
    if (response.status === 200) {
      StdAlert("Success!!", response.data.message, () => {
        navigation.navigate("Login");
      });
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={spinner} />
        <AppHeaderLight pageName={__("Activate Account")} />
        <FullHeightScrollView style={styles.scrollView}>
          <Center height={windowHeight() - 80} width={windowWidth()}>
            <View style={[styles.fullWidth, styles.form]}>
              <Box style={styles.mainBlock}>
                <Text style={styles.mainHeader}>{__("Registration Complete")}</Text>
                <Text style={styles.mainText}>
                  {__("A confirmation email has been sent to the email address you have listed in your profile. Please follow the instructions in that email in order to activate your account.")}
                </Text>
                <Text style={styles.mainText}>
                  {__("If you do not receive the email within a few minutes, please check your email spam folder to check if the message did not end up there. You can have the email send again by using the field below.")}
                </Text>
              </Box>

              <InputBox
                onChangeText={(value) => {
                  setEmail("email");
                }}
                color={theme.color}
                label="Email"
                value={email}
                placeholder={__("Email")}
              />

              <Center width="100%">
                <Button onPress={resendActivationCode} style={styles.submitBtn}>
                  {__("Re-send activation email")}
                </Button>
              </Center>

              <Hr style={styles.divider} />

              <Center width="100%">
                <Text style={styles.register}>
                  {__("Already activated?")}{" "}
                  <Text style={styles.registerBtn} onPress={gotoLogin}>
                    {__("LOG IN")}
                  </Text>
                </Text>
              </Center>
            </View>
          </Center>
        </FullHeightScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
