import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";

export default function getStyles() {
  const styles = StyleSheet.create({
    subpageHeader: {
      flexDirection: "row",
      width: "100%",
    },
    backIcon: {
      zIndex: 4,
      fontSize: 18,
      width: 40,
      height: 40,
      backgroundColor: "transparent",
    },
    pageName: {
      fontFamily: "GilroyLight",
      fontSize: 20,
      lineHeight: 38,
      marginRight: 40,
      textAlign: "center",
      width: windowWidth() - 80,
    },
    pageNameWithRightIcon: {
      marginRight: 0,
      width: windowWidth() - 100,
    },
  });

  return styles;
}
