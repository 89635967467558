import React from "react";
import { ScrollView } from "react-native";
import { View } from "native-base";
import { windowHeight } from "services/scaleFactor";

export default function FullHeightScrollView(props) {
  return (
    <ScrollView style={props.style}>
      <View style={{ minHeight: windowHeight() }}>{props.children}</View>
    </ScrollView>
  );
}
