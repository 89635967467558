import React, { useState, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { SafeAreaView, ScrollView } from "react-native";
import RenderHtml from "elements/RenderHtml";
import getStyles from "styles/Promotions";
import api from "services/api";
import theme from "services/theme";
import {
  View,
  Center,
  Button,
  Text,
  Box,
  KeyboardAvoidingView,
  Accordion,
} from "native-base";
import { StdAlert } from "elements/Alert";
import Hr from "elements/Hr";
import SubPageHeader from "elements/SubPageHeader";
import Image from "elements/Image";
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";
import Spinner from "elements/Spinner";

export default function Promotions(props) {
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  const { __ } = React.useContext(require("contexts/LangContext").default);

  const navigation = props.navigation;

  const [spinner, setSpinner] = useState(false);

  const [selectedTab, setSelectedTab] = useState("welcome"); //welcome, current
  const [pageContent, setPageContent] = useState({});
  const [promotions, setPromotions] = useState([]);
  const [bonusSchedules, setBonusSchedules] = useState({});

  const getBonusSchedules = async () => {
    setSpinner(true);
    var response = await api.get("/bonus-schedule");
    setSpinner(false);
    if (response.status === 200) {
      setBonusSchedules(response.data);
    } else {
      setSelectedTab("welcome");
      StdAlert("Message!!", response.data.message);
    }
  };

  const getPromotions = async () => {
    setSpinner(true);
    var response = await api.get("/promotions");
    setSpinner(false);
    if (response.status === 200) {
      setPageContent(response.data.content_data);
      setPromotions(response.data.promotions);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const togglePromoText = (index) => {
    var promosTmp = JSON.parse(JSON.stringify(promotions));
    promosTmp[index].expanded = !promosTmp[index].expanded;
    setPromotions(promosTmp);
  };

  const gotoSignup = () => {
    navigation.navigate("SignUp");
  };
  const gotoBonusTerms = () => {
    navigation.navigate("Bonus Terms");
  };

  useFocusEffect(
    useCallback(() => {
      getPromotions();
    }, [])
  );

  const gotoBonusSchedules = () => {
    setSelectedTab("current");
    getBonusSchedules();
  };
  const gotoPromotions = () => {
    setSelectedTab("welcome");
    getPromotions();
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={spinner} />
        <Box style={styles.fullHeight}>
          <AppHeader />
          <ScrollView>
            <SubPageHeader
              pageName={__("Promotions")}
              style={styles.subpageHeader}
              openLeftMenuOnBack={true}
            />

            <Center width="100%">
              <View style={styles.tabs}>
                <Button
                  colorScheme="clear"
                  onPress={gotoPromotions}
                  style={[
                    styles.tab,
                    selectedTab === "welcome" ? styles.tabActive : {},
                  ]}
                >
                  <Text
                    style={[
                      styles.tabText,
                      selectedTab === "welcome" ? styles.tabTextActive : {},
                    ]}
                  >
                    {__("Welcome Bonuses")}
                  </Text>
                </Button>
                <Button
                  colorScheme="clear"
                  onPress={gotoBonusSchedules}
                  style={[
                    styles.tab,
                    selectedTab === "current" ? styles.tabActive : {},
                  ]}
                >
                  <Text
                    style={[
                      styles.tabText,
                      selectedTab === "current" ? styles.tabTextActive : {},
                    ]}
                  >
                    {__("Current Bonuses")}
                  </Text>
                </Button>
              </View>
            </Center>

            {selectedTab === "welcome" && (
              <React.Fragment>
                <Box style={styles.promotionsMain}>
                  <Text style={styles.promotionsMainHeader}>
                    {__("Welcome Bonuses")}
                  </Text>
                  <RenderHtml
                    style={styles.promotionsMainText}
                    html={pageContent.content}
                  />
                  <Hr />
                </Box>

                {promotions.map((promo, index) => {
                  var details = promo.details;
                  var shortText = promo.details.substr(0, 210);
                  var showMore = true;
                  if (details === shortText) {
                    showMore = false;
                  }

                  return (
                    <Box key={index} style={styles.promotionsSection}>
                      <Image
                        style={styles.promotionSectionImage}
                        source={{ uri: promo.image }}
                      />
                      <Text style={styles.promotionSectionHeader}>
                        {promo.title}
                      </Text>

                      {showMore ? (
                        <RenderHtml
                          style={styles.promotionSectionText}
                          html={promo.expanded ? details : shortText}
                        />
                      ) : (
                        <RenderHtml
                          style={styles.promotionSectionText}
                          html={details}
                        />
                      )}

                      {showMore && (
                        <Text
                          onPress={() => togglePromoText(index)}
                          style={styles.promotionReadMore}
                        >
                          {" "}
                          {promo.expanded ? (
                            __("Read less")
                          ) : (
                            __("Read more")
                          )}
                        </Text>
                      )}

                      <Center
                        width="100%"
                        style={styles.promotionSectionButtons}
                      >
                        <Button
                          onPress={gotoSignup}
                          style={[styles.promotionSectionButton]}
                        >
                          <Text style={[styles.promotionSectionButtonText]}>
                            {__("Claim Bonus")}
                          </Text>
                        </Button>
                        <Button
                          onPress={gotoBonusTerms}
                          style={[styles.promotionSectionButton]}
                        >
                          <Text style={[styles.promotionSectionButtonText]}>
                            {__("Explore Terms")}
                          </Text>
                        </Button>
                      </Center>
                      <Hr />
                    </Box>
                  );
                })}
              </React.Fragment>
            )}

            {selectedTab === "current" && (
              <React.Fragment>
                <Box style={styles.promotionsMain}>
                  <Text style={styles.promotionsMainHeader}>
                    {__("Current Bonuses")}
                  </Text>
                  <Text style={styles.promotionsMainText}>
                    {__("We regularly have bonus offers up for grabs! Please see the promotions page for more information.")}
                  </Text>
                  <Hr />
                </Box>

                {bonusSchedules.x_deposit_bonuses &&
                bonusSchedules.x_deposit_bonuses.length ? (
                  <Box style={styles.promotionsSection}>
                    <Text style={styles.promotionsMainHeader}>
                      {__("X-Deposit Bonus")}
                    </Text>
                    <Accordion allowToggle>
                      {bonusSchedules.x_deposit_bonuses.map((item, index) => (
                        <Accordion.Item key={index}>
                          <Accordion.Summary
                            bg={theme.primaryColor}
                            _expanded={{ backgroundColor: theme.darkerColor }}
                          >
                            {"Deposit Number " + item.deposit_number}
                            <Accordion.Icon />
                          </Accordion.Summary>
                          <Accordion.Details>
                            <Text style={styles.accordionText}>
                              {__("Min Deposit")}:{" "}
                              {item.minimum_deposit}
                            </Text>
                            <Text style={styles.accordionText}>
                              {__("Bonus Amount")}: {item.bonus}
                            </Text>
                            <Text style={styles.accordionText}>
                              {__("Maximum Bonus")}:{" "}
                              {item.maximum_bonus}
                            </Text>
                            <Text style={styles.accordionText}>
                              {__("Free Spins")}:{" "}
                              {item.free_spin_details}
                            </Text>
                          </Accordion.Details>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                    <Hr />
                  </Box>
                ) : null}

                {bonusSchedules.datetime_deposit_bonuses &&
                bonusSchedules.datetime_deposit_bonuses.length ? (
                  <Box style={styles.promotionsSection}>
                    <Text style={styles.promotionsMainHeader}>
                      {__("Date Time Deposit Bonus")}
                    </Text>
                    <Accordion allowToggle>
                      {bonusSchedules.datetime_deposit_bonuses.map(
                        (item, index) => (
                          <Accordion.Item key={index}>
                            <Accordion.Summary
                              bg={theme.primaryColor}
                              _expanded={{ backgroundColor: theme.darkerColor }}
                            >
                              {item.datetime}
                              <Accordion.Icon />
                            </Accordion.Summary>
                            <Accordion.Details>
                              <Text style={styles.accordionText}>
                                {__("Min Deposit")}:{" "}
                                {item.minimum_deposit}
                              </Text>
                              <Text style={styles.accordionText}>
                                {__("Bonus Amount")}: {item.bonus}
                              </Text>
                              <Text style={styles.accordionText}>
                                {__("Maximum Bonus")}:{" "}
                                {item.maximum_bonus}
                              </Text>
                              <Text style={styles.accordionText}>
                                {__("Free Spins")}:{" "}
                                {item.free_spin_details}
                              </Text>
                            </Accordion.Details>
                          </Accordion.Item>
                        )
                      )}
                    </Accordion>
                    <Hr />
                  </Box>
                ) : null}
              </React.Fragment>
            )}

            <View style={[styles.fullWidth, styles.form]}>
              <View style={{ width: "100%", height: 100 }}></View>
            </View>
          </ScrollView>
          <FooterMenu />
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
