import React, { useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Platform, SafeAreaView, ScrollView } from "react-native";
import { WebView } from "react-native-webview";
import getStyles from "styles/Support";
import theme from "services/theme";
import api from "services/api";
import config from "root/config";
import auth from "services/auth";
import ls from "services/ls";
import AppHeader from "elements/AppHeader";
import InputBox from "elements/InputBox";
import FooterMenu from "elements/FooterMenu";
// import FullHeightScrollView from "elements/FullHeightScrollView";
import { StdAlert } from "elements/Alert";
import {
  Center,
  Button,
  Text,
  Box,
  View,
  Accordion,
  KeyboardAvoidingView,
} from "native-base";
import SubPageHeader from "elements/SubPageHeader";
import Spinner from "elements/Spinner";

export default function Support(props) {
  const navigation = props.navigation;
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);
  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [spinner, setSpinner] = useState(false);

  const updateForm = (value, field) => {
    if (user && (field === "email" || field === "name")) {
      return false;
    }
    setForm({ ...form, [field]: value });
  };

  const [user, setUser] = useState(null);

  const getUser = async () => {
    var user = await auth.getUser();
    setUser(user);
  };

  useEffect(() => {
    getUser();
    ls.on("userObj", (newVal) => {
      if (newVal) {
        setUser(newVal);
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      setForm({
        ...form,
        email: user.email,
        name: user.first_name + " " + user.last_name,
      });
    }
  }, [user]);

  const [selectedTab, setSelectedTab] = useState("faq"); //faq, email, chat

  const [launchUrl, setLaunchUrl] = useState(config.liveChatUrl);
  // const [launchUrl, setLaunchUrl] = useState("https://google.com");

  const [faqs, setFaqs] = useState([]);

  const getFaqs = async () => {
    setSpinner(true);
    var response = await api.get("/get-faqs");
    setSpinner(false);
    if (response.status === 200) {
      setFaqs(response.data);
    }
  };

  const sendMail = async () => {
    setSpinner(true);
    var response = await api.post("/create-ticket", form);
    setSpinner(false);
    if (response.status === 200) {
      StdAlert("Success!!", response.data.message);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  useFocusEffect(
    useCallback(() => {
      getFaqs();
    }, [])
  );

  const onWebViewLoaded = (web) => {
    setSpinner(false);
  };
  const onWebViewLoadedIframe = () => {
    setSpinner(false);
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={spinner} />
        <Box style={styles.fullHeight}>
          <AppHeader />
          <Center width="100%">
            <View style={styles.tabs}>
              <Button
                onPress={() => setSelectedTab("faq")}
                style={[
                  styles.tab,
                  selectedTab === "faq" ? styles.tabActive : {},
                ]}
              >
                <Text
                  style={[
                    styles.tabText,
                    selectedTab === "faq" ? styles.tabTextActive : {},
                  ]}
                >
                  {__("FAQ")}
                </Text>
              </Button>

              <Button
                onPress={() => setSelectedTab("email")}
                style={[
                  styles.tab,
                  selectedTab === "email" ? styles.tabActive : {},
                ]}
              >
                <Text
                  style={[
                    styles.tabText,
                    selectedTab === "email" ? styles.tabTextActive : {},
                  ]}
                >
                  {__("Email Us")}
                </Text>
              </Button>

              <Button
                onPress={() => setSelectedTab("chat")}
                style={[
                  styles.tab,
                  selectedTab === "chat" ? styles.tabActive : {},
                ]}
              >
                <Text
                  style={[
                    styles.tabText,
                    selectedTab === "chat" ? styles.tabTextActive : {},
                  ]}
                >
                  {__("Live Chat")}
                </Text>
              </Button>
            </View>
          </Center>
          {selectedTab === "faq" ? (
            <ScrollView>
              <Box>
                <Accordion>
                  {faqs.map((faqCategory, index) => (
                    <Accordion.Item key={index}>
                      <Accordion.Summary
                        bg={theme.primaryColor}
                        _expanded={{ backgroundColor: theme.darkerColor }}
                      >
                        {faqCategory.category}
                        <Accordion.Icon />
                      </Accordion.Summary>
                      <Accordion.Details>
                        <Accordion>
                          {faqCategory.faqs.map((faq, index2) => (
                            <Accordion.Item key={index2}>
                              <Accordion.Summary>
                                {faq.title}
                                <Accordion.Icon />
                              </Accordion.Summary>
                              <Accordion.Details>
                                {faq.description}
                              </Accordion.Details>
                            </Accordion.Item>
                          ))}
                        </Accordion>
                      </Accordion.Details>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </Box>
            </ScrollView>
          ) : selectedTab === "email" ? (
            <ScrollView>
              <Box style={styles.form}>
                <Text style={styles.emailMain}>
                  {__("You can Live Chat with out Support Team. Click on the Live Support tab when openening the support panel. Feel free to ask them any question you have regarding Casino Xbit, your account or (Live) games on the website.")}
                </Text>
                <Text style={styles.emailMain}>
                  {__("If you want to send us a message through our contact form then you can send it from here.")}
                </Text>

                <Box>
                  <InputBox
                    onChangeText={(value) => {
                      updateForm(value, "name");
                    }}
                    color={theme.color}
                    label={__("Name")}
                    value={form.name}
                    placeholder={__("Name")}
                  />
                  <InputBox
                    onChangeText={(value) => {
                      updateForm(value, "email");
                    }}
                    color={theme.color}
                    label={__("Email")}
                    value={form.email}
                    placeholder={__("Email")}
                  />
                  <InputBox
                    onChangeText={(value) => {
                      updateForm(value, "subject");
                    }}
                    color={theme.color}
                    label={__("Subject")}
                    value={form.subject}
                    placeholder={__("Subject")}
                  />
                  <InputBox
                    onChangeText={(value) => {
                      updateForm(value, "message");
                    }}
                    type="textarea"
                    style={{height: '250'}}
                    color={theme.color}
                    label={__("Message")}
                    value={form.message}
                    placeholder={__("Message")}
                  />
                  <Center width="100%">
                    <Button style={styles.submitBtn} onPress={sendMail}>
                      {__("Send")}
                    </Button>
                  </Center>

                  <View style={[styles.fullWidth, styles.form]}>
                    <View style={{ width: "100%", height: 30 }}></View>
                  </View>
                </Box>
              </Box>
            </ScrollView>
          ) : (
            <Box style={styles.webViewBox}>
              {launchUrl
                ? Platform.select({
                    web: (
                      <iframe
                        src={launchUrl}
                        style={styles.webView}
                        onLoad={onWebViewLoadedIframe}
                      />
                    ),
                    default: (
                      <Box style={styles.webView}>
                        <WebView
                          flex={1}
                          source={{
                            uri: launchUrl,
                          }}
                          onLoad={onWebViewLoaded}
                        />
                      </Box>
                    ),
                  })
                : null}
            </Box>
          )}

          <FooterMenu />
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
  // <Trans line="(.*)" />
  // {__("$1")}
}
