import React, { useEffect, useState, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import auth from "services/auth";
import api from "services/api";
import Translator from "services/Translator";
import { VStack, Select } from "native-base";

export default function LanguageSwitcher(props) {
  const switchLanguage = (lang) => {
    // var homeUrl = history.createHref({ pathname: "/" });
    // var currentUrl = window.location.href;
    // var newUrl = currentUrl.replace(homeUrl, "/" + lang.code + "/");
    Translator.setSelectedLanguage(lang);
    setSelectedLanguage(lang);
    // window.location.href = newUrl;

    if (props.onChangeLanguage) {
      props.onChangeLanguage(lang);
    }
  };

  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState({});

  const getSelectedLanguage = async () => {
    var lang = await Translator.getSelectedLanguage();
    setSelectedLanguage(lang);
  };

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      setLanguages(response.data);
    }
  };

  useFocusEffect(
    useCallback(() => {
      getSelectedLanguage();
      getLanguages();
    }, [])
  );

  return (
    <Select
      style={[props.style]}
      selectedValue={JSON.stringify(selectedLanguage)}
      minWidth="200"
      accessibilityLabel={
        selectedLanguage.alias
          ? selectedLanguage.alias
          : selectedLanguage.language
      }
      placeholder={selectedLanguage.alias ?? selectedLanguage.language}
      mt={1}
      onValueChange={(itemValue) => switchLanguage(JSON.parse(itemValue))}
    >
      {languages.map((item, index) => (
        <Select.Item
          key={index}
          label={item.alias ?? item.language}
          value={JSON.stringify(item)}
        />
      ))}
    </Select>
  );
}
