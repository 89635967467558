import React, { useRef, useEffect } from "react";
import { StyleSheet, Animated, Easing, Platform } from "react-native";
import Image from "elements/Image";
import { View, Text, Center } from "native-base";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";
import Logo from "assets/images/logo.svg";

export default function Spinner({ show }) {
  const anim = useRef(new Animated.Value(1)).current; // Initial value for opacity: 0

  const spinnerWidth = windowWidth() / 5;
  const spinnerHeight = spinnerWidth * 0.66;

  useEffect(() => {
    var animation = Animated.loop(
      Animated.sequence([
        Animated.timing(anim, {
          toValue: 0.5,
          duration: 1000,
          useNativeDriver: false,
          easing: Easing.linear(),
        }),
        Animated.timing(anim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: false,
          easing: Easing.linear(),
        }),
      ])
    );
    animation.start();
    return () => {
      animation.stop();
    };
  }, [anim, show]);

  return show ? (
    <View style={styles.container}>
      <Animated.View // Special animatable View
        style={{
          opacity: anim, // Bind opacity to animated value
        }}
      >
        <Center width={windowWidth()} height={windowHeight()}>
          <View
            style={{
              width: parseInt(spinnerWidth),
              height: parseInt(spinnerHeight),
            }}
          >
            <Image source={Logo} type="svg" style={styles.spinnerImg} />
          </View>
        </Center>
      </Animated.View>
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      web: { position: "fixed", width: "100vw", height: "100vh" },
      default: { position: "absolute", height: "100%", width: "100%" },
    }),
    flex: 1,
    top: 0,
    left: 0,
    zIndex: 100,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
  },
  spinnerView: {},
  spinnerImg: {
    width: "100%",
    height: "100%",
  },
});
