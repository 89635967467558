import React from "react";
import { View, Text } from "native-base";
import { StyleSheet, Platform } from "react-native";

export default function ProgressBar(props) {
  const color = props.color ? props.color : "#000000";
  // "#C3B39A"
  return (
    <View
      style={[
        styles.progressBarContainer,
        { borderColor: color },
        props.style ? props.style : {},
      ]}
    >
      <View
        style={[
          styles.progressBar,
          { width: (props.size / 100) * 102 + "%", backgroundColor: color },
        ]}
      ></View>
    </View>
  );
}

const styles = StyleSheet.create({
  progressBarContainer: {
    width: Platform.OS === "web" ? "60vw" : "60%",
    height: 8,
    borderWidth: 1,
    borderRadius: 5,
    marginLeft: Platform.OS === "web" ? "20vw" : "20%",
    marginTop: 20,
  },
  progressBar: {
    height: 8,
    position: "absolute",
    top: -1,
    left: -1,
    borderRadius: 5,
  },
});
