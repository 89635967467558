import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";

export default function getStyles() {
  var styles = StyleSheet.create({
    fullHeight: {
      height: Platform.OS === "web" ? window.innerHeight : "100%",
    },
    fullWidth: {
      width: Platform.OS === "web" ? "100vw" : "100%",
    },
    scrollView: {
      height: windowHeight() - 52,
    },
    mainBlock: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    mainHeader: {
      fontFamily: "GilroyLight",
      fontSize: 18,
      marginBottom: 8,
    },
    mainText: {
      fontFamily: "GilroyRegular",
      fontSize: 15,
      marginBottom: 40,
    },
    form: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    forgotPass: {
      fontFamily: "GilroyLight",
      fontSize: 14,
      color: theme.primaryColor,
      marginTop: 10,
      textAlign: "right",
    },
    submitBtn: {
      color: theme.buttonTextColor,
      fontFamily: "GilroyLight",
      fontSize: 13,
      marginTop: 35,
      paddingLeft: 50,
      paddingRight: 50,
      borderRadius: 15,
    },
    divider: {
      marginTop: 50,
    },
    register: {
      fontFamily: "GilroyLight",
      fontSize: 14,
      color: theme.textColor,
      marginTop: 10,
    },
    registerBtn: {
      fontFamily: "GilroyLight",
      fontSize: 14,
      color: theme.primaryColor,
    },
  });

  return styles;
}
