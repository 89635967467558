import React, { useState, useCallback } from "react";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { StyleSheet } from "react-native";
import { Button, Text, Center } from "native-base";
import theme from "services/theme";
import auth from "services/auth";

export default function SignUpButton() {
  const navigation = useNavigation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const getIsLoggedIn = async () => {
    var loggedIn = await auth.isAuthenticated();
    setIsLoggedIn(loggedIn);
  };

  const gotoSignUp = () => {
    navigation.navigate("SignUp");
  };

  useFocusEffect(
    useCallback(() => {
      try {
        getIsLoggedIn();
      } catch (ex) {}
    }, [])
  );

  return (
    <React.Fragment>
      {isLoggedIn ? (
        <Text></Text>
      ) : (
        <Center width="100%">
          <Button onPress={gotoSignUp} style={styles.registerBtn}>
            <Text style={styles.registerBtnText}>SIGN UP</Text>
          </Button>
        </Center>
      )}
    </React.Fragment>
  );
}

const styles = StyleSheet.create({
  registerBtn: {
    paddingLeft: 40,
    paddingRight: 40,
    marginTop: 20,
    borderRadius: 20,
  },
  registerBtnText: {
    fontFamily: "GilroyLight",
    fontSize: 14,
    color: theme.buttonTextColor,
  },
});
