import React, { Component, useState, useEffect } from "react";
import { Image, Platform, SafeAreaView } from "react-native";
import getStyles from "styles/Login";
import theme from "services/theme";
import api from "services/api";
import auth from "services/auth";
import ls from "services/ls";
import {
  View,
  Center,
  Button,
  Text,
  Input,
  Box,
  KeyboardAvoidingView,
} from "native-base";

import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";
import AppContext from "contexts/AppContext";
import Hr from "elements/Hr";
import InputBox from "elements/InputBox";
import AppHeaderLight from "elements/AppHeaderLight";
import FullHeightScrollView from "elements/FullHeightScrollView";
import Spinner from "elements/Spinner";

export default function Login(props) {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const navigation = props.navigation;
  const { __ } = React.useContext(require("contexts/LangContext").default);
  const goToLanding = () => {
    navigation.navigate("Landing");
  };

  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    setForm({
      email: "",
      password: "",
    });
  }, []);

  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const gotoForgotPassword = () => {
    navigation.navigate("Forgot Password");
  };

  const gotoSignup = () => {
    navigation.navigate("SignUp");
  };

  const doLogin = async () => {
    setSpinner(true);
    var response = await api.post("/login", form);
    setSpinner(false);
    if (response.status === 200) {
      await auth.login(response.data.user);
    } else if (response.status === 423) {
      navigation.navigate("Activate Account", {
        email: form.email,
      });
    } else {
      alert(response.data.message);
    }
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={spinner} />
        <AppHeaderLight pageName={__("LOG IN")} />
        <FullHeightScrollView style={styles.scrollView}>
          <Center height={windowHeight()} width={windowWidth()}>
            <View style={[styles.fullWidth, styles.form]}>
              <InputBox
                onChangeText={(value) => {
                  updateForm(value, "email");
                }}
                color={theme.color}
                label={__("Email")}
                value={form.email}
                placeholder={__("Email")}
              />

              <InputBox
                onChangeText={(value) => {
                  updateForm(value, "password");
                }}
                color={theme.color}
                label={__("Password")}
                placeholder={__("Password")}
                type="password"
                value={form.password}
              />

              <Text style={styles.forgotPass} onPress={gotoForgotPassword}>
                {__("Forgot Password?")}
              </Text>

              <Center width="100%">
                <Button style={styles.submitBtn} onPress={doLogin}>
                  {__("Log In")}
                </Button>
              </Center>

              <Hr style={styles.divider} />

              <Center width="100%">
                <Text style={styles.register}>
                  {__("Don’t have an account?")}{" "}
                  <Text style={styles.registerBtn} onPress={gotoSignup}>
                    {__("SIGN UP")}
                  </Text>
                </Text>
              </Center>
            </View>
          </Center>
        </FullHeightScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
