import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";

export default function getStyles() {
  var styles = {
    flexBox: {
      flexDirection: "row",
    },
    fullWidth: {
      width: Platform.OS === "web" ? "100vw" : "100%",
    },
    fullHeight: {
      height: Platform.OS === "web" ? window.innerHeight : "100%",
    },
    form: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    accordionText: {
      fontFamily: "GilroyRegular",
      fontSize: 15,
      color: theme.textColor,
      lineHeight: 20,
      paddingBottom: 10,
    },
    accordionTextBold: {
      fontFamily: "GilroyExtraBold",
      fontSize: 15,
      color: theme.textColor,
      lineHeight: 20,
      paddingBottom: 10,
    },
    idUploadSection: {
      marginBottom: 20,
    },
    idUploadBox: {
      position: "relative",
      marginBottom: 10,
    },
    bulb: {
      position: "absolute",
      right: 10,
      top: 10,
      width: 20,
      height: 20,
      borderRadius: 50,
    },
    redBulb: {
      backgroundColor:
        "radial-gradient(circle, rgba(228,10,10,1) 7%, rgba(85,24,2,1) 80%, rgba(246,171,144,0.42107265269389005) 100%);",
    },
    yellowBulb: {
      backgroundColor:
        "radial-gradient(circle, rgba(228,170,10,1) 7%, rgba(167,145,6,1) 80%, rgba(246,243,144,0.7656104678199405) 100%);",
    },
    greenBulb: {
      backgroundColor:
        "radial-gradient(circle, rgba(10,228,23,1) 7%, rgba(6,167,48,1) 80%, rgba(144,246,178,0.7656104678199405) 100%);",
    },
    button: {
      marginTop: 18,
      marginTop: 35,
      paddingLeft: 50,
      paddingRight: 50,
      borderRadius: 15,
    },
    buttonText: {
      color: theme.buttonTextColor,
      fontFamily: "GilroyLight",
      fontSize: 14,
      lineHeight: scaleFactor(30, 30),
    },
    checkbox: {
      marginLeft: 10,
    },
    checkboxLabel: {
      fontFamily: "GilroyRegular",
      fontSize: 14,
      lineHeight: 30,
      color: theme.textColor,
    },
    splitButtons: {
      flexDirection: "row",
    },
    splitButtonContainer: {
      width: "50%",
    },
    splitButton: {
      width: "98%",
      paddingLeft: "1%",
      paddingRight: "1%",
    },
    dangerButton: {
      backgroundColor: "red",
    },
    uploadButtonsModalOverlay: {
      backgroundColor: "rgba(0,0,0,0.5)",
      position: "absolute",
      width: windowWidth(),
      height: windowHeight(),
    },
    uploadButtonsModal: {
      backgroundColor: "white",
      width: "80%",
      paddingTop: 40,
      marginTop: 200,
      marginLeft: "10%",
      padding: 20,
      borderRadius: 20,
    },
    modalButton: {
      marginTop: 15,
    },
    buttonIcon: {
      fontSize: 16,
    },
    modalCloseButton: {
      position: "absolute",
      top: 5,
      right: 5,
      padding: 0,
      borderRadius: 20,
      overflow: "hidden",
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    modalIconText: {
      fontSize: 40,
    },
  };

  styles = Platform.select({
    web: styles,
    default: StyleSheet.create(styles),
  });
  return styles;
}

// export default styles;
