import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import {
  windowHeight,
  windowWidth,
  scaleFactor,
  scrollViewStyle,
} from "services/scaleFactor";

export default function getStyles() {
  var styles = StyleSheet.create({
    flexBox: {
      flexDirection: "row",
    },
    fullHeight: {
      height: Platform.OS === "web" ? window.innerHeight : "100%",
    },
    subpageHeader: {},
    fullWidth: {
      width: Platform.OS === "web" ? "100vw" : "100%",
    },
    appHeaderSearchBtn: {
      backgroundColor: "transparent",
    },
    appHeaderSearchBtnText: {
      color: theme.darkerColor,
      fontSize: 23,
      marginTop: -3,
    },
    form: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    halfWidthInput: {
      width: windowWidth() / 2 - 10,
      paddingRight: 5,
    },
    halfWidthInputRight: {
      paddingRight: 0,
      paddingLeft: 5,
    },
    stepHeading: {
      fontFamily: "GilroyLight",
      fontSize: 18,
      color: theme.textColor,
      marginTop: 15,
    },
    searchBtns: {
      flexDirection: "row",
      marginTop: 10,
      marginBottom: 5,
      paddingBottom: 25,
      borderBottomWidth: 1,
      borderColor: theme.shadowColor,
    },
    searchBtn: {
      color: theme.buttonTextColor,
      fontFamily: "GilroyLight",
      fontSize: 13,
      marginTop: 10,
      paddingLeft: 20,
      paddingRight: 20,
      borderRadius: 15,
      width: "31%",
      marginLeft: "1%",
      marginRight: "1%",
    },
    divider: {
      marginTop: 20,
      marginBottom: 20,
    },
    searchResults: {},
    searchResult: {
      flexDirection: "row",
      marginTop: 15,
    },
    searchArrow: {
      width: 45,
      height: 45,
      backgroundColor: theme.lighterColor,
      borderRadius: 50,
      marginRight: 15,
    },
    searchArrowText: {
      fontSize: 14,
    },
    resultsContainer: {
      marginTop: 3,
      flex: 1,
    },
    resultsRow: {
      // flexDirection: "row",
      width: "100%",
      paddingRight: 10,
      borderBottomWidth: 1,
      borderBottomColor: theme.lighterColor,
    },
    resultLabel: {
      fontFamily: "GilroyLight",
      fontSize: 13,
      lineHeight: 30,
      color: theme.shadowColor,
    },
    resultText: {
      fontFamily: "GilroySemiBold",
      fontSize: 14,
      lineHeight: 30,
      color: theme.textColor,
      textAlign: "left",
      ...Platform.select({
        web: {
          wordBreak: "break-all",
        },
        default: {
          flex: 1,
          flexWrap: "wrap",
        },
      }),
    },
  });

  return styles;
}

// export default styles;
