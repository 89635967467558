import React, { Component, useState, useCallback } from "react";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import getStyles from "styles/Pagination";
import { Box, View, Center, Text, Button } from "native-base";
import { Feather } from "@expo/vector-icons";

export default function Pagination(props) {
  const data = props.data;
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const onPressPage = (page) => {
    if (props.onPressPage && page) {
      props.onPressPage(page);
    }
  };
  if (!data) {
    return null;
  }
  return (
    <Center width="100%">
      <Box style={styles.container} onLayout={onLayoutChange}>
        {data.map((pageData, index) => {
          return (
            <Button
              key={index}
              colorScheme={
                pageData.type === "current_page" ? "primary" : "clear"
              }
              style={[
                styles.page,
                pageData.type === "current_page" ? styles.currentPage : {},
                index === 0 ? styles.prevPage : {},
                index === data.length - 1 ? styles.nextPage : {},
                ["first_page", "last_page", "prev_page", "next_page"].indexOf(
                  pageData.type
                ) !== -1
                  ? styles.iconPage
                  : {},
              ]}
              onPress={() => onPressPage(pageData.page)}
            >
              <Text
                style={[
                  styles.pageText,
                  pageData.type === "current_page"
                    ? styles.currentPageText
                    : {},
                ]}
              >
                {pageData.type === "prev_page" ? (
                  <Feather name="chevron-left" style={styles.prevIcon} />
                ) : pageData.type === "next_page" ? (
                  <Feather name="chevron-right" style={styles.nextIcon} />
                ) : pageData.type === "first_page" ? (
                  <Feather name="chevrons-left" style={styles.nextIcon} />
                ) : pageData.type === "last_page" ? (
                  <Feather name="chevrons-right" style={styles.nextIcon} />
                ) : (
                  pageData.label
                )}
              </Text>
            </Button>
          );
        })}
      </Box>
    </Center>
  );
}
