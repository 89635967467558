import React, { Component, useEffect, useState } from "react";
import { NavigationContainer, useFocusEffect } from "@react-navigation/native";
import { Platform, Alert } from "react-native";
// import * as Linking from 'expo-linking';
import { NativeBaseProvider, Box } from "native-base";
import DefaultMenu from "drawers/DefaultMenu";
import UserMenu from "drawers/UserMenu";
import Landing from "screens/Landing";
import AppContext from "contexts/AppContext";
import LangContext from "contexts/LangContext";
import auth from "services/auth";
import ls from "services/ls";
import socket from "services/socket";
import { Text } from "native-base";
import * as Font from "expo-font";
import StatusBar from "elements/StatusBar";
import Translator from "services/Translator";
// import useFonts hook
import { useFonts } from "@use-expo/font";
import nbtheme from "services/nbtheme";
import theme from "services/theme";
import { savePushToken } from "services/pushToken";

import PushController from "services/PushController";

const customFonts = {
  GilroyLight: require("assets/fonts/Gilroy-Light.ttf"),
  GilroyExtraBold: require("assets/fonts/Gilroy-ExtraBold.ttf"),
  // GilroyMedium: require("assets/fonts/Gilroy-Medium.ttf"),
  GilroyRegular: require("assets/fonts/Gilroy-Regular.ttf"),
  GilroySemiBold: require("assets/fonts/Gilroy-SemiBold.ttf"),
};

// const prefix = Linking.createURL('/xbitapp/web-build');
// console.log('prefix',prefix)
// const subDir = "/xbitapp/web-build/";
// const subDir = "/app/";

export default function App() {
  const [loadingDone, setLoadingDone] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loadingVal, setLoadingVal] = useState(0);

  const [isFontsLoaded] = useFonts(customFonts);

  const [subDir, setSubDir] = useState("/");
  const linking = {
    // prefixes: [prefix],
    config: {
      screens: {
        LeftDrawer: {
          screens: {
            CasinoXbit: {
              screens: {
                Login: subDir + "login",
                SignUp: subDir + "signup",
                "Forgot Password": subDir + "forgot-password",
                "Activate Account": subDir + "activate",
                Games: subDir + "games/:gameType?",
                BackToGames: subDir + "back-to-games",
                Play: subDir + "play/:slug/:mode",
                Sportsbook: subDir + "sportsbook",
                Cashier: subDir + "cashier",
                Profile: subDir + "profile",
                "Transaction History": subDir + "transaction-history",
                "Gaming History": subDir + "gaming-history",
                "Balance Transfer History": subDir + "balance-transfer-history",
                "Bonus History": subDir + "bonus-history",
                "Free Spins": subDir + "free-spins",
                Promotions: subDir + "promotions",
                "Loyalty And VIP": subDir + "loyalty-and-vip",
                "AML Policy": subDir + "aml-policy",
                "Terms And Conditions": subDir + "terms-and-conditions",
                "Bonus Terms": subDir + "bonus-terms",
                "Privacy Policy": subDir + "privacy-policy",
                "Responsible Gaming": subDir + "responsible-gaming",
                "Access Control": subDir + "access-control",
                Support: subDir + "support",
              },
            },
          },
        },
        Landing: subDir + "",
      },
    },
    enabled: true,
  };

  const appContext = React.useMemo(
    () => ({
      socket: socket,
      updateBalance: async (balanceObj) => {
        await ls.set("balanceObj", balanceObj);
      },
      changeLoggedInAs: async (userType) => {
        setLoggedInAs(userType);
      },
    }),
    [socket]
  );

  const [translations, setTranslations] = useState({});

  const getTranslations = async () => {
    var lang = await Translator.getSelectedLanguage();
    setSubDir("/" + lang.code + "/");
    var trans = await Translator.getTranslations();
    setTranslations(trans);
  };

  const langContext = React.useMemo(
    () => ({
      __: (line, params) => {
        return Translator._translate(line, params, translations);
      },
    }),
    [translations]
  );

  const checkLoggedIn = async () => {
    if (await auth.isAuthenticated()) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkLoggedIn();
    ls.on("userObj", (newVal) => {
      checkLoggedIn();
      savePushToken(); //Will be fetched from ls
    });
    getTranslations();
    ls.on("translations", (newVal) => {
      setTranslations(newVal);
    });
    ls.on("language", (newVal) => {
      setSubDir("/" + newVal.code + "/");
    });
  }, []);

  const init = async () => {
    setLoadingDone(false);
    var loading = 0;
    var loadingFunc = setInterval(() => {
      setLoadingVal(++loading);
      if (loading === 100) {
        clearInterval(loadingFunc);
        setLoadingDone(true);
      }
    }, 10);
  };
  useEffect(() => {
    init();
  }, []);

  return (
    <LangContext.Provider value={langContext}>
      <AppContext.Provider value={appContext}>
        <NavigationContainer linking={linking}>
          <NativeBaseProvider theme={nbtheme}>
            <PushController />
            <StatusBar />
            {!(loadingDone && isFontsLoaded) ? (
              <Landing loadingVal={loadingVal} />
            ) : isLoggedIn ? (
              <UserMenu />
            ) : (
              <DefaultMenu />
            )}
          </NativeBaseProvider>
        </NavigationContainer>
      </AppContext.Provider>
    </LangContext.Provider>
  );
}
