import React from "react";
import { View, Text } from "native-base";
import { StyleSheet } from "react-native";
import theme from "services/theme";
import Image from "elements/Image";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";
import NoRecordsFoundImage from "assets/images/no-records-found.png";

export default function NoRecordsFound(props) {
  const width = windowWidth() > windowHeight() ? windowHeight() : windowWidth();
  const { __ } = React.useContext(require("contexts/LangContext").default);
  return (
    <View
      style={{
        width: width,
        height: width,
        marginTop: 50,
        position: "relative",
      }}
    >
      <Image
        source={NoRecordsFoundImage}
        style={{ width: width, height: width }}
      />
      <Text
        style={{
          fontFamily: "GilroyLight",
          fontSize: 17,
          top: width / 2 - 20,
          width: "100%",
          textAlign: "center",
          color: theme.textColor,
          position: "absolute",
        }}
      >
        {__("No Records Found")}
      </Text>
    </View>
  );
}
