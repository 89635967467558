import React, { useState, useEffect } from "react";
import config from "root/config";
import api from "services/api";
import ls from "services/ls";

function currentTimestamp() {
  return parseInt(Date.now() / 1000);
}

class Translator {
  static async getSelectedLanguage() {
    var language = await ls.get("language");
    if (!language) {
      language = config.defaultLanguage;
      ls.set("language", language);
    }
    return language;
  }

  static async setSelectedLanguage(language) {
    await ls.set("language", language);
    await ls.remove("translations");
    return await Translator.getTranslations();
  }

  static async getTranslations() {
    var translations = await ls.get("translations");
    if (
      !translations ||
      !translations.lastFetched ||
      currentTimestamp() - translations.lastFetched > 1800
    ) {
      //Translation not yet fetched or expired (30 minutes threshold time)
      var response = await api.get("/translations");
      if (response.status === 200) {
        translations = response.data;
        translations.lastFetched = currentTimestamp();
        const lang = (await Translator.getSelectedLanguage()).code;
        await ls.set("translations", translations);
        console.log("Translations fetched for language " + lang);
      } else {
        console.log("Error fetching translations for language " + lang);
      }
    }

    return translations;
  }

  static _translate(line, params, translations) {
    if (translations && translations.lines) {
      // console.log(translations.lines)
      if (translations.lines[line]) {
        line = translations.lines[line];
        var finalParams = { ...translations.site_variables };
        if (params !== undefined) {
          finalParams = { ...finalParams, ...params };
        }
        Object.entries(finalParams).map((item) => {
          line = line.replace(":" + item[0], item[1]);
          return null;
        });
      } else {
        //Translation not found
        // api.post("/translations/add", { line });
        // console.log(line)
      }
    }

    return line;
  }

  static async translate(line, params) {
    var translations = await Translator.getTranslations();
    return Translator._translate(line, params, translations);
  }
}

Translator.getTranslations();

export default Translator;

export function Trans({ line }) {
  console.log("Trans called with " + line);
  const [translatedLine, setTranslatedLine] = useState(line);
  useEffect(() => {
    (async () => {
      var newLine = await Translator.translate(line);
      setTranslatedLine(newLine);
    })();
    return () => {};
  }, []);

  ls.on("translations", (translations) => {
    var newLine = Translator._translate(line, {}, translations);
    setTranslatedLine(newLine);
  });

  return translatedLine;
}
