import React, { Component, useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import {
  StyleSheet,
  ImageBackground,
  TouchableHighlight,
  TouchableOpacity,
  Platform,
  SafeAreaView,
} from "react-native";
import Image from "elements/Image";
import { View, Text } from "native-base";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";
import theme from "services/theme";
import Logo from "assets/images/logo.svg";
import ProgressBar from "elements/ProgressBar";

export default function Landing({ loadingVal }) {
  const [styles, setStyles] = useState(getStyles());

  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  return (
    <SafeAreaView>
      <View style={styles.fullHeight} onLayout={onLayoutChange}>
        <View>
          <Image source={Logo} type="svg" style={styles.landing_img} />
        </View>
        <ProgressBar size={loadingVal} color={theme.primaryColor} />
      </View>
    </SafeAreaView>
  );
}

function getStyles() {
  return StyleSheet.create({
    fullHeight: {
      height: Platform.OS === "web" ? "100vh" : "100%",
    },
    landing_img: {
      width: windowWidth() / 2,
      height: (windowWidth() / 2) * 0.66,
      marginLeft: windowWidth() / 4,
      marginTop: windowHeight() / 2 - (windowWidth() / 4) * 0.66 - 50, //half window height - half image height - additional space for loader
    },
  });
}
