import React from "react";
import { Image, Platform } from "react-native";
import { SvgXml } from "react-native-svg";

export default function ImageCustom(props) {
  const width = props.style && props.style.width ? props.style.width : 200;
  const height = props.style && props.style.height ? props.style.height : 200;
  if (Platform.OS === "web" || props.type !== "svg") {
    return <Image style={props.style} source={props.source} />;
  } else {
    return (
      <SvgXml
        width={width}
        height={height}
        style={props.style}
        xml={props.source}
        {...props}
      />
    );
  }
}
