import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import {
  windowHeight,
  windowWidth,
  scaleFactor,
  scaleFactorW,
  boxShadowStyle,
  scrollViewStyle,
  orientation,
} from "services/scaleFactor";

export default function getStyles() {
var styles = {
  flexBox: {
    flexDirection: "row",
  },
  fullHeight: {
    height: Platform.OS === "web" ? window.innerHeight : "100%",
  },
  banner: {
    width: "100%",
  },
  bannerImg: {
    width: windowWidth(),
    height: windowWidth() * 0.488,
  },
  subpageHeader: {
    position: "absolute",
    zIndex: 4,
    top: scaleFactor(14),
  },
  bannerText: {
    width: windowWidth(),
    height: windowWidth() * 0.488,
    position: "absolute",
    zIndex: 4,
  },
  totalBalance: {
    fontFamily: "GilroyLight",
    fontSize: scaleFactor(32),
    lineHeight: scaleFactor(32),
    height: scaleFactor(40),
    marginTop: scaleFactor(10),
    marginBottom: 5,
    color: theme.buttonTextColor,
  },
  totalBalanceText: {
    fontFamily: "GilroyLight",
    fontSize: 14,
    color: theme.buttonTextColor,
  },
  balanceButtons: {
    bottom: scaleFactor(40, 40),
  },
  balanceButton: {
    marginLeft: 5,
    marginRight: 5,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: theme.invertedButtonColor,
    ...boxShadowStyle(theme.shadowColor),
    height: 80,
  },
  balance: {
    fontFamily: "GilroyLight",
    fontSize: 18,
    color: theme.textColor,
  },
  balanceText: {
    fontFamily: "GilroyLight",
    fontSize: 14,
    color: theme.shadowColor,
  },

  deleteBonusButton: {
    borderColor: theme.primaryColor,
    borderWidth: 1,
    marginTop: 10,
    paddingLeft: scaleFactor(30, 30),
    paddingRight: scaleFactor(30, 30),
    borderRadius: 15,
    marginLeft: 5,
    marginRight: 5,
    textAlign: "center",
  },
  deleteBonusText: {
    fontFamily: "GilroyLight",
    fontSize: 16,
    color: theme.primaryColor,
  },
  cashierButtons: {
    marginBottom: scaleFactor(40, 40),
  },
  cashierButton: {
    color: theme.buttonTextColor,
    fontFamily: "GilroyLight",
    fontSize: 13,

    paddingLeft: scaleFactor(40, 40),
    paddingRight: scaleFactor(40, 40),
    borderRadius: 15,
    marginLeft: 5,
    marginRight: 5,
    textAlign: "center",
  },
  cashierText: {
    color: theme.invertedButtonColor,
    fontFamily: "GilroyLight",
    fontSize: 16,
  },
  cashierPage: {
    marginLeft: 15,
    marginRight: 15,
  },
  cashierPageTexts: {
    padding: 15,
    marginTop: 20,
  },
  cashierPageText: {
    fontFamily: "GilroyLight",
    marginBottom: 10,
  },
  gamesFilterOverlay: {
    position: "absolute",
    height: "100%",
    top: 0,
    zIndex: 10,
    left: 0,
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  gamesFilter: {
    marginTop: 50,
    height: windowHeight() - 50,
    width: windowWidth(),
    backgroundColor: "white",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 20,
    paddingBottom:
      (orientation() == "landscape"
        ? scaleFactorW(50, 70)
        : scaleFactor(65, 70)) + 30,
    flex: 1,
  },
  filterText: {
    textAlign: "center",
    width: windowWidth() - 140,
    marginLeft: 50,
    fontFamily: "GilroySemiBold",
    fontSize: 20,
  },
  filterCloseBtn: {
    width: 50,
    height: 50,
    backgroundColor: "transparent",
  },
  amountText: {
    fontFamily: "GilroySemiBold",
    fontSize: 18,
    marginBottom: 10,
  },
  amountTextSub: {
    fontFamily: "GilroyLight",
    fontSize: 12,
    marginBottom: 10,
  },
  amounts: {
    flexWrap: "wrap",
    marginBottom: 25,
  },
  amountBtn: {
    fontFamily: "GilroyLight",
    borderColor: theme.primaryColor,
    overflow: "hidden",
    borderWidth: 1,
    color: theme.textColor,
    paddingLeft: 15,
    paddingRight: 15,
    lineHeight: 35,
    fontSize: 17,
    borderRadius: 15,
    marginRight: 5,
    marginBottom: 5,
  },
  amountBtnSelected: {
    backgroundColor: theme.lighterColor,
  },
  paymentMethods: {},
  paymentMethod: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    borderWidth: 1,
    borderRadius: 15,
    marginBottom: 15,
    borderColor: theme.shadowColor,
    ...boxShadowStyle(theme.shadowColor),
    backgroundColor: theme.invertedButtonColor,
  },
  paymentMethodCheckBox: {
    paddingTop: 8,
  },
  paymentMethodIcon: {
    width: 50,
    height: 50,
  },
  paymentMethodLabel: {
    fontFamily: "GilroyLight",
    fontSize: 16,
    lineHeight: 45,
    marginLeft: 20,
  },
  paymentMethodInfoButton: {
    borderRadius: 50,
    position: "absolute",
    right: 0,
    top: 0,
  },
  paymentMethodInfoIcon: {
    fontSize: 23,
    color: theme.primaryColor,
  },
  paymentMethodText: {
    fontFamily: "GilroyLight",
    fontSize: 13,
    color: theme.shadowColor,
  },
  bonusCampaigns: {
    marginTop: 20,
  },
  bonusCheckbox: {
    marginLeft: 10,
  },
  checkboxLabel: {
    fontFamily: "GilroyExtraBold",
    fontSize: 14,
    lineHeight: 30,
    color: theme.textColor,
  },
  iframe: {
    width: "100%",
    height: windowHeight() - 150,
  },
  webView: {
    width: "100%",
    height: windowHeight() - 150,
    borderColor: "red",
    borderWidth: 1,
  },
  submitBtn: {
    color: theme.buttonTextColor,
    fontFamily: "GilroyLight",
    fontSize: 13,
    marginTop: 35,
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 15,
  },
  counousDepositFinalStep: {
    // height: windowHeight() - 110,
    flex: 1,
  },
  qrCodeContainer: {
    width: windowWidth() - 40,
    height: windowWidth() - 40,
  },
  qrCode: {
    width: windowWidth() - 40,
    height: windowWidth() - 40,
  },
  resultsRow: {
    flexDirection: "row",
    width: "100%",
    paddingRight: 10,
  },
  evenRow: {
    backgroundColor: "#EEEEEE",
  },
  resultLabel: {
    fontFamily: "GilroyLight",
    fontSize: 16,
    lineHeight: 30,
    color: theme.shadowColor,
    marginRight: 10,
    width: "35%",
  },
  resultText: {
    fontFamily: "GilroySemiBold",
    fontSize: 16,
    lineHeight: 30,
    color: theme.textColor,
    textAlign: "left",
    ...Platform.select({
      web: {
        wordBreak: "break-all",
      },
      default: {
        flex: 1,
        flexWrap: "wrap",
      },
    }),
  },
  depositInfo: {
    marginTop: 20,
    marginBottom: 20,
  },
  downArrowBold: {
    fontSize: 30,
    color: theme.primaryColor,
  },
  appLinkArea: {
    marginTop: 20,
    marginBottom: 20,
    flexDirection: "row",
  },
  appStoreBtn: {
    backgroundColor: "transparent",
    padding: 0,
    width: windowWidth() / 2 - 30,
    height: (windowWidth() / 2 - 30) * 0.283871,
    marginLeft: 5,
    marginRight: 5,
  },
  appStore: {
    width: windowWidth() / 2 - 30,
    height: (windowWidth() / 2 - 30) * 0.283871,
  },
  footerTextArea: {},
  footerText: {},
};

styles = Platform.select({
  web: styles,
  default: StyleSheet.create(styles),
});
  return styles;
}
// export default styles;
