import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import { windowWidth } from "services/scaleFactor";

export default function getStyles() {
  var styles = StyleSheet.create({
    flexBox: {
      flexDirection: "row",
    },
    fullHeight: {
      height: Platform.OS === "web" ? window.innerHeight : "100%",
    },
    fullWidth: {
      width: Platform.OS === "web" ? "100vw" : "100%",
    },
    pageText: {
      marginTop: 20,
      paddingLeft: 10,
      paddingRight: 10,
    },
    pageTextPara: {
      fontFamily: "GilroyRegular",
      fontSize: 15,
      color: theme.textColor,
      lineHeight: 20,
    },
    pageTextHeading: {
      fontFamily: "GilroySemiBold",
      fontSize: 15,
      color: theme.darkerColor,
      lineHeight: 25,
    },
    pageImageFullWidth: {
      borderRadius: 15,
      marginTop: 10,
      marginBottom: 10,
      width: windowWidth() - 20,
      height: (windowWidth() - 20) * 0.408,
    },
  });

  return styles;
}
