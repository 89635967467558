import React from "react";
import { StyleSheet, TouchableHighlight, TouchableOpacity } from "react-native";
import { Button, Icon } from "native-base";
import { Feather, Ionicons } from "@expo/vector-icons";
import theme from "services/theme";

export default function CheckBox(props) {
  const Checked = (props) => {
    if (props.type && props.type === "radio") {
      return <Ionicons name="radio-button-on" {...props} />;
    } else {
      return <Ionicons name="checkbox" {...props} />;
    }
  };

  const UnChecked = (props) => {
    if (props.type && props.type === "radio") {
      return <Ionicons name="radio-button-off" {...props} />;
    } else {
      return <Ionicons name="square-outline" {...props} />;
    }
  };

  const type = props.type ? props.type : "checkbox";

  return (
    <TouchableOpacity
      activeOpacity={0.75}
      style={[styles.listitem, props.style ? props.style : {}]}
      onPress={props.onPress}
    >
      {props.checked ? (
        <Checked
          type={type}
          style={[styles.icon, props.iconStyle ? props.iconStyle : {}]}
        />
      ) : (
        <UnChecked
          type={type}
          style={[styles.icon, props.iconStyle ? props.iconStyle : {}]}
        />
      )}
      {props.children}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  listitem: {
    flexDirection: "row",
    paddingTop: 10,
    paddingBottom: 10,
    width: "100%",
  },
  icon: {
    color: theme.primaryColor,
    fontSize: 30,
    position: "relative",
    marginRight: 10,
  },
});
