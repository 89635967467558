import React, { Component, useState, useEffect } from "react";
import {
  Platform,
  StyleSheet,
  View,
  StatusBar,
  Dimensions,
} from "react-native";
import Constants from "expo-constants";
import theme from "services/theme";
import { orientation } from "services/scaleFactor";

export default function StatusBarCustom() {
  if (Platform.OS === "android") {
    StatusBar.setBackgroundColor(theme.primaryColor);
  }
  const [styles, setStyles] = useState(getStyles());

  useEffect(() => {
    Dimensions.addEventListener("change", () => {
      StatusBar.setHidden(orientation() === "landscape");
      setStyles(getStyles());
    });
  }, []);

  return Platform.select({
    web: <View style={styles.StatusBar}></View>,
    android: null,
    default: (
      <View style={styles.StatusBar}>
        <StatusBar translucent barStyle="dark-content" />
      </View>
    ),
  });
}

const getStyles = () => {
  const styles = Platform.select({
    web: {
      StatusBar: {
        position: "fixed",
        width: "100vw",
        height: "env(safe-area-inset-top)",
        top: 0,
        left: 0,
        zIndex: 100,
        backgroundColor: theme.primaryColor,
      },
    },
    default: StyleSheet.create({
      StatusBar: {
        height: orientation() === "portrait" ? Constants.statusBarHeight : 0,
        backgroundColor: theme.primaryColor,
      },
    }),
  });
  return styles;
};
