import React, { Component, useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Platform, SafeAreaView } from "react-native";
import { WebView } from "react-native-webview";
import getStyles from "styles/Sportsbook";
import theme from "services/theme";
import api from "services/api";
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";
import Image from "elements/Image";
import { StdAlert, BinaryAlert } from "elements/Alert";
import FullHeightScrollView from "elements/FullHeightScrollView";
import Spinner from "elements/Spinner";
import {
  Center,
  Button,
  Text,
  Box,
  View,
  KeyboardAvoidingView,
} from "native-base";


export default function Sportsbook(props) {
  const navigation = props.navigation;
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  const [spinner, setSpinner] = useState(false);
  const [launchUrl, setLaunchUrl] = useState("");

  const initGameSession = async () => {
    props.navigation.navigate('Games');
    setSpinner(true);
    var response = await api.get("/sportsbook/initsession");
    if (response.status === 200) {
      setLaunchUrl(response.data.launchUrl);
    } else {
      setSpinner(false);
      StdAlert("Warning!!", response.data.message, () => navigation.goBack());
    }
  };

  useFocusEffect(
    useCallback(() => {
      setLaunchUrl("");
      initGameSession();
    }, [])
  );

  const onWebViewLoaded = (web) => {
    setTimeout(() => {
      setSpinner(false);
    }, 5000);
  };
  const onWebViewLoadedIframe = () => {
    setTimeout(() => {
      setSpinner(false);
    }, 5000);
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        onLayout={onLayoutChange}
        style={{ backgroundColor: "white" }}
      >
        <Spinner show={spinner} />
        <Box style={styles.fullHeight}>
          <AppHeader />
          <Box style={styles.webView}>
            {launchUrl
              ? Platform.select({
                  web: (
                    <iframe
                      src={launchUrl}
                      style={styles.webView}
                      onLoad={onWebViewLoadedIframe}
                    />
                  ),
                  default: (
                    <Box style={styles.webView}>
                      <WebView
                        flex={1}
                        source={{
                          uri: launchUrl,
                        }}
                        onLoad={onWebViewLoaded}
                      />
                    </Box>
                  ),
                })
              : null}
          </Box>
          <FooterMenu />
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
