import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import {
  windowHeight,
  windowWidth,
  scaleFactor,
  orientation,
} from "services/scaleFactor";

export default function getStyles() {
  var styles = {
    header: {
      flexDirection: "row",
      paddingBottom: scaleFactor(4, 5),
      paddingTop: scaleFactor(4, 8),
      paddingBottom: scaleFactor(4, 8),
      paddingLeft: scaleFactor(10, 15),
      paddingRight: scaleFactor(10, 15),
      height: 52,
      position: "relative",
      borderColor: theme.shadowColor,
      borderWidth: 0,
      borderBottomWidth: 1,
    },
    mainMenuButton: {
      borderRadius: 50,
      paddingLeft: 0,
      paddingRight: 0,
      width: scaleFactor(40, 45),
      height: scaleFactor(40, 45),
    },
    icon: {
      color: theme.darkerColor,
      fontSize: scaleFactor(12, 19),
      paddingLeft: 0,
      paddingRight: 0,
      fontWeight: "700",
      position: "relative",
      marginLeft: "auto",
      marginRight: "auto",
    },
    smallLogoContainer: {
      position: "absolute",
      width: scaleFactor(32 / 0.66, 40 / 0.66),
      height: scaleFactor(32, 40),
    },
    smallLogo: {
      width: scaleFactor(32 / 0.66, 38 / 0.66),
      height: scaleFactor(32, 38),
    },
    balanceContainer: {
      backgroundColor: theme.lighterColor,
      borderRadius: 25,
      paddingLeft: scaleFactor(7, 10),
      paddingRight: scaleFactor(7, 10),
      height: scaleFactor(30, 30),
      marginLeft: "auto",
    },
    balance: {
      color: theme.darkerColor,
      ...Platform.select({
        web: {
          lineHeight: scaleFactor(30, 30),
        },
        ios:{
          lineHeight: scaleFactor(30, 30),
        },
        default: {
          lineHeight: scaleFactor(25, 25),
        },
      }),
      fontSize: scaleFactor(14, 14),
      fontFamily: "GilroyLight",
    },
    userMenuButton: {
      backgroundColor: "transparent",
      borderWidth: 0,
      width: scaleFactor(40, 45),
      height: scaleFactor(40, 45),
      paddingTop: 0,
      paddingBottom: 0,
      marginRight: -15,
      paddingLeft: 0,
      paddingRight: 0
    },
    userMenuIcon: {
      ...Platform.select({
        ios: {fontSize: scaleFactor(23, 23),},
        default: {fontSize: scaleFactor(27, 27),}
      }),

      lineHeight: scaleFactor(40, 45),
    },
  };
  styles.smallLogoContainer.left =
    (windowWidth() - styles.smallLogoContainer.width) / 2;
  styles.smallLogoContainer.top =
    (styles.header.height - styles.smallLogoContainer.height) / 2;
  styles.mainMenuButton.marginTop =
    (styles.header.height -
      styles.mainMenuButton.height -
      styles.header.paddingTop -
      styles.header.paddingBottom) /
    2;
  styles.balanceContainer.marginTop =
    (styles.header.height -
      styles.balanceContainer.height -
      styles.header.paddingTop -
      styles.header.paddingBottom) /
    2;
  styles.userMenuButton.marginTop =
    (styles.header.height -
      styles.userMenuButton.height -
      styles.header.paddingTop -
      styles.header.paddingBottom) /
    2;

  styles = StyleSheet.create(styles);
  return styles;
}

// export default styles;
