import io from "socket.io-client";
import api from "services/api";

var socket;

// if (socket && socket.connected) {
//   console.log("socket already connected " + socket.id);
// } else {
//   var socket = io(api.socketurl, {
//     transports: ["websocket"]
//   });
//
//   socket.on("connect", () => {
//     console.log("socket connected " + socket.id);
//     socket.emit("pingi", "pingi from " + socket.id);
//   });
//
//   socket.on("connect_timeout", timeout => {
//     console.log("Socket connection timed out");
//     console.log(timeout);
//   });
//   socket.on("connect_error", error => {
//     // console.log("Socket connection failed");
//     // console.log(error);
//   });
//
//   socket.on("pong", msg => {
//     // console.log("pong " + msg + " for " + socket.id);
//   });
// }

export default socket;

// return () => {
//   console.log("restarting");
//   socket.off("connect");
//   socket.off("connect_timeout");
//   socket.off("connect_error");
//   socket.off("pong");
// };
