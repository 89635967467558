import React, { useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { SafeAreaView, ScrollView } from "react-native";
import RefreshControl from "services/RefreshControl";
import getStyles from "styles/HistoryPages";
import theme from "services/theme";
import api from "services/api";
import { View, Button, Text, Box, KeyboardAvoidingView } from "native-base";
import InputBox from "elements/InputBox";
import Hr from "elements/Hr";
import SubPageHeader from "elements/SubPageHeader";
import { Entypo, Feather } from "@expo/vector-icons";
import SelectBox from "elements/SelectBox";
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";
import DatePicker from "elements/DatePicker";
import Pagination from "elements/Pagination";
import NoRecordsFound from "elements/NoRecordsFound";
import Spinner from "elements/Spinner";

export default function GamingHistory(props) {
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);
  const [spinner, setSpinner] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [results, setResults] = useState([]);
  const [resultsFetched, setResultsFetched] = useState(false);

  const onPageChange = (page) => {
    doSearch(page);
  };

  const onRefresh = () => {
    doSearch(currentPage);
  };

  const defaultForm = {
    gameName: "",
    roundId: "",
    amount: "",
    type: "",
    usingBonus: "",
    isFreeround: "",
    startDate: null,
    endDate: null,
  };

  const [form, setForm] = useState(defaultForm);

  const navigation = props.navigation;

  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const trTypes = [
    { label: __("All"), value: "" },
    { label: __("Bet"), value: "debit" },
    { label: __("Win"), value: "credit" },
  ];

  const yesNoValues = [
    { label: __("All"), value: "" },
    { label: __("Yes"), value: "1" },
    { label: __("No"), value: "0" },
  ];

  const doSearch = async (page) => {
    setSpinner(true);
    setCurrentPage(page);
    var response = await api.post("/history/gaming", {
      ...form,
      page: page,
    });
    setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
      setResultsFetched(true);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const doExport = async (exportType) => {
    var response = await api.post("/history/gaming/csv", form);
    if (response.status === 200) {
      download("GamingHistory.xlsx", response.data);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const resetForm = async () => {
    setForm(defaultForm);
  };

  const toggleResultExpanded = (index) => {
    var resultsClone = JSON.parse(JSON.stringify(results));
    resultsClone.splice(index, 1, {
      ...results[index],
      expanded: !results[index].expanded,
    });
    setResults(resultsClone);
  };

  const [searchDisplayed, setSearchDisplayed] = useState(false);

  useFocusEffect(
    useCallback(() => {
      if (!resultsFetched) {
        doSearch(currentPage);
      }
    }, [resultsFetched])
  );

  const SearchIcon = () => {
    return (
      <Button
        style={styles.appHeaderSearchBtn}
        onPress={() => setSearchDisplayed(!searchDisplayed)}
      >
        <Feather style={styles.appHeaderSearchBtnText} name="search" />
      </Button>
    );
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={spinner} />
        <Box style={styles.fullHeight}>
          <AppHeader />
          <ScrollView
            refreshControl={
              <RefreshControl refreshing={spinner} onRefresh={onRefresh} />
            }
          >
            <SubPageHeader
              pageName={__("Gaming History")}
              rightIcon={<SearchIcon />}
              style={styles.subpageHeader}
            />

            <View style={[styles.fullWidth, styles.form]}>
              {!searchDisplayed && <Hr />}
              {searchDisplayed && (
                <View>
                  <InputBox
                    value={form.gameName}
                    onChangeText={(itemValue) =>
                      updateForm(itemValue, "gameName")
                    }
                    label={__("Game Name")}
                    placeholder={__("Game Name")}
                  />
                  <InputBox
                    value={form.roundId}
                    onChangeText={(itemValue) =>
                      updateForm(itemValue, "roundId")
                    }
                    label={__("Round Id")}
                    placeholder={__("Round Id")}
                  />
                  <Box style={styles.flexBox}>
                    <View style={styles.halfWidthInput}>
                      <InputBox
                        value={form.amount}
                        onChangeText={(itemValue) =>
                          updateForm(itemValue, "amount")
                        }
                        label={__("Amount")}
                        placeholder={__("Amount")}
                      />
                    </View>
                    <View
                      style={[
                        styles.halfWidthInput,
                        styles.halfWidthInputRight,
                      ]}
                    >
                      <SelectBox
                        label={__("Type")}
                        placeholder={__("Type")}
                        minWidth={100}
                        selectedValue={form.type}
                        onValueChange={(itemValue) =>
                          updateForm(itemValue, "type")
                        }
                        items={trTypes}
                      />
                    </View>
                  </Box>
                  <Box style={styles.flexBox}>
                    <View style={styles.halfWidthInput}>
                      <SelectBox
                        label={__("Using Bonus")}
                        placeholder={__("Using Bonus")}
                        minWidth={100}
                        selectedValue={form.usingBonus}
                        onValueChange={(itemValue) =>
                          updateForm(itemValue, "usingBonus")
                        }
                        items={yesNoValues}
                      />
                    </View>
                    <View
                      style={[
                        styles.halfWidthInput,
                        styles.halfWidthInputRight,
                      ]}
                    >
                      <SelectBox
                        label={__("Freerounds")}
                        placeholder={__("Freerounds")}
                        minWidth={100}
                        selectedValue={form.isFreeround}
                        onValueChange={(itemValue) =>
                          updateForm(itemValue, "isFreeround")
                        }
                        items={yesNoValues}
                      />
                    </View>
                  </Box>

                  <DatePicker
                    label={__("Start Date")}
                    value={form.startDate}
                    onValueChange={(itemValue) =>
                      updateForm(itemValue, "startDate")
                    }
                  />

                  <DatePicker
                    label={__("End Date")}
                    value={form.endDate}
                    onValueChange={(itemValue) =>
                      updateForm(itemValue, "endDate")
                    }
                  />

                  <Box style={styles.searchBtns}>
                    <Button
                      onPress={() => doSearch(1)}
                      style={styles.searchBtn}
                    >
                      {__("Search")}
                    </Button>
                    <Button
                      onPress={() => doExport("excel")}
                      style={styles.searchBtn}
                    >
                      {__("Export")}
                    </Button>
                    <Button
                      onPress={() => resetForm()}
                      style={styles.searchBtn}
                    >
                      {__("Reset")}
                    </Button>
                  </Box>
                </View>
              )}

              {!results.length && !spinner && <NoRecordsFound />}

              <View style={styles.searchResults}>
                {results.map((result, index) => {
                  return (
                    <View key={index} style={styles.searchResult}>
                      <Button
                        style={styles.searchArrow}
                        onPress={() => {
                          toggleResultExpanded(index);
                        }}
                      >
                        {result.expanded ? (
                          <Entypo
                            style={styles.searchArrowText}
                            name="chevron-thin-up"
                          />
                        ) : (
                          <Entypo
                            style={styles.searchArrowText}
                            name="chevron-thin-down"
                          />
                        )}
                      </Button>
                      <View style={styles.resultsContainer}>
                        <View style={styles.resultsRow}>
                          <Text style={styles.resultLabel}>{__("Date")}</Text>
                          <Text style={styles.resultText}>
                            {result.record_date}
                          </Text>
                        </View>
                        <View style={styles.resultsRow}>
                          <Text style={styles.resultLabel}>{__("Game")}</Text>
                          <Text style={styles.resultText}>
                            {result.game_name}
                          </Text>
                        </View>
                        <View style={styles.resultsRow}>
                          <Text style={styles.resultLabel}>{__("Type")}</Text>
                          <Text style={styles.resultText}>{result.type}</Text>
                        </View>
                        <View style={styles.resultsRow}>
                          <Text style={styles.resultLabel}>{__("Amount")}</Text>
                          <Text style={styles.resultText}>{result.amount}</Text>
                        </View>
                        {result.expanded && (
                          <View>
                            <View style={styles.resultsRow}>
                              <Text style={styles.resultLabel}>
                                {__("Balance Type")}
                              </Text>
                              <Text style={styles.resultText}>
                                {result.using_bonus}
                              </Text>
                            </View>
                            <View style={styles.resultsRow}>
                              <Text style={styles.resultLabel}>
                                {__("Freeround?")}
                              </Text>
                              <Text style={styles.resultText}>
                                {result.is_freeround}
                              </Text>
                            </View>
                            <View style={styles.resultsRow}>
                              <Text style={styles.resultLabel}>
                                {__("Balance Before")}
                              </Text>
                              <Text style={styles.resultText}>
                                {result.balance_before}
                              </Text>
                            </View>
                            <View style={styles.resultsRow}>
                              <Text style={styles.resultLabel}>
                                {__("Balance After")}
                              </Text>
                              <Text style={styles.resultText}>
                                {result.balance_after}
                              </Text>
                            </View>
                            <View style={styles.resultsRow}>
                              <Text style={styles.resultLabel}>
                                {__("Bonus Before")}
                              </Text>
                              <Text style={styles.resultText}>
                                {result.bonus_balance_before}
                              </Text>
                            </View>
                            <View style={styles.resultsRow}>
                              <Text style={styles.resultLabel}>
                                {__("Bonus After")}
                              </Text>
                              <Text style={styles.resultText}>
                                {result.bonus_balance_after}
                              </Text>
                            </View>
                          </View>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View>

              <View style={{ width: "100%", height: 20 }}></View>
            </View>

            <Pagination data={pagination} onPressPage={onPageChange} />
          </ScrollView>
          <FooterMenu />
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
