import React, { Component, useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import {
  Platform,
  TouchableHighlight,
  ScrollView,
  SafeAreaView,
} from "react-native";
import RefreshControl from "services/RefreshControl";
import getStyles from "styles/Games";
import theme from "services/theme";
import api from "services/api";
import {
  View,
  Center,
  Text,
  Icon,
  Input,
  Box,
  KeyboardAvoidingView,
  Button,
} from "native-base";
import InputBox from "elements/InputBox";
import Image from "elements/Image";
import GameItem from "elements/GameItem";
import Hr from "elements/Hr";
import Pagination from "elements/Pagination";
import { AntDesign } from "@expo/vector-icons";
import auth from "services/auth";
//Game Items
import PopularIcon from "assets/images/gametypes/popular.svg";
import SlotsIcon from "assets/images/gametypes/slots.svg";
import TableGamesIcon from "assets/images/gametypes/table-games.svg";
import LiveGamesIcon from "assets/images/gametypes/live-games.svg";
import JackpotIcon from "assets/images/gametypes/jackpot.svg";
import SportsBookIcon from "assets/images/gametypes/sportsbook.svg";
import NewGamesIcon from "assets/images/gametypes/new-games.svg";
import BonusGamesIcon from "assets/images/gametypes/bonus-games.svg";
//Active Icons
import PopularIconActive from "assets/images/gametypes/active/popular.svg";
import SlotsIconActive from "assets/images/gametypes/active/slots.svg";
import TableGamesIconActive from "assets/images/gametypes/active/table-games.svg";
import LiveGamesIconActive from "assets/images/gametypes/active/live-games.svg";
import JackpotIconActive from "assets/images/gametypes/active/jackpot.svg";
import SportsBookIconActive from "assets/images/gametypes/active/sportsbook.svg";
import NewGamesIconActive from "assets/images/gametypes/active/new-games.svg";
import BonusGamesIconActive from "assets/images/gametypes/active/bonus-games.svg";
//Search configuration Icon
import ConfigIcon from "assets/images/config.svg";
///////////////////////
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";
import Spinner from "elements/Spinner";

export default function Games(props) {
  const [styles, setStyles] = useState(getStyles());

  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);
  const [spinner, setSpinner] = useState(false);
  const [isSearchResultsShowing, setIsSearchResultsShowing] = useState(false);

  const navigateTo = (screen) => {
    props.navigation.navigate(screen);
  };

  const defaultGames = {
    popular: { gamelist: [] },
    video_slots: { gamelist: [] },
    live_casino: { gamelist: [] },
    table_games: { gamelist: [] },
    jackpot: { gamelist: [] },
    new: { gamelist: [] },
    bonus: { gamelist: [] },
    other: { gamelist: [] },
  };

  const gameTitles = {
    popular: __("Popular"),
    video_slots: __("Slots"),
    live_casino: __("Live Games"),
    table_games: __("Table Games"),
    jackpot: __("Jackpot Games"),
    new: __("New Games"),
    other: __("Others"),
    bonus: __("Bonus"),
    search_results: __("Search Results"),
  };

  const [gameType, setGameType] = useState(
    props.route.params && props.route.params.gameType
      ? props.route.params.gameType
      : null
  );
  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [providers, setProviders] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [games, setGames] = useState(defaultGames);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const onRefresh = () => {
    onPageChange(currentPage);
  };

  const getGames = async (gameTypes, currentPage, perPage) => {
    setSpinner(true);
    setSearchStr("");
    setSearchSuggestions([]);
    console.log(gameTypes);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: gameTypes,
      page: currentPage,
    });
    setSpinner(false);
    if (response.status === 200) {
      setIsSearchResultsShowing(false);
      setGames(response.data.games);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const getCategorizedGames = async (gameType, page) => {
    if (page === undefined) {
      page = 1;
    }
    if (gameType) {
      setCurrentPage(page);
      getGames(gameType, page, 12);
    } else {
      setCurrentPage(1);
      getGames(Object.keys(defaultGames), 1, 2);
    }
    setGameType(gameType);
  };

  const onPageChange = (page) => {
    if (isSearchResultsShowing) {
      searchGames(page, null, null);
    } else {
      getCategorizedGames(gameType, page);
    }
  };

  const getAllGames = async () => {
    if (gameType) {
      getCategorizedGames(gameType, currentPage);
    } else {
      getGames(Object.keys(defaultGames), 1, 2);
    }
  };

  const getProviders = async () => {
    var response = await api.get("/games/providers");
    if (response.status === 200) {
      setProviders(response.data);
    }
  };

  const getSearchSuggestions = async (text) => {
    setSearchStr(text);
    if (text.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: text,
      });
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  const toggleSelectedProvider = (providerId) => {
    var selectedProvidersClone = JSON.parse(JSON.stringify(selectedProviders));
    if (providerId === null) {
      //All providers
      selectedProvidersClone = [];
    } else {
      var index = selectedProvidersClone.indexOf(providerId);
      if (index < 0) {
        selectedProvidersClone = [...selectedProvidersClone, providerId];
      } else {
        selectedProvidersClone.splice(index, 1);
      }
    }
    setSelectedProviders(selectedProvidersClone);
  };

  useFocusEffect(
    useCallback(() => {
      if (!initialLoadComplete) {
        setSpinner(false);
        getProviders();
        getAllGames();
        setInitialLoadComplete(true);
      }
    }, [initialLoadComplete])
  );

  const doSearchByGameName = (gameName) => {
    searchGames(1, gameName, null);
  };

  const searchGames = async (page, gameName, providers) => {
    var perPage = 12;
    setCurrentPage(page);
    if (gameName === null) {
      gameName = searchStr;
    } else {
      setSearchStr(gameName);
    }
    if (providers === null) {
      providers = selectedProviders;
    } else {
      setSelectedProviders(providers);
    }
    setSearchSuggestions([]);
    var serchParams = {
      providers: selectedProviders,
      search_query: gameName,
      page: page,
      per_page: perPage,
    };
    console.log(serchParams);
    var response = await api.post("/games/search", serchParams);
    setSpinner(false);
    if (response.status === 200) {
      setIsSearchResultsShowing(true);
      setGames(response.data.games);
      setGameType(null);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const openFilter = () => {
    setShowFilter(true);
  };
  const closeFilter = () => {
    setShowFilter(false);
  };
  const applyFilter = async () => {
    setSpinner(true);
    searchGames(1, null, selectedProviders);
    setShowFilter(false);
  };

  const clearFilters = () => {
    setSelectedProviders([]);
    // searchGames(1, null, []);
  };

  const GameTypeButton = ({
    styles,
    typeName,
    Icon,
    ActiveIcon,
    text,
    onPress,
  }) => {
    const onPressFunc = () => {
      if (typeof onPress === "function") {
        onPress.call(typeName);
      } else {
        typeName
          ? props.navigation.push("Games", { gameType: typeName })
          : props.navigation.push("Games");
      }
    };
    return (
      <Button
        style={[
          styles.gameTypeButton,
          gameType === typeName ? styles.gameTypeButtonActive : {},
        ]}
        onPress={onPressFunc}
      >
        <Image
          source={gameType === typeName ? ActiveIcon : Icon}
          type="svg"
          style={styles.gameTypeIcon}
        />
        <Text
          style={[
            styles.gameTypeText,
            gameType === typeName ? styles.gameTypeTextActive : {},
          ]}
        >
          {text}
        </Text>
      </Button>
    );
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView style={{ backgroundColor: "white" }}>
        <Spinner show={spinner} />

        <Box style={styles.fullHeight} onLayout={onLayoutChange}>
          <AppHeader />
          <ScrollView
            stickyHeaderIndices={[0]}
            refreshControl={
              <RefreshControl refreshing={spinner} onRefresh={onRefresh} />
            }
          >
            <ScrollView style={styles.gameTypesScrollView} horizontal={true}>
              <Box style={styles.gameTypes}>
                <GameTypeButton
                  typeName={null}
                  Icon={PopularIcon}
                  ActiveIcon={PopularIconActive}
                  text={__("All Games")}
                  styles={styles}
                />
                <GameTypeButton
                  typeName="popular"
                  Icon={PopularIcon}
                  ActiveIcon={PopularIconActive}
                  text={__("Popular")}
                  styles={styles}
                />
                <GameTypeButton
                  typeName="video_slots"
                  Icon={SlotsIcon}
                  ActiveIcon={SlotsIconActive}
                  text={__("Slots") + " "}
                  styles={styles}
                />
                <GameTypeButton
                  typeName="table_games"
                  Icon={TableGamesIcon}
                  ActiveIcon={TableGamesIconActive}
                  text={__("Table Games")}
                  styles={styles}
                />
                <GameTypeButton
                  typeName="live_games"
                  Icon={LiveGamesIcon}
                  ActiveIcon={LiveGamesIconActive}
                  text={__("Live Games")}
                  styles={styles}
                />
                <GameTypeButton
                  typeName="jackpot"
                  Icon={JackpotIcon}
                  ActiveIcon={JackpotIconActive}
                  text={__("Jackpot")}
                  styles={styles}
                />
                {/*<GameTypeButton
                  typeName="sportsbook"
                  Icon={SportsBookIcon}
                  ActiveIcon={SportsBookIconActive}
                  text={" " + __("Sportsbook")}
                  onPress={() => {
                    navigateTo("Sportsbook");
                  }}
                  styles={styles}
                />*/}
                <GameTypeButton
                  typeName="new"
                  Icon={NewGamesIcon}
                  ActiveIcon={NewGamesIconActive}
                  text={__("New")}
                  styles={styles}
                />
                <GameTypeButton
                  typeName="bonus"
                  Icon={BonusGamesIcon}
                  ActiveIcon={BonusGamesIconActive}
                  text={__("Bonus")}
                  styles={styles}
                />
              </Box>
            </ScrollView>

            <Box style={styles.gameSearchBoxOuter}>
              <View style={[styles.gameSearchBox, styles.flexBox]}>
                <InputBox
                  onChangeText={(text) => {
                    getSearchSuggestions(text);
                  }}
                  value={searchStr}
                  boxStyle={styles.searchBar}
                  placeholder="Search"
                />
                <Button style={styles.searchBtn} onPress={openFilter}>
                  <Image
                    source={ConfigIcon}
                    type="svg"
                    style={styles.searchBtnIcon}
                  />
                </Button>
              </View>
              <View
                style={[
                  styles.searchResults,
                  searchSuggestions.length ? { display: "flex" } : {},
                ]}
              >
                {searchSuggestions.map((suggestion, index) => {
                  return (
                    <Text
                      key={index}
                      onPress={() => doSearchByGameName(suggestion)}
                      style={styles.searchResult}
                    >
                      {suggestion}
                    </Text>
                  );
                })}
              </View>
            </Box>
            <Box style={styles.gamesListContainerAll}>
              {Object.keys(games).map((type, index) => {
                return games[type].gamelist.length ? (
                  <Box key={index} style={styles.gamesListContainer}>
                    <View style={styles.gameListHeader}>
                      <Text style={styles.gameListHeaderText}>
                        {gameTitles[type]}
                      </Text>
                      {gameType === null && !isSearchResultsShowing && (
                        <Text
                          onPress={() => {
                            getCategorizedGames(type);
                          }}
                          style={styles.gameListHeaderShowMore}
                        >
                          {__("Show more")}
                        </Text>
                      )}
                    </View>
                    <View style={styles.gameList}>
                      {games[type].gamelist.map((game, index) => {
                        return <GameItem key={index} game={game} />;
                      })}
                    </View>
                  </Box>
                ) : (
                  <Text key={index}></Text>
                );
              })}
              <Pagination data={pagination} onPressPage={onPageChange} />
            </Box>
          </ScrollView>
          {showFilter && (
            <Box style={styles.gamesFilterOverlay}>
              <Box style={styles.gamesFilter}>
                <View style={styles.flexBox}>
                  <Text style={styles.filterText}>Search Filter</Text>
                  <Button
                    colorScheme="clear"
                    style={styles.filterCloseBtn}
                    onPress={closeFilter}
                  >
                    <Icon
                      as={<AntDesign name="close" />}
                      size="sm"
                      m={2}
                      color={theme.textColor}
                    />
                  </Button>
                </View>
                <ScrollView>
                  <Text style={styles.vendorsText}>Vendors</Text>
                  <Box style={[styles.vendors, styles.flexBox]}>
                    <Button
                      colorScheme="clear"
                      onPress={() => {
                        toggleSelectedProvider(null);
                      }}
                      style={[
                        styles.vendorBtn,
                        selectedProviders.length
                          ? {}
                          : styles.vendorBtnSelected,
                      ]}
                    >
                      <Text style={styles.vendorBtnText}>
                        {__("All Providers")}
                      </Text>
                    </Button>
                    {providers.map((val, index) => {
                      return (
                        <Button
                          key={index}
                          onPress={() => {
                            toggleSelectedProvider(val.provider_id);
                          }}
                          colorScheme="clear"
                          style={[
                            styles.vendorBtn,
                            selectedProviders.indexOf(val.provider_id) < 0
                              ? {}
                              : styles.vendorBtnSelected,
                          ]}
                        >
                          <Text style={styles.vendorBtnText}>{val.name}</Text>
                        </Button>
                      );
                    })}
                  </Box>

                  <Center width="100%" style={{ marginTop: 20 }}>
                    <Button onPress={applyFilter} style={styles.applyFilterBtn}>
                      <Text style={styles.applyFilterBtnText}>
                        Apply Filters
                      </Text>
                    </Button>
                    <Button
                      onPress={clearFilters}
                      colorScheme="clear"
                      style={[styles.applyFilterBtn, styles.clearFilterBtn]}
                    >
                      <Text
                        style={[
                          styles.applyFilterBtnText,
                          styles.clearFilterBtnText,
                        ]}
                      >
                        {__("Clear All")}
                      </Text>
                    </Button>
                  </Center>
                </ScrollView>
              </Box>
            </Box>
          )}
          <FooterMenu />
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
