export default {
  primaryColor: "#C3B39A",
  lighterColor: "#EAE4DB", //Faced buttons etc
  darkerColor: "#8F7143", //Hover of buttons, inputs etc
  inputTextColor: "#1F1F25",
  textColor: "#1F1F25",
  placeholderColor: "gray",
  shadowColor: "lightgray",
  buttonTextColor: "white",
  invertedButtonColor: "white",
};
