import React, { Component, useState, useEffect, useMemo } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Image, Platform, SafeAreaView } from "react-native";
import { StdAlert, BinaryAlert } from "elements/Alert";
import getStyles from "styles/Login";
import theme from "services/theme";
import api from "services/api";
import {
  View,
  Center,
  Button,
  Text,
  Icon,
  Input,
  Box,
  KeyboardAvoidingView,
} from "native-base";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";
import { AntDesign } from "@expo/vector-icons";

import Hr from "elements/Hr";
import InputBox from "elements/InputBox";
import AppHeaderLight from "elements/AppHeaderLight";
import FullHeightScrollView from "elements/FullHeightScrollView";
import Spinner from "elements/Spinner";

export default function ForgotPassword(props) {
  const [styles, setStyles] = useState(getStyles());

  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  const [email, setEmail] = useState("");
  const navigation = props.navigation;
  useEffect(() => {
    setEmail("");
  }, []);
const { __ } = React.useContext(require("contexts/LangContext").default);
  const gotoLogin = () => {
    navigation.navigate("Login");
  };

  const [spinner, setSpinner] = useState(false);

  const resetPassword = async () => {
    setSpinner(true);
    var response = await api.post("/request-reset-password", { email: email });
    setSpinner(false);
    if (response.status === 200) {
      StdAlert(
        "Success!!",
        __("An email containing instructions to reset your password has been sent."),
        () => {
          navigation.navigate("Login");
        }
      );
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={spinner} />
        <AppHeaderLight pageName={__("Forgot Password")} />
        <FullHeightScrollView style={styles.scrollView}>
          <Center height={windowHeight() - 80} width={windowWidth()}>
            <View style={[styles.fullWidth, styles.form]}>
              <Box style={styles.mainBlock}>
                <Text style={styles.mainHeader}>{__("Forgot your password!")}</Text>
                <Text style={styles.mainText}>
                  {__("Enter your email below to receive instructions about resetting your password.")}
                </Text>
              </Box>
              <InputBox
                onChangeText={(value) => {
                  setEmail(value);
                }}
                color={theme.color}
                label={__("Email")}
                value={email}
                placeholder={__("Email")}
              />

              <Center width="100%">
                <Button onPress={resetPassword} style={styles.submitBtn}>
                  {__("Recover Password")}
                </Button>
              </Center>

              <Hr style={styles.divider} />

              <Center width="100%">
                <Text style={styles.register}>
                  {__("Already have an account?")}{" "}
                  <Text style={styles.registerBtn} onPress={gotoLogin}>
                    {__("LOG IN")}
                  </Text>
                </Text>
              </Center>
            </View>
          </Center>
        </FullHeightScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
