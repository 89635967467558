import React, { useEffect, useState, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import getStyles from "styles/UserMenu";
import theme from "services/theme";
import { createStackNavigator } from "@react-navigation/stack";
import AppContext from "contexts/AppContext";
import auth from "services/auth";
import {
  FontAwesome5,
  Ionicons,
  Fontisto,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
} from "@react-navigation/drawer";
import { Button, Text, View, Icon, Box, Center } from "native-base";
import { TouchableHighlight } from "react-native";
import Hr from "elements/Hr";
import Image from "elements/Image";
import Logo from "assets/images/logo.svg";
import LanguageSwitcher from "elements/LanguageSwitcher";
import Login from "screens/users/Login";
import SignUp from "screens/users/SignUp";
import ForgotPassword from "screens/users/ForgotPassword";
import ActivateAccount from "screens/users/ActivateAccount";

import Games from "screens/games/Games";
import BackToGames from "screens/games/BackToGames";
import Play from "screens/games/Play";
import Sportsbook from "screens/games/Sportsbook";
import Promotions from "screens/pages/Promotions";
import LoyaltyAndVIP from "screens/pages/LoyaltyAndVIP";
import AmlPolicy from "screens/pages/AmlPolicy";
import TermsAndConditions from "screens/pages/TermsAndConditions";
import BonusTerms from "screens/pages/BonusTerms";
import PrivacyPolicy from "screens/pages/PrivacyPolicy";
import ResponsibleGaming from "screens/pages/ResponsibleGaming";
import Support from "screens/pages/Support";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

function CasinoXbit(props) {
  return (
    <Stack.Navigator
      initialRouteName="Login"
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Login" component={Login} />
      <Stack.Screen name="SignUp" component={SignUp} />
      <Stack.Screen name="Forgot Password" component={ForgotPassword} />
      <Stack.Screen name="Activate Account" component={ActivateAccount} />
      <Stack.Screen name="Games" component={Games} />
      <Stack.Screen name="BackToGames" component={BackToGames} />
      <Stack.Screen name="Play" component={Play} />
      <Stack.Screen name="Sportsbook" component={Sportsbook} />
      <Stack.Screen name="Promotions" component={Promotions} />
      <Stack.Screen name="Loyalty And VIP" component={LoyaltyAndVIP} />
      <Stack.Screen name="AML Policy" component={AmlPolicy} />
      <Stack.Screen
        name="Terms And Conditions"
        component={TermsAndConditions}
      />
      <Stack.Screen name="Bonus Terms" component={BonusTerms} />
      <Stack.Screen name="Privacy Policy" component={PrivacyPolicy} />
      <Stack.Screen name="Responsible Gaming" component={ResponsibleGaming} />
      <Stack.Screen name="Support" component={Support} />
    </Stack.Navigator>
  );
}

export default function UserMenu(props) {
  return (
    <Drawer.Navigator
      initialRouteName="LeftDrawer"
      screenOptions={{
        headerShown: false,
        drawerPosition: "right",
        drawerType: "front",
        overlayColor: "transparent",
        drawerStyle: { width: "0%", backgroundColor: "#000000" },
      }}
    >
      <Drawer.Screen
        name="LeftDrawer"
        component={LeftDrawer}
        style={{ backgroundColor: "#000000", height: 0 }}
        options={{
          drawerLabel: () => null,
          title: null,
          drawerIcon: () => null,
        }}
      />
    </Drawer.Navigator>
  );
}

function LeftDrawer(props) {
  return (
    <Drawer.Navigator
      initialRouteName="CasinoXbit"
      screenOptions={{
        headerShown: false,
        drawerPosition: "left",
        drawerType: "front",
        overlayColor: "transparent",
        drawerStyle: { width: "80%", backgroundColor: "#000000" },
      }}
      drawerContent={(props) => <CustomDrawerContentLeft {...props} />}
    >
      <Drawer.Screen
        name="CasinoXbit"
        component={CasinoXbit}
        style={{ backgroundColor: "#000000", height: 0 }}
        options={{
          drawerLabel: () => null,
          title: null,
          drawerIcon: () => null,
        }}
      />
    </Drawer.Navigator>
  );
}

function CustomDrawerContentLeft(props) {
  const [styles, setStyles] = useState(getStyles());

  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const navigation = props.navigation;
  const route = props.route;

  const [currentScreen, setCurrentScreen] = useState("Landing");

  const hideMenu = () => {
    navigation.closeDrawer();
  };
  const navigateTo = (screen) => {
    navigation.navigate(screen);
    setCurrentScreen(screen);
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);
  return (
    <DrawerContentScrollView
      style={styles.drawerLeft}
      {...props}
      onLayout={onLayoutChange}
    >
      <Box>
        <View style={styles.flexBox}>
          <Button style={styles.closeBtn} onPress={hideMenu}>
            <FontAwesome5 name="times" style={styles.closeBtnIcon} />
          </Button>
        </View>

        <View style={styles.menu}>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Login")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Login")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Games")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Play Now")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Promotions")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Promotions")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Loyalty And VIP")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Loyalty And VIP")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("AML Policy")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("AML Policy")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Terms And Conditions")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Terms And Conditions")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Bonus Terms")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Bonus Terms")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Privacy Policy")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Privacy Policy")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Responsible Gaming")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Responsible Gaming")}</Text>
          </TouchableHighlight>

          <LanguageSwitcher
            onChangeLanguage={hideMenu}
            style={[
              styles.menuItem,
              styles.menuText,
              { color: "white", borderBottomWidth: 0 },
            ]}
          />
        </View>
      </Box>
    </DrawerContentScrollView>
  );
}
