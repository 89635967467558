import React, { Component } from "react";
import { StyleSheet } from "react-native";
import { Button, Text, Icon } from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";

function BackButton() {
  const navigation = useNavigation();
  const goBack = () => {
    navigation.goBack();
  };
  return (
    <Button onPress={goBack} transparent>
      <AntDesign style={styles.icon} name="left" />
    </Button>
  );
}

function MenuButton() {
  const navigation = useNavigation();
  return (
    <Button
      transparent
      style={styles.icon}
      onPress={() => navigation.openDrawer()}
    >
      <AntDesign style={styles.icon} name="bars" />
    </Button>
  );
}

const styles = StyleSheet.create({
  icon: {
    color: "white",
    fontSize: 30,
    fontWeight:"700",
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto"
  }
});

export { MenuButton, BackButton };
