export const StdAlert = (title, desc, onPress = () => {}) => {
  alert(`${title}\n${desc}`);
  if (onPress) onPress();
};

export const BinaryAlert = (
  title,
  desc,
  onPositivePress = () => {},
  onNegativePress = () => {}
) => {
  const res = window.confirm(`${title}\n${desc}`);
  if (res) onPositivePress();
  else onNegativePress();
};
