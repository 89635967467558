import React, { useState, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import Spinner from "elements/Spinner";
import RenderHtml from "elements/RenderHtml";
import api from "services/api";
import { SafeAreaView, ScrollView } from "react-native";
import getStyles from "styles/SimplePages";
import theme from "services/theme";
import { View, Text, Box, KeyboardAvoidingView } from "native-base";
import SignUpButton from "elements/SignUpButton";
import Hr from "elements/Hr";
import Br from "elements/Br";
import SubPageHeader from "elements/SubPageHeader";
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";

export default function ResponsibleGaming(props) {
  const [styles, setStyles] = useState(getStyles());

  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);

  const [spinner, setSpinner] = useState(false);

  const [pageContent, setPageContent] = useState({});
  const getContents = async () => {
    setSpinner(true);
    var response = await api.get("/get-content-page", {
      page_uid: "responsible_gaming",
    });
    setSpinner(false);
    if (response.status === 200) {
      setPageContent(response.data.content_data);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  useFocusEffect(
    useCallback(() => {
      getContents();
    }, [])
  );
  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Box style={styles.fullHeight}>
          <AppHeader />
          <ScrollView>
            <SubPageHeader
              pageName={__("Responsible Gaming")}
              style={styles.subpageHeader}
              openLeftMenuOnBack={true}
            />

            <Box style={styles.pageText}>
              <RenderHtml
                style={styles.pageTextPara}
                html={pageContent.content}
              />
            </Box>

            <SignUpButton />

            <View style={[styles.fullWidth, styles.form]}>
              <View style={{ width: "100%", height: 30 }}></View>
            </View>
          </ScrollView>
          <FooterMenu />
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
