import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import {
  windowHeight,
  windowWidth,
  scaleFactor,
  scrollViewStyleWithoutFooter,
} from "services/scaleFactor";

export default function getStyles() {
  var styles = StyleSheet.create({
    fullHeight: {
      height: Platform.OS === "web" ? window.innerHeight : "100%",
    },
    fullWidth: {
      width: Platform.OS === "web" ? "100vw" : "100%",
    },
    scrollView: { ...scrollViewStyleWithoutFooter() },
    form: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    steps: {
      width: "70%",
      flexDirection: "row",
      marginTop: 30,
    },
    step: {
      width: 35,
      height: 35,
      borderRadius: 17,
      borderColor: theme.primaryColor,
      borderWidth: 1,
      fontSize: 14,
      backgroundColor: 'white',
      fontFamily: "GilroyLight",
      textAlign: "center",
      lineHeight: 32,
    },
    stepDivider: {
      flex: 1,
      top: -18,
      marginLeft: 4,
      marginRight: 4,
    },
    activeStep: {
      backgroundColor: theme.primaryColor,
      color: theme.buttonTextColor,
      borderRadius: 17,
      overflow: 'hidden'
    },
    welcome: {
      fontFamily: "GilroyExtraBold",
      fontSize: 20,
      marginTop: 20,
    },
    welcomeSmall: {
      fontFamily: "GilroyLight",
      fontSize: 13,
      color: theme.textColor,
      marginTop: 10,
    },
    welcomeSmallBtn: {
      fontFamily: "GilroyLight",
      fontSize: 14,
      color: theme.primaryColor,
    },
    divider: {
      marginTop: 10,
    },
    registerBy: {
      fontFamily: "GilroyLight",
      fontSize: 16,
      color: theme.textColor,
      marginTop: 7,
    },
    stepHeading: {
      fontFamily: "GilroyLight",
      fontSize: 15,
      color: theme.textColor,
      marginTop: 10,
    },
    bonusOfferBox: {
      borderRadius: 10,
      overflow: "hidden",
      borderWidth: 1,
      borderColor: theme.primaryColor,
      marginTop: 15,
    },
    bonusOfferHeader: {
      fontFamily: "GilroyLight",
      fontSize: 13,
      color: theme.textColor,
      backgroundColor: theme.primaryColor,
      lineHeight: 20,
      paddingLeft: 10,
    },
    bonusOfferBanner: {
      width: "100%",
      height: 60,
    },
    bonusOfferInner: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
    },
    bonusCheckbox: {
      marginLeft: 10,
    },
    checkboxLabel: {
      fontFamily: "GilroyExtraBold",
      fontSize: 14,
      lineHeight: 30,
      color: theme.textColor,
    },
    bonusText: {
      fontFamily: "GilroyLight",
      fontSize: 14,
      color: theme.textColor,
      lineHeight: 20,
    },
    bonusLinks: {
      flexDirection: "row",
      marginTop: 10,
    },
    bonusLinkLeft: {
      fontFamily: "GilroyLight",
      fontSize: 13,
      color: theme.primaryColor,
    },
    bonusLinkRight: {
      marginLeft: "auto",
    },
    inputHelpText: {
      fontFamily: "GilroyLight",
      fontSize: 12,
      color: theme.textColor,
      paddingLeft: 5,
      paddingRight: 13,
      marginTop: 4,
    },
    submitBtn: {
      color: theme.buttonTextColor,
      fontFamily: "GilroyLight",
      fontSize: 13,
      marginTop: 35,
      paddingLeft: 50,
      paddingRight: 50,
      borderRadius: 15,
    },
    divider: {
      marginTop: 50,
    },
    register: {
      fontFamily: "GilroyLight",
      fontSize: 14,
      color: theme.textColor,
      marginTop: 10,
    },
    registerBtn: {
      fontFamily: "GilroyLight",
      fontSize: 14,
      color: theme.primaryColor,
    },
    errorMessage: {
      fontFamily: "GilroyLight",
      fontSize: 12,
      color: "#FF2200",
      marginTop: 5,
    },
  });

  return styles;
}

// export default styles;
