import React, { Component, useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Image, Platform, BackHandler, SafeAreaView } from "react-native";
import { StdAlert, BinaryAlert } from "elements/Alert";
import getStyles from "styles/SignUp";
import theme from "services/theme";
import api from "services/api";
import {
  View,
  Center,
  Button,
  Text,
  Icon,
  Box,
  KeyboardAvoidingView,
} from "native-base";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";
import InputBox from "elements/InputBox";
import Hr from "elements/Hr";
import AppHeaderLight from "elements/AppHeaderLight";
import CheckBox from "elements/CheckBox";
import SelectBox from "elements/SelectBox";
import DatePicker from "elements/DatePicker";
import CountryAndPhoneInput from "elements/CountryAndPhoneInput";
import FullHeightScrollView from "elements/FullHeightScrollView";
import Spinner from "elements/Spinner";

export default function SignUp(props) {
  const navigation = props.navigation;

  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);
  const gotoLogin = () => {
    navigation.navigate("Login");
  };
  const gotoTerms = () => {
    navigation.navigate("Terms And Conditions");
  };
  const gotoBonusTerms = () => {
    navigation.navigate("Bonus Terms");
  };

  const [spinner, setSpinner] = useState(false);

  const [step, setStep] = useState(1);
  const nextStep = () => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      doRegister();
    }
  };
  const setPrevStep = (newStep) => {
    if (step > newStep) {
      //Preventing a forward step
      setStep(newStep);
    }
  };

  const doRegister = async () => {
    setSpinner(true);
    var response = await api.post("/signup", form);
    setSpinner(false);
    if (response.status === 200) {
      navigation.navigate("Activate Account", {
        email: form.email,
      });
    } else {
      if (response.data.errors) {
        var errors = response.data.errors;
        if (errors.bonusOffer) {
          setStep(1);
        } else if (errors.email || errors.password || errors.rePassword) {
          setStep(2);
        }
        setErrors(errors);
      } else {
        StdAlert("Warning!!", response.data.message);
      }
    }
  };

  useFocusEffect(
    useCallback(() => {
      const backAction = () => {
        if (step >= 2) {
          setStep(step - 1);
          return true; //Stop bubbling
        }
        return false; //Will bubble up
      };
      const backHandler = BackHandler.addEventListener(
        "hardwareBackPress",
        backAction
      );
      return () => backHandler.remove();
    }, [step])
  );

  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const currencies = [{ label: "XBTU", value: "USD" }];
  const [phoneCodes, setPhoneCodes] = useState([]);
  const [campaigns, setCampaigns] = useState([]);

  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200) {
      setCountries(
        response.data.map((val) => {
          return { label: val.nicename, value: val.code };
        })
      );
      setPhoneCodes(
        response.data.map((val) => {
          return {
            label: "+" + val.phonecode + " (" + val.code + ")",
            value: "+" + val.phonecode,
          };
        })
      );
    }
  };

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      setLanguages(
        response.data.map((val) => {
          return { label: val.language, value: val.code };
        })
      );
    }
  };

  const getIpDetails = async () => {
    var response = await api.get("/ip-details");
    if (response.status === 200) {
      var ipDetails = response.data;
      updateForm(ipDetails.ip_country, "country");
      updateForm(ipDetails.dialing_code, "phoneCode");
    }
  };

  const getCampaigns = async () => {
    if (form.country.length && form.currency.length) {
      var response = await api.post("/get-registration-campaigns", {
        country: form.country,
        currency: form.currency,
      });
      if (response.status === 200) {
        setCampaigns(response.data);
      }
    }
  };

  const emptyForm = {
    email: "",
    password: "",
    rePassword: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    dateOfBirth: "",
    gender: __("Male"),
    currency: "USD",
    language: "en",
    phoneCode: "",
    phone: "",
    agreeToTerms: false,
    receiveBonusOffer: false,
    bonusOffer: "",
  };

  const [form, setForm] = useState(emptyForm);

  const [errors, setErrors] = useState({});

  const chooseBonusOffer = (bonus) => {
    setForm({ ...form, bonusOffer: bonus });
  };

  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
    setErrors({ ...errors, [field]: null });
  };

  const getAllDetails = async () => {
    setSpinner(true);
    await getCountries();
    await getLanguages();
    await getIpDetails();
    setSpinner(false);
  };

  useFocusEffect(
    useCallback(() => {
      try {
        getAllDetails();
      } catch (ex) {
        console.log(ex);
      }
    }, [props.route])
  );

  useEffect(() => {
    getCampaigns();
  }, [form.currency, form.country]);

  const Steps = ({ step }) => {
    return (
      <Center width="100%">
        <View style={styles.steps}>
          <Text
            onPress={() => setPrevStep(1)}
            style={[styles.step, step === 1 ? styles.activeStep : {}]}
          >
            1
          </Text>
          <Hr style={styles.stepDivider} />
          <Text
            onPress={() => setPrevStep(2)}
            style={[styles.step, step === 2 ? styles.activeStep : {}]}
          >
            2
          </Text>
          <Hr style={styles.stepDivider} />
          <Text style={[styles.step, step === 3 ? styles.activeStep : {}]}>
            3
          </Text>
        </View>
      </Center>
    );
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={spinner} />
        <AppHeaderLight pageName={__("SIGN UP")} />
        <FullHeightScrollView>
          <Steps step={step} />

          <View style={[styles.fullWidth, styles.form]}>
            <Center width="100%">
              <Text style={styles.welcome}>{__("Welcome")}</Text>

              <Text style={styles.welcomeSmall}>
                {__("Welcome to :brand_name! Have fun and win")}{" "}
                <Text style={styles.welcomeSmallBtn}>{__("BIG!")}</Text>
              </Text>
              <Hr style={styles.divider} />
              <Text style={styles.registerBy}>
                {__("Register by entering your details")}
              </Text>
            </Center>
            {step === 1 && (
              <View>
                <Center width="100%">
                  <Text style={styles.stepHeading}>{__("Choose Offer")}</Text>
                </Center>
                {campaigns.map((campaign, index) => {
                  return (
                    <Box key={index} style={styles.bonusOfferBox}>
                      <Text style={styles.bonusOfferHeader}>
                        {campaign.title.toUpperCase()}
                      </Text>
                      <Image
                        style={styles.bonusOfferBanner}
                        source={{ uri: campaign.campaign_image }}
                      />
                      <CheckBox
                        type="radio"
                        style={styles.bonusCheckbox}
                        onPress={() => {
                          chooseBonusOffer(campaign.id);
                        }}
                        checked={form.bonusOffer === campaign.id}
                      >
                        <Text style={styles.checkboxLabel}>
                          {__("Select Bonus Campaign")}
                        </Text>
                      </CheckBox>
                      {form.bonusOffer === campaign.id && (
                        <Box style={styles.bonusOfferInner}>
                          <Text style={styles.bonusText}>
                            {campaign.campaign_information}
                          </Text>
                          <View style={styles.bonusLinks}>
                            <Text
                              style={styles.bonusLinkLeft}
                              onPress={gotoBonusTerms}
                            >
                              {__("Bonus Terms apply")}
                            </Text>
                            <Text
                              style={[
                                styles.bonusLinkLeft,
                                styles.bonusLinkRight,
                              ]}
                              onPress={gotoTerms}
                            >
                              {__("General Terms & Conditions apply")}
                            </Text>
                          </View>
                        </Box>
                      )}
                    </Box>
                  );
                })}

                <CheckBox
                  style={styles.bonusCheckbox}
                  type="radio"
                  onPress={() => {
                    chooseBonusOffer("no_bonus");
                  }}
                  checked={form.bonusOffer === "no_bonus"}
                >
                  <Text style={styles.checkboxLabel}>{__("No Bonus")}</Text>
                </CheckBox>

                <Text style={styles.errorMessage}>{errors.bonusOffer}</Text>
              </View>
            )}
            {step === 2 && (
              <View>
                <Center width="100%">
                  <Text style={styles.stepHeading}>
                    {__("Contact Information")}
                  </Text>
                </Center>
                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "email");
                  }}
                  color={theme.color}
                  value={form.email}
                  placeholder="Email"
                />
                <Text style={styles.inputHelpText}>
                  {__("Your email will be used to log in.")}
                </Text>
                <Text style={styles.errorMessage}>{errors.email}</Text>

                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "password");
                  }}
                  color={theme.color}
                  placeholder={__("Password")}
                  type="password"
                  value={form.password}
                />
                <Text style={styles.inputHelpText}>
                  {__(
                    "Your password must contain between 8 and 30 characters, ncluding at least one number and one special character. Don’t use your email address or username as your password."
                  )}
                </Text>
                <Text style={styles.errorMessage}>{errors.password}</Text>

                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "rePassword");
                  }}
                  color={theme.color}
                  placeholder={__("Confirm Password")}
                  type="password"
                  value={form.rePassword}
                />
                <Text style={styles.inputHelpText}>
                  {__("Confirm your password please.")}
                </Text>
                <Text style={styles.errorMessage}>{errors.rePassword}</Text>
              </View>
            )}

            {step === 3 && (
              <View>
                <Center width="100%">
                  <Text style={styles.stepHeading}>
                    {__("Contact Information")}
                  </Text>
                </Center>
                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "firstName");
                  }}
                  color={theme.color}
                  value={form.firstName}
                  placeholder={__("First Name")}
                />
                <Text style={styles.errorMessage}>{errors.firstName}</Text>

                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "lastName");
                  }}
                  color={theme.color}
                  value={form.lastName}
                  placeholder={__("Last Name")}
                />
                <Text style={styles.errorMessage}>{errors.lastName}</Text>

                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "address");
                  }}
                  color={theme.color}
                  value={form.address}
                  placeholder={__("Address")}
                />
                <Text style={styles.errorMessage}>{errors.address}</Text>

                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "city");
                  }}
                  color={theme.color}
                  value={form.city}
                  placeholder={__("City")}
                />
                <Text style={styles.errorMessage}>{errors.city}</Text>

                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "state");
                  }}
                  color={theme.color}
                  value={form.state}
                  placeholder={__("State")}
                />
                <Text style={styles.errorMessage}>{errors.state}</Text>

                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "zip");
                  }}
                  color={theme.color}
                  value={form.zip}
                  placeholder={__("Zip Code")}
                />
                <Text style={styles.errorMessage}>{errors.zip}</Text>

                <SelectBox
                  selectedValue={form.country}
                  label={__("Country")}
                  minWidth={200}
                  placeholder={__("Country")}
                  onValueChange={(itemValue) =>
                    updateForm(itemValue, "country")
                  }
                  items={countries}
                />
                <Text style={styles.errorMessage}>{errors.country}</Text>

                <DatePicker
                  label={__("Date of Birth")}
                  value={form.dateOfBirth}
                  onValueChange={(value) => {
                    updateForm(value, "dateOfBirth");
                  }}
                />
                <Text style={styles.errorMessage}>{errors.dateOfBirth}</Text>

                <SelectBox
                  selectedValue={form.gender}
                  label={__("Gender")}
                  minWidth={200}
                  placeholder={__("Gender")}
                  onValueChange={(itemValue) => updateForm(itemValue, "gender")}
                  items={[
                    { label: __("Male"), value: "Male" },
                    { label: __("Female"), value: "Female" },
                    { label: __("Other"), value: "Other" },
                  ]}
                />
                <Text style={styles.errorMessage}>{errors.gender}</Text>

                <SelectBox
                  selectedValue={form.currency}
                  label={__("Curency of Game Play")}
                  minWidth={200}
                  placeholder={__("Curency of Game Play")}
                  onValueChange={(itemValue) =>
                    updateForm(itemValue, "currency")
                  }
                  items={currencies}
                />
                <Text style={styles.errorMessage}>{errors.currency}</Text>

                <SelectBox
                  selectedValue={form.language}
                  label={__("Language")}
                  minWidth={200}
                  placeholder={__("Language")}
                  onValueChange={(itemValue) =>
                    updateForm(itemValue, "language")
                  }
                  items={languages}
                />
                <Text style={styles.errorMessage}>{errors.language}</Text>

                <CountryAndPhoneInput
                  label={__("Phone Number")}
                  phoneCodes={phoneCodes}
                  phoneCodeValue={form.phoneCode}
                  phoneValue={form.phone}
                  onPhoneChange={(value) => {
                    updateForm(value, "phone");
                  }}
                  onCodeChange={(value) => {
                    updateForm(value, "phoneCode");
                  }}
                />
                <Text style={styles.errorMessage}>{errors.phone}</Text>

                <CheckBox
                  type="checkbox"
                  style={styles.bonusCheckbox}
                  onPress={() => {
                    updateForm(!form.agreeToTerms, "agreeToTerms");
                  }}
                  checked={form.agreeToTerms === true}
                >
                  <Text style={styles.checkboxLabel}>
                    {__("Accept Terms & Conditions")}
                  </Text>
                </CheckBox>
                <Text style={styles.errorMessage}>{errors.agreeToTerms}</Text>

                <CheckBox
                  type="checkbox"
                  style={styles.bonusCheckbox}
                  onPress={() => {
                    updateForm(!form.receiveBonusOffer, "receiveBonusOffer");
                  }}
                  checked={form.receiveBonusOffer === true}
                >
                  <Text style={styles.checkboxLabel}>
                    {__("Accept bonus offers")}
                  </Text>
                </CheckBox>
              </View>
            )}
            <Center width="100%">
              <Button onPress={nextStep} style={styles.submitBtn}>
                {step < 3 ? __("Next Step") : __("Create Account")}
              </Button>
            </Center>

            <Hr style={styles.divider} />

            <Center width="100%">
              <Text style={styles.register}>
                {__("Already have an account?")}{" "}
                <Text style={styles.registerBtn} onPress={gotoLogin}>
                  {__("LOG IN")}
                </Text>
              </Text>
            </Center>
            <View style={{ width: "100%", height: 20 }}></View>
          </View>
        </FullHeightScrollView>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
