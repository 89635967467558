import React, { useState, forwardRef, useRef, memo } from "react";
import { StyleSheet } from "react-native";
import { Text, Button, Box, Icon } from "native-base";
import DatePicker from "react-mobile-datepicker";
import { AntDesign } from "@expo/vector-icons";
import moment from "moment";
import theme from "services/theme";

const Datepicker = (props, ref) => {
  var styles = {
    inputBox: {
      width: "100%",
      paddingTop: "9px",
      marginTop: props.label ? 5 : 0,
    },
    input: {
      overflow: "hidden",
      width: "100%",
      borderColor: theme.primaryColor,
      borderWidth: 1,
      color: theme.inputTextColor,
      height: 45,
      borderRadius: 20,
      paddingLeft: 3,
      flexDirection: "row",
      fontSize: 15,
      boxShadow: "none",
    },
    trigger: {
      backgroundColor: "transparent",
      flex: 1,
    },
    triggerText: {
      fontSize: 14,
      lineHeight: 45,
      paddingLeft: 10
    },
    okButton: {
      paddingLeft: 5,
      paddingRight: 5,
    },
    okIcon: {},
    label: {
      fontSize: 14,
      color: theme.primaryColor,
      position: "absolute",
      top: 0,
      marginLeft: 30,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 5,
      paddingRight: 5,
      backgroundColor: "white",
      zIndex: 4,
    },
  };

  const doNothing = () => {};

  const [show, setShow] = useState(false);
  const showDatePicker = () => {
    setShow(true);
  };
  const hideDatePicker = () => {
    setShow(false);
  };

  const onChange = (date) => {
    props.onValueChange(moment(date).format("YYYY-MM-DD"));
    hideDatePicker();
  };

  return (
    <Box style={[styles.inputBox, props.boxStyle ? props.boxStyle : {}]}>
      {props.label && <Text style={styles.label}>{props.label}</Text>}
      <Box ref={ref} style={styles.input}>
        <Box ref={ref} style={styles.trigger} onPress={showDatePicker}>
          <Text style={styles.triggerText}>
            {props.value
              ? moment(props.value, "YYYY-MM-DD").format("MMM DD, YYYY")
              : props.placeholder
              ? props.placeholder
              : ""}
          </Text>
        </Box>
        {show ? (
          <Button
            onPress={hideDatePicker}
            style={styles.okButton}
            colorScheme="clear"
          >
            <Icon
              style={styles.okIcon}
              as={<AntDesign name="checkcircle" />}
              size="sm"
              m={2}
              color={theme.shadowColor}
            />
          </Button>
        ) : (
          <Button
            onPress={showDatePicker}
            style={styles.okButton}
            colorScheme="clear"
          >
            <Icon
              style={styles.okIcon}
              as={<AntDesign name="calendar" />}
              size="sm"
              m={2}
              color={theme.shadowColor}
            />
          </Button>
        )}
      </Box>

      <DatePicker
        value={
          props.value ? moment(props.value, "YYYY-MM-DD").toDate() : new Date()
        }
        isOpen={show}
        onSelect={onChange}
        onCancel={hideDatePicker}
        confirmText="Ok"
        cancelText="Cancel"
        showHeader={false}
        min={
          props.minValue
            ? moment(props.minValue, "YYYY-MM-DD").toDate()
            : undefined
        }
        max={
          props.maxValue
            ? moment(props.maxValue, "YYYY-MM-DD").toDate()
            : undefined
        }
      />
    </Box>
  );
};

export default memo(forwardRef(Datepicker));
