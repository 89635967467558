import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import {
  windowHeight,
  windowWidth,
  scaleFactor,
  orientation,
  scrollViewStyle,
  scrollViewHeight,
} from "services/scaleFactor";

export default function getStyles() {

  var styles = {
    fullWidth: {
      width: Platform.OS === "web" ? "100vw" : "100%",
    },
    fullHeight: {
      height: Platform.OS === "web" ? window.innerHeight : "100%",
    },
    webViewBox: {
      flex: 1,
      width: windowWidth() <= 340 ? windowWidth() : windowWidth() -20,
      backgroundColor: "white",
      marginTop: 0,
      marginLeft: windowWidth() <= 340 ? (windowWidth() <= 320 ? -20 : 0) : 10,
      marginRight: windowWidth() <= 340 ? 0 : 10,
    },
    ...Platform.select({
      web: {
        webView: {
          flex: 1,
          border: "none",
          backgroundColor: "white",
          marginTop: 0,
        },
      },
      default: {
        webView: {
          flex: 1,
          width: windowWidth() <= 320 ? windowWidth() + 30 : "100%",
          backgroundColor: "white",
          marginTop: 0,
        },
      },
    }),
    tabs: {
      flexDirection: "row",
      borderColor: theme.primaryColor,
      borderWidth: 1,
      borderRadius: 25,
      padding: 1.5,
      marginTop: 10,
      marginBottom: 10,
      overflow: "hidden",
      height: 40,
    },
    tab: {
      borderRadius: 25,
      overflow: "hidden",
      backgroundColor: "transparent",
      minWidth: 100,
    },
    tabText: {
      fontFamily: "GilroyLight",
    },
    tabActive: {
      backgroundColor: theme.primaryColor,
    },
    tabTextActive: {
      color: theme.buttonTextColor,
    },
    form: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    emailMain: {
      fontFamily: "GilroyLight",
      fontSize: 15,
      color: theme.textColor,
      marginBottom: 20,
    },
    submitBtn: {
      color: theme.buttonTextColor,
      fontFamily: "GilroyLight",
      fontSize: 13,
      marginTop: 35,
      paddingLeft: 50,
      paddingRight: 50,
      borderRadius: 15,
    },
  };

  if (Platform.OS !== "web") {
    styles = StyleSheet.create(styles);
  }

  return styles;
}
// export default styles;
