import { Dimensions, Platform } from "react-native";

var windowHeight = () => {
  return Platform.select({
    android: Dimensions.get("window").height,
    ios: Dimensions.get("window").height,
    default: Dimensions.get("window").height,
  });
};
const windowWidth = () => {
  return Dimensions.get("window").width;
};

const orientation = () => {
  var orientation = "portrait";
  if (windowWidth() > windowHeight()) {
    orientation = "landscape";
  }
  return orientation;
};

const scrollViewHeight = (withFooter) => {
  withFooter = withFooter === undefined ? true : false;
  var height = windowHeight() - 52;

  if (withFooter) {
    if (orientation() == "landscape") {
      height = height - scaleFactorW(50, 70);
    } else {
      height = height - scaleFactor(65, 70);
    }
  }
  return height;
};

const scrollViewStyle = () => {
  return Platform.select({
    web: {
      height: scrollViewHeight(),
    },
    ios: {
      height: scrollViewHeight(),
    },
    default: {
      height: scrollViewHeight(),
    },
  });
};

const scrollViewStyleWithoutFooter = () => {
  return Platform.select({
    web: {
      height: scrollViewHeight(false),
    },
    ios: {
      height: scrollViewHeight(false),
    },
    default: {
      height: scrollViewHeight(false),
    },
  });
};

// const scaleFactor = windowHeight / 568;
const scaleFactor = (value, maxValue, minValue) => {
  const factor = windowWidth() / 375;
  var convertedValue = value * factor;
  if (
    (maxValue !== undefined || maxValue === null) &&
    convertedValue > maxValue
  ) {
    convertedValue = maxValue;
  } else if (
    (minValue !== undefined || minValue === null) &&
    convertedValue < minValue
  ) {
    convertedValue = minValue;
  }
  return convertedValue;
};
// const scaleFactor = windowHeight / 568;
const scaleFactorW = (value, maxValue, minValue) => {
  const factor = windowHeight() / 375;
  var convertedValue = value * factor;
  if (
    (maxValue !== undefined || maxValue === null) &&
    convertedValue > maxValue
  ) {
    convertedValue = maxValue;
  } else if (
    (minValue !== undefined || minValue === null) &&
    convertedValue < minValue
  ) {
    convertedValue = minValue;
  }
  return convertedValue;
};

const boxShadowStyle = (color) => {
  return {
    shadowColor: color,
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 4,
  };
};

export {
  windowHeight,
  windowWidth,
  scaleFactor,
  scaleFactorW,
  orientation,
  boxShadowStyle,
  scrollViewHeight,
  scrollViewStyle,
  scrollViewStyleWithoutFooter,
};
