import React, { useEffect, useState } from "react";
import getStyles from "styles/UserMenu";
import theme from "services/theme";
import { createStackNavigator } from "@react-navigation/stack";
import AppContext from "contexts/AppContext";
import auth from "services/auth";
import ls from "services/ls";
import LanguageSwitcher from "elements/LanguageSwitcher";
import {
  FontAwesome5,
  Ionicons,
  Fontisto,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
} from "@react-navigation/drawer";
import { Button, Text, View, Icon, Box, Center } from "native-base";
import { TouchableHighlight } from "react-native";
import { useFocusEffect } from "@react-navigation/native";
import Hr from "elements/Hr";
import Image from "elements/Image";
import Logo from "assets/images/logo.svg";

import Games from "screens/games/Games";
import BackToGames from "screens/games/BackToGames";
import Play from "screens/games/Play";
import Sportsbook from "screens/games/Sportsbook";
import Cashier from "screens/account/Cashier";
import Profile from "screens/account/Profile";
import TransactionHistory from "screens/account/TransactionHistory";
import GamingHistory from "screens/account/GamingHistory";
import BalanceTransferHistory from "screens/account/BalanceTransferHistory";
import BonusHistory from "screens/account/BonusHistory";
import FreeSpins from "screens/account/FreeSpins";
import AccessControl from "screens/account/AccessControl";
import Promotions from "screens/pages/Promotions";
import LoyaltyAndVIP from "screens/pages/LoyaltyAndVIP";
import AmlPolicy from "screens/pages/AmlPolicy";
import TermsAndConditions from "screens/pages/TermsAndConditions";
import BonusTerms from "screens/pages/BonusTerms";
import PrivacyPolicy from "screens/pages/PrivacyPolicy";
import ResponsibleGaming from "screens/pages/ResponsibleGaming";
import Support from "screens/pages/Support";

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

function CasinoXbit(props) {
  return (
    <Stack.Navigator
      
      screenOptions={{
        headerShown: false,
      }}
    >
      <Stack.Screen name="Games" component={Games} />
      <Stack.Screen name="BackToGames" component={BackToGames} />
      <Stack.Screen name="Play" component={Play} />
      <Stack.Screen name="Sportsbook" component={Sportsbook} />
      <Stack.Screen name="Cashier" component={Cashier} />
      <Stack.Screen name="Profile" component={Profile} />
      <Stack.Screen name="Transaction History" component={TransactionHistory} />
      <Stack.Screen name="Gaming History" component={GamingHistory} />
      <Stack.Screen
        name="Balance Transfer History"
        component={BalanceTransferHistory}
      />
      <Stack.Screen name="Bonus History" component={BonusHistory} />
      <Stack.Screen name="Free Spins" component={FreeSpins} />
      <Stack.Screen name="Access Control" component={AccessControl} />
      <Stack.Screen name="Promotions" component={Promotions} />
      <Stack.Screen name="Loyalty And VIP" component={LoyaltyAndVIP} />
      <Stack.Screen name="AML Policy" component={AmlPolicy} />
      <Stack.Screen
        name="Terms And Conditions"
        component={TermsAndConditions}
      />
      <Stack.Screen name="Bonus Terms" component={BonusTerms} />
      <Stack.Screen name="Privacy Policy" component={PrivacyPolicy} />
      <Stack.Screen name="Responsible Gaming" component={ResponsibleGaming} />
      <Stack.Screen name="Support" component={Support} />
    </Stack.Navigator>
  );
}

export default function UserMenu(props) {
  return (
    <Drawer.Navigator
      initialRouteName="LeftDrawer"
      screenOptions={{
        headerShown: false,
        drawerPosition: "right",
        drawerType: "front",
        overlayColor: "transparent",
        drawerStyle: { width: "80%", backgroundColor: "#000000" },
      }}
      drawerContent={(props) => <CustomDrawerContentRight {...props} />}
    >
      <Drawer.Screen
        name="LeftDrawer"
        component={LeftDrawer}
        style={{ backgroundColor: "#000000", height: 0 }}
        options={{
          drawerLabel: () => null,
          title: null,
          drawerIcon: () => null,
        }}
      />
    </Drawer.Navigator>
  );
}

function LeftDrawer(props) {
  return (
    <Drawer.Navigator
      initialRouteName="CasinoXbit"
      screenOptions={{
        headerShown: false,
        drawerPosition: "left",
        drawerType: "front",
        overlayColor: "transparent",
        drawerStyle: { width: "80%", backgroundColor: "#000000" },
      }}
      drawerContent={(props) => <CustomDrawerContentLeft {...props} />}
    >
      <Drawer.Screen
        name="CasinoXbit"
        component={CasinoXbit}
        style={{ backgroundColor: "#000000", height: 0 }}
        options={{
          drawerLabel: () => null,
          title: null,
          drawerIcon: () => null,
        }}
      />
    </Drawer.Navigator>
  );
}

function CustomDrawerContentLeft(props) {
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const navigation = props.navigation;
  const route = props.route;

  const [currentScreen, setCurrentScreen] = useState("Landing");

  const hideMenu = () => {
    console.log(route);
    navigation.closeDrawer();
  };
  const navigateTo = (screen) => {
    navigation.navigate(screen);
    setCurrentScreen(screen);
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);
  return (
    <DrawerContentScrollView
      style={styles.drawerLeft}
      {...props}
      onLayout={onLayoutChange}
    >
      <Box>
        <View style={styles.flexBox}>
          <Button style={styles.closeBtn} onPress={hideMenu}>
            <FontAwesome5 name="times" style={styles.closeBtnIcon} />
          </Button>
        </View>

        <View style={styles.menu}>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Games")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Play Now")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Promotions")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Promotions")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Loyalty And VIP")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Loyalty And VIP")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("AML Policy")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("AML Policy")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Terms And Conditions")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Terms And Conditions")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Bonus Terms")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Bonus Terms")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Privacy Policy")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Privacy Policy")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Responsible Gaming")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Responsible Gaming")}</Text>
          </TouchableHighlight>

          <LanguageSwitcher
            onChangeLanguage={hideMenu}
            style={[styles.menuItem, styles.menuText]}
          />
        </View>
      </Box>
    </DrawerContentScrollView>
  );
}

function CustomDrawerContentRight(props) {
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const navigation = props.navigation;
  const route = props.route;

  const [currentScreen, setCurrentScreen] = useState("Landing");

  const [balanceObj, setBalanceObj] = useState({});
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState("");

  const getUser = async () => {
    var user = await auth.getUser();
    setUser(user);
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);
  useEffect(() => {
    getUser();
    ls.on("balanceObj", (newVal) => {
      setBalanceObj(newVal);
    });
    ls.on("userObj", (newVal) => {
      //On logout, it creates error to produce first name just before redirected
      //So, we won't set null as user, but wait for the redirect to take effect
      if (newVal) {
        setUser(newVal);
      }
    });
  }, []);

  useEffect(() => {
    if (user) {
      setUserName(user.first_name + " " + user.last_name);
    }
  }, [user]);

  const logout = async () => {
    await auth.logout();
  };

  const hideMenu = () => {
    navigation.closeDrawer();
  };

  const navigateTo = (screen) => {
    navigation.navigate(screen);
    setCurrentScreen(screen);
  };

  return (
    <DrawerContentScrollView
      style={styles.drawerRight}
      {...props}
      onLayout={onLayoutChange}
    >
      <Box>
        <View style={styles.flexBox}>
          <Text style={styles.userName}>{userName}</Text>
          <Button style={styles.closeBtn} onPress={hideMenu}>
            <FontAwesome5 name="times" style={styles.closeBtnIcon} />
          </Button>
        </View>
        <Hr />

        <View style={styles.infoItem}>
          <View style={styles.flexBox}>
            <Ionicons name="star-sharp" style={styles.infoItemIcon} />
            <Text style={styles.infoItemBoldText}>
              {__("VIP Level")}{" "}
              <Text style={styles.infoItemBoldText}>
                {balanceObj.vip_level}
              </Text>
            </Text>
          </View>
          {balanceObj.next_vip_level && (
            <Text style={styles.infoItemText}>
              {__("Next VIP Level")}{" "}
              <Text style={styles.infoItemText}>
                {balanceObj.next_vip_level} ({balanceObj.account_progress}%)
              </Text>
            </Text>
          )}
        </View>

        <View style={styles.infoItem}>
          <View style={styles.flexBox}>
            <Fontisto name="wallet" style={styles.infoItemIcon} />
            <Text style={styles.infoItemBoldText}>
              {__("Total Balance")}{" "}
              <Text style={styles.infoItemBoldText}>
                XBU {balanceObj.total_balance}
              </Text>
            </Text>
          </View>
          <Text style={styles.infoItemText}>
            {__("Cash Balance")}{" "}
            <Text style={styles.infoItemText}>
              XBU {balanceObj.cash_balance}
            </Text>
          </Text>
          <Text style={styles.infoItemText}>
            {__("Bonus Balance")}{" "}
            <Text style={styles.infoItemText}>
              XBB {balanceObj.bonus_balance}
            </Text>
          </Text>
        </View>

        <Center width="100%">
          <Button
            colorScheme="clear"
            style={styles.cashierButton}
            onPress={() => navigateTo("Cashier")}
          >
            <Text style={styles.cashierButtonText}>{__("CASHIER")}</Text>
          </Button>
        </Center>

        <View style={styles.menu}>
          <TouchableHighlight
            underlayColor={theme.darkerColor}
            activeOpacity={0.8}
            onPress={() => navigateTo("Profile")}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("My Account")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            activeOpacity={0.8}
            underlayColor={theme.darkerColor}
            onPress={() => {
              navigateTo("Transaction History");
            }}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Transaction History")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            activeOpacity={0.8}
            underlayColor={theme.darkerColor}
            onPress={() => {
              navigateTo("Gaming History");
            }}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Gaming History")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            activeOpacity={0.8}
            underlayColor={theme.darkerColor}
            onPress={() => {
              navigateTo("Bonus History");
            }}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Bonus History")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            activeOpacity={0.8}
            underlayColor={theme.darkerColor}
            onPress={() => {
              navigateTo("Free Spins");
            }}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Free Spins")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            activeOpacity={0.8}
            underlayColor={theme.darkerColor}
            onPress={() => {
              navigateTo("Balance Transfer History");
            }}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>
              {__("Balance Transfer History")}
            </Text>
          </TouchableHighlight>
          <TouchableHighlight
            activeOpacity={0.8}
            underlayColor={theme.darkerColor}
            onPress={() => {
              navigateTo("Access Control");
            }}
            style={styles.menuItem}
          >
            <Text style={styles.menuText}>{__("Access Control")}</Text>
          </TouchableHighlight>
          <TouchableHighlight
            activeOpacity={0.8}
            underlayColor={theme.darkerColor}
            onPress={logout}
            style={styles.menuItem}
          >
            <View style={styles.flexBox}>
              <Text style={styles.menuText}>{__("Logout")}</Text>
              <MaterialCommunityIcons
                name="logout"
                style={[styles.infoItemIcon, styles.logoutIcon]}
              />
            </View>
          </TouchableHighlight>

          <View style={{ height: 80 }}></View>
        </View>
      </Box>
    </DrawerContentScrollView>
  );
}
