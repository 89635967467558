import React from "react";
import { View } from "native-base";
import { StyleSheet } from "react-native";
import theme from "services/theme";

export default function Hr(props) {
  return (
    <View
      style={[
        {
          borderBottomWidth: 1,
          borderBottomColor: theme.shadowColor,
          width: '100%'
        },
        props.style ? props.style : {},
      ]}
    />
  );
}
