import React, { useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import * as DocumentPicker from "expo-document-picker";
import * as ImagePicker from "expo-image-picker";
import moment from "moment";
import {
  Platform,
  TouchableHighlight,
  SafeAreaView,
  ScrollView,
} from "react-native";
import RefreshControl from "services/RefreshControl";
import { FontAwesome5 } from "react-native-vector-icons";
import { StdAlert, BinaryAlert } from "elements/Alert";
import mime from "mime";
import * as Linking from "expo-linking";
import getStyles from "styles/AccessControl";
import theme from "services/theme";
import ls from "services/ls";
import api from "services/api";
import auth from "services/auth";
import axios from "axios";
import { decode, encode } from "base-64";
import {
  View,
  Text,
  Icon,
  Input,
  Box,
  Accordion,
  KeyboardAvoidingView,
  Button,
} from "native-base";
import InputBox from "elements/InputBox";
import DatePicker from "elements/DatePicker";
import CheckBox from "elements/CheckBox";
import SelectBox from "elements/SelectBox";
import Image from "elements/Image";
import Hr from "elements/Hr";
import Br from "elements/Br";
import SubPageHeader from "elements/SubPageHeader";
import { AntDesign, FontAwesome } from "@expo/vector-icons";

///////////////////////
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";
import Spinner from "elements/Spinner";

export default function AccessControl(props) {
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  const { __ } = React.useContext(require("contexts/LangContext").default);
  const [spinner, setSpinner] = useState(false);

  const autoOrManual = [
    { label: "Automatic", value: "automatic" },
    { label: "Manual", value: "manual" },
  ];

  const dayWeekMonth = [
    { label: "Per Day", value: "day" },
    { label: "Per Week", value: "week" },
    { label: "Per Month", value: "month" },
  ];

  const getPermissionAsync = async () => {
    if (Constants.platform.android) {
      const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
      if (status !== "granted") {
        alert(__("Sorry, we need camera roll permissions to make this work!"));
      }
    }
  };

  const [idUploads, setIdUploads] = useState({
    id: null,
    poa: null,
    other: [],
  });
  const [idUploadsFetched, setIdUploadsFetched] = useState(false);
  const getIdUploads = async (force) => {
    if (!idUploadsFetched || force) {
      setSpinner(true);
      var response = await api.get("/id-uploads");
      setSpinner(false);
      if (response.status === 200) {
        setIdUploads(response.data);
        setIdUploadsFetched(true);
      }
    }
  };

  const [depositLimitFetched, setDepositLimitFetched] = useState(false);
  const [depositLimit, setDepositLimit] = useState({});
  const getDepositLimit = async (force) => {
    if (!depositLimitFetched || force) {
      setSpinner(true);
      var response = await api.get("/deposit-limit");
      setSpinner(false);
      if (response.status === 200) {
        setDepositLimit(response.data);
        setDepositLimitFetched(true);
      }
    }
  };

  const [wagerLimitFetched, setWagerLimitFetched] = useState(false);
  const [wagerLimit, setWagerLimit] = useState({});
  const getWagerLimit = async (force) => {
    if (!wagerLimitFetched || force) {
      setSpinner(true);
      var response = await api.get("/wager-limit");
      setSpinner(false);
      if (response.status === 200) {
        setWagerLimit(response.data);
        setWagerLimitFetched(true);
      }
    }
  };

  const [lossLimitFetched, setLossLimitFetched] = useState(false);
  const [lossLimit, setLossLimit] = useState({});
  const getLossLimit = async (force) => {
    if (!lossLimitFetched || force) {
      setSpinner(true);
      var response = await api.get("/loss-limit");
      setSpinner(false);
      if (response.status === 200) {
        setLossLimit(response.data);
        setLossLimitFetched(true);
      }
    }
  };

  const [playTimeLimitFetched, setPlayTimeLimitFetched] = useState(false);
  const [playTimeLimit, setPlayTimeLimit] = useState({});
  const getPlayTimeLimit = async (force) => {
    if (!playTimeLimitFetched || force) {
      setSpinner(true);
      var response = await api.get("/play-time-limit");
      setSpinner(false);
      if (response.status === 200) {
        setPlayTimeLimit(response.data);
        setPlayTimeLimitFetched(true);
      }
    }
  };

  const [accessLimitFetched, setAccessLimitFetched] = useState(false);
  const [accessLimit, setAccessLimit] = useState({});
  const getAccessLimit = async () => {
    if (!accessLimitFetched) {
      setSpinner(true);
      var response = await api.get("/access-limit");
      setSpinner(false);
      if (response.status === 200) {
        setAccessLimit(response.data);
        setAccessLimitConfirm(false);
        setAccessLimitFetched(true);
      }
    }
  };

  const [accountConrolsFetched, setAccountControlsFetched] = useState(false);
  const [accountConrols, setAccountControls] = useState({});
  const getAccountControls = async () => {
    if (!accountConrolsFetched) {
      setSpinner(true);
      var response = await api.get("/account-controls");
      setSpinner(false);
      if (response.status === 200) {
        setAccountControls(response.data);
        setAccountControlsFetched(true);
      }
    }
  };

  const dataURItoBlob = (dataURI) => {
    var byteString = decode(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  };

  const [idUploadSelected, setIdUploadSelected] = useState(null);

  const uploadIdDocumentModal = async (type) => {
    setIdUploadSelected(type);
  };

  const cancelIdUpload = () => {
    setIdUploadSelected(null);
  };

  const selectIdImage = async () => {
    try {
      var options = {
        type: "image/*",
      };
      const file = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
      });
      if (!file.cancelled) {
        const data = new FormData();

        if (Platform.OS === "web") {
          data.append("file", dataURItoBlob(file.uri), "image.jpg");
        } else if (Platform.OS === "android") {
          data.append("file", {
            name: file.uri.split("/").pop(),
            uri: file.uri,
            type: "image/jpeg",
          });
        } else {
          data.append("file", {
            name: file.uri.split("/").pop(),
            uri: file.uri.replace("file://", ""),
            type: "image/jpeg",
          });
        }
        data.append("type", idUploadSelected);
        var headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        var response = await api.post("/id-uploads", data, headers);
        if (response.status === 200) {
          StdAlert("Success!!", response.data.message);
        } else {
          StdAlert("Warning!!", response.data.message);
        }
        setIdUploadSelected(null);
        getIdUploads(true);
      }
    } catch (err) {}
  };

  const selectIdDocument = async (type) => {
    try {
      var options = {
        type: "application/pdf",
      };
      const file = await DocumentPicker.getDocumentAsync(options);
      if (file.type === "cancel") {
      } else if (file.type === "success") {
        const data = new FormData();

        if (Platform.OS === "web") {
          data.append("file", dataURItoBlob(file.uri), "document.pdf");
        } else if (Platform.OS === "android") {
          data.append("file", {
            name: file.uri.split("/").pop(),
            uri: "file://" + file.uri,
            type: "image/jpeg",
          });
        } else {
          data.append("file", {
            name: file.uri.split("/").pop(),
            uri: file.uri,
            type: "image/jpeg",
          });
        }
        data.append("type", idUploadSelected);
        var headers = {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        };
        var response = await api.post("/id-uploads", data, headers);
        if (response.status === 200) {
          StdAlert("Success!!", response.data.message);
        } else {
          StdAlert("Warning!!", response.data.message);
        }
        setIdUploadSelected(null);
        getIdUploads(true);
      }
    } catch (err) {}
  };

  const updateDepositLimit = (value, field) => {
    if (!depositLimit || !depositLimit.id) {
      setDepositLimit({
        ...depositLimit,
        [field]: value,
      });
    }
  };
  const submitDepositLimit = async () => {
    setSpinner(true);
    var response = await api.post("/deposit-limit", depositLimit);
    setSpinner(false);

    if (response.status === 200) {
      StdAlert("Success!!", response.data.message);
      getDepositLimit(true);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const updateWagerLimit = (value, field) => {
    if (!wagerLimit || !wagerLimit.id) {
      setWagerLimit({
        ...wagerLimit,
        [field]: value,
      });
    }
  };
  const submitWagerLimit = async () => {
    setSpinner(true);
    var response = await api.post("/wager-limit", wagerLimit);
    setSpinner(false);
    if (response.status === 200) {
      StdAlert("Success!!", response.data.message);
      getWagerLimit(true);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const updateLossLimit = (value, field) => {
    if (!lossLimit || !lossLimit.id) {
      setLossLimit({
        ...lossLimit,
        [field]: value,
      });
    }
  };
  const submitLossLimit = async () => {
    setSpinner(true);
    var response = await api.post("/loss-limit", lossLimit);
    setSpinner(false);
    if (response.status === 200) {
      StdAlert("Success!!", response.data.message);
      getLossLimit(true);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const updatePlayTimeLimit = (value, field) => {
    if (!playTimeLimit || !playTimeLimit.id) {
      setPlayTimeLimit({
        ...playTimeLimit,
        [field]: value,
      });
    }
  };
  const submitPlayTimeLimit = async () => {
    setSpinner(true);
    var response = await api.post("/play-time-limit", playTimeLimit);
    setSpinner(false);
    if (response.status === 200) {
      StdAlert("Success!!", response.data.message);
      getPlayTimeLimit(true);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const updateAccessLimit = (value, field) => {
    if (!accessLimit || !accessLimit.id) {
      setAccessLimit({
        ...accessLimit,
        [field]: value,
      });
    }
  };
  const [accessLimitConfirm, setAccessLimitConfirm] = useState(false);
  const [accessLimitConfirmText, setAccessLimitConfirmText] = useState({});
  const submitAccessLimit = async () => {
    setSpinner(true);
    var response = await api.post("/access-limit", {
      ...accessLimit,
      confirm: accessLimitConfirm,
    });
    setSpinner(false);
    if (response.status === 200) {
      StdAlert("Success!!", response.data.message);
      auth.logout();
    } else if (response.status === 202) {
      setAccessLimitConfirm(true); //Wait for confirmation
      setAccessLimitConfirmText(response.data);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const toggleAccountControl = async (field) => {
    setSpinner(true);
    var response = await api.post("/account-controls", {
      field: field,
    });
    setSpinner(false);
    if (response.status === 200) {
      setAccountControls({ ...accountConrols, [field]: response.data });
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const getAllData = async () => {
    getIdUploads(false);
  };

  const accordionToggled = (openItems) => {
    var lastOpenItem = openItems.length
      ? openItems[openItems.length - 1]
      : null;
    if (lastOpenItem === 0) {
      getIdUploads(false);
    } else if (lastOpenItem === 1) {
      getDepositLimit(false);
    } else if (lastOpenItem === 2) {
      getWagerLimit(false);
    } else if (lastOpenItem === 3) {
      getLossLimit(false);
    } else if (lastOpenItem === 4) {
      getPlayTimeLimit(false);
    } else if (lastOpenItem === 5) {
      getAccessLimit();
    } else if (lastOpenItem === 6) {
      getAccountControls();
    }
  };

  useFocusEffect(
    useCallback(() => {
      console.log("idUploadsFetched", idUploadsFetched);
      getAllData();
    }, [idUploadsFetched])
  );

  const onRefresh = () => {
    getIdUploads(true);
    setDepositLimitFetched(false);
    setWagerLimitFetched(false);
    setLossLimitFetched(false);
    setPlayTimeLimitFetched(false);
    setAccessLimitFetched(false);
    setAccountControlsFetched(false);
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={spinner} />
        <Box style={styles.fullHeight}>
          <AppHeader />
          <ScrollView
            refreshControl={
              <RefreshControl refreshing={spinner} onRefresh={onRefresh} />
            }
          >
            <SubPageHeader
              pageName={__("Responsible Gaming")}
              style={styles.subpageHeader}
            />
            <Box style={{ marginTop: 10 }}>
              <Accordion index={[0]} onChange={accordionToggled}>
                <Accordion.Item>
                  <Accordion.Summary
                    bg={theme.primaryColor}
                    _expanded={{ backgroundColor: theme.darkerColor }}
                  >
                    {__("Upload Your ID")}
                    <Accordion.Icon />
                  </Accordion.Summary>
                  <Accordion.Details>
                    <Text style={styles.accordionText}>
                      {__(
                        "Please upload your identification documents. Maximum size of 2MB per file."
                      )}
                      "
                    </Text>
                    <Text style={styles.accordionText}>
                      {__("Accepted file formats: JPG, PNG and PDF.")}
                    </Text>
                    <View style={styles.idUploadSection}>
                      <Text style={styles.accordionTextBold}>
                        {__("Upload Your ID")}
                      </Text>
                      {!!idUploads.id && (
                        <View style={styles.idUploadBox}>
                          <Image
                            style={{ width: "100%", height: 200 }}
                            source={{ uri: idUploads.id.api_photo_url }}
                          />
                          <View
                            style={[
                              styles.bulb,
                              idUploads.id.approved === "submitted"
                                ? styles.yellowBulb
                                : idUploads.id.approved === "approved"
                                ? styles.greenBulb
                                : styles.redBulb,
                            ]}
                          ></View>
                        </View>
                      )}
                      {(!idUploads.id ||
                        idUploads.id.approved !== "approved") && (
                        <Button
                          onPress={() => {
                            uploadIdDocumentModal("id");
                          }}
                          style={styles.button}
                        >
                          <Text style={styles.buttonText}>
                            {idUploads.poa
                              ? __("Update ID Document")
                              : __("Upload ID Document")}
                          </Text>
                        </Button>
                      )}
                    </View>

                    <View style={styles.idUploadSection}>
                      <Text style={styles.accordionTextBold}>
                        {__("Proof of Address")}
                      </Text>
                      {!!idUploads.poa && (
                        <View style={styles.idUploadBox}>
                          <Image
                            style={{ width: "100%", height: 200 }}
                            source={{ uri: idUploads.poa.api_photo_url }}
                          />
                          <View
                            style={[
                              styles.bulb,
                              idUploads.poa.approved === "submitted"
                                ? styles.yellowBulb
                                : idUploads.poa.approved === "approved"
                                ? styles.greenBulb
                                : styles.redBulb,
                            ]}
                          ></View>
                        </View>
                      )}
                      {(!idUploads.poa ||
                        idUploads.poa.approved !== "approved") && (
                        <Button
                          onPress={() => {
                            uploadIdDocumentModal("poa");
                          }}
                          style={styles.button}
                        >
                          <Text style={styles.buttonText}>
                            {idUploads.poa
                              ? "Update Proof of Address"
                              : "Upload Proof of Address"}
                          </Text>
                        </Button>
                      )}
                    </View>

                    <View style={styles.idUploadSection}>
                      <Text style={styles.accordionTextBold}>
                        {__("Other Documents")}
                      </Text>
                      {idUploads.other.map((doc, index) => (
                        <View key={index} style={styles.idUploadBox}>
                          <Image
                            style={{ width: "100%", height: 200 }}
                            source={{ uri: doc.api_photo_url }}
                          />
                          <View
                            style={[
                              styles.bulb,
                              doc.approved === "submitted"
                                ? styles.yellowBulb
                                : doc.approved === "approved"
                                ? styles.greenBulb
                                : styles.redBulb,
                            ]}
                          ></View>
                        </View>
                      ))}
                      <Button
                        onPress={() => {
                          uploadIdDocumentModal("other");
                        }}
                        style={styles.button}
                      >
                        <Text style={styles.buttonText}>
                          {idUploads.other.length
                            ? __("Upload More Documents")
                            : __("Upload Other Documents")}
                        </Text>
                      </Button>
                    </View>
                  </Accordion.Details>
                </Accordion.Item>

                <Accordion.Item>
                  <Accordion.Summary
                    bg={theme.primaryColor}
                    _expanded={{ backgroundColor: theme.darkerColor }}
                  >
                    {__("Deposit Control")}
                    <Accordion.Icon />
                  </Accordion.Summary>
                  <Accordion.Details>
                    {!depositLimit || !depositLimit.id ? (
                      <Text style={styles.accordionText}>
                        {__("Please specify a deposit limit for your account")}
                      </Text>
                    ) : (
                      <Text style={styles.accordionText}>
                        {__("Deposit limit is set to the following parameters")}
                      </Text>
                    )}
                    <View styles={[styles.fullWidth, styles.form]}>
                      <DatePicker
                        label={__("Starts At")}
                        placeholder={__("Starts At")}
                        value={depositLimit.start_date}
                        minValue={moment().format("YYYY-MM-DD")}
                        onValueChange={(itemValue) =>
                          updateDepositLimit(itemValue, "start_date")
                        }
                      />
                      <DatePicker
                        label={__("Ends At")}
                        placeholder={__("Ends At")}
                        value={depositLimit.end_date}
                        minValue={
                          depositLimit.start_date
                            ? depositLimit.start_date
                            : moment().format("YYYY-MM-DD")
                        }
                        onValueChange={(itemValue) =>
                          updateDepositLimit(itemValue, "end_date")
                        }
                      />
                      <InputBox
                        color={theme.color}
                        label={__("Amount")}
                        value={
                          depositLimit.limit_amount
                            ? depositLimit.limit_amount.toString()
                            : "0"
                        }
                        onChangeText={(value) =>
                          updateDepositLimit(value, "limit_amount")
                        }
                        placeholder={__("Amount")}
                      />
                      {(!depositLimit || !depositLimit.id) && (
                        <Button
                          onPress={submitDepositLimit}
                          style={styles.button}
                        >
                          <Text style={styles.buttonText}>Apply</Text>
                        </Button>
                      )}
                    </View>
                  </Accordion.Details>
                </Accordion.Item>

                <Accordion.Item>
                  <Accordion.Summary
                    bg={theme.primaryColor}
                    _expanded={{ backgroundColor: theme.darkerColor }}
                  >
                    {__("Wager Control")}
                    <Accordion.Icon />
                  </Accordion.Summary>
                  <Accordion.Details>
                    {!wagerLimit || !wagerLimit.id ? (
                      <Text style={styles.accordionText}>
                        {__("Please specify a wager limit for your account")}
                      </Text>
                    ) : wagerLimit.control_type == "automatic" ? (
                      <Text style={styles.accordionText}>
                        {__("Wager limit set as") +
                          " XBU " +
                          wagerLimit.limit_amount.toString() +
                          " " +
                          __("per") +
                          " "}
                        {wagerLimit.time_period}
                      </Text>
                    ) : (
                      <Text style={styles.accordionText}>
                        {__("Wager limit set as") +
                          " XBU " +
                          wagerLimit.limit_amount.toString() +
                          " " +
                          __("from") +
                          " " +
                          wagerLimit.show_start_date +
                          " " +
                          __("to") +
                          " " +
                          wagerLimit.show_end_date.toString()}
                      </Text>
                    )}
                    <View styles={[styles.fullWidth, styles.form]}>
                      <SelectBox
                        label={__("Control Type")}
                        placeholder={__("Control Type")}
                        minWidth={100}
                        selectedValue={wagerLimit.control_type}
                        onValueChange={(itemValue) =>
                          updateWagerLimit(itemValue, "control_type")
                        }
                        items={autoOrManual}
                      />
                      {wagerLimit.control_type === "automatic" && (
                        <SelectBox
                          label={__("Restrict For")}
                          placeholder={__("Restrict For")}
                          minWidth={100}
                          selectedValue={wagerLimit.time_period}
                          onValueChange={(itemValue) =>
                            updateWagerLimit(itemValue, "time_period")
                          }
                          items={dayWeekMonth}
                        />
                      )}
                      {wagerLimit.control_type === "manual" && (
                        <React.Fragment>
                          <DatePicker
                            label={__("Starts At")}
                            placeholder={__("Starts At")}
                            value={wagerLimit.start_date}
                            minValue={moment().format("YYYY-MM-DD")}
                            onValueChange={(itemValue) =>
                              updateWagerLimit(itemValue, "start_date")
                            }
                          />
                          <DatePicker
                            label={__("Ends At")}
                            placeholder={__("Ends At")}
                            value={wagerLimit.end_date}
                            minValue={
                              wagerLimit.start_date
                                ? wagerLimit.start_date
                                : moment().format("YYYY-MM-DD")
                            }
                            onValueChange={(itemValue) =>
                              updateWagerLimit(itemValue, "end_date")
                            }
                          />
                        </React.Fragment>
                      )}
                      <InputBox
                        color={theme.color}
                        label={__("Amount")}
                        value={
                          wagerLimit.limit_amount
                            ? wagerLimit.limit_amount.toString()
                            : "0"
                        }
                        onChangeText={(value) =>
                          updateWagerLimit(value, "limit_amount")
                        }
                        placeholder={__("Amount")}
                      />
                      {(!wagerLimit || !wagerLimit.id) && (
                        <Button
                          onPress={submitWagerLimit}
                          style={styles.button}
                        >
                          <Text style={styles.buttonText}>{__("Apply")}</Text>
                        </Button>
                      )}
                    </View>
                  </Accordion.Details>
                </Accordion.Item>

                <Accordion.Item>
                  <Accordion.Summary
                    bg={theme.primaryColor}
                    _expanded={{ backgroundColor: theme.darkerColor }}
                  >
                    {__("Loss Control")}
                    <Accordion.Icon />
                  </Accordion.Summary>
                  <Accordion.Details>
                    {!lossLimit || !lossLimit.id ? (
                      <Text style={styles.accordionText}>
                        {__("Please specify a loss limit for your account")}
                      </Text>
                    ) : lossLimit.control_type == "automatic" ? (
                      <Text style={styles.accordionText}>
                        {__("Loss limit set as") +
                          " XBU " +
                          lossLimit.limit_amount.toString() +
                          " " +
                          __("per") +
                          " " +
                          lossLimit.time_period}
                      </Text>
                    ) : (
                      <Text style={styles.accordionText}>
                        {__("Loss limit set as") +
                          " XBU " +
                          lossLimit.limit_amount.toString() +
                          " " +
                          __("from") +
                          " " +
                          lossLimit.show_start_date.toString() +
                          " " +
                          __("to") +
                          " " +
                          lossLimit.show_end_date}
                      </Text>
                    )}
                    <View styles={[styles.fullWidth, styles.form]}>
                      <SelectBox
                        label={__("Control Type")}
                        placeholder={__("Control Type")}
                        minWidth={100}
                        selectedValue={lossLimit.control_type}
                        onValueChange={(itemValue) =>
                          updateLossLimit(itemValue, "control_type")
                        }
                        items={autoOrManual}
                      />
                      {lossLimit.control_type === "automatic" && (
                        <SelectBox
                          label={__("Restrict For")}
                          placeholder={__("Restrict For")}
                          minWidth={100}
                          selectedValue={lossLimit.time_period}
                          onValueChange={(itemValue) =>
                            updateLossLimit(itemValue, "time_period")
                          }
                          items={dayWeekMonth}
                        />
                      )}
                      {lossLimit.control_type === "manual" && (
                        <React.Fragment>
                          <DatePicker
                            label={__("Starts At")}
                            placeholder={__("Starts At")}
                            value={lossLimit.start_date}
                            minValue={moment().format("YYYY-MM-DD")}
                            onValueChange={(itemValue) =>
                              updateLossLimit(itemValue, "start_date")
                            }
                          />
                          <DatePicker
                            label={__("Ends At")}
                            placeholder={__("Ends At")}
                            value={lossLimit.end_date}
                            minValue={
                              lossLimit.start_date
                                ? lossLimit.start_date
                                : moment().format("YYYY-MM-DD")
                            }
                            onValueChange={(itemValue) =>
                              updateLossLimit(itemValue, "end_date")
                            }
                          />
                        </React.Fragment>
                      )}
                      <InputBox
                        color={theme.color}
                        label={__("Amount")}
                        value={
                          lossLimit.limit_amount
                            ? lossLimit.limit_amount.toString()
                            : "0"
                        }
                        onChangeText={(value) =>
                          updateLossLimit(value, "limit_amount")
                        }
                        placeholder={__("Amount")}
                      />
                      {(!lossLimit || !lossLimit.id) && (
                        <Button onPress={submitLossLimit} style={styles.button}>
                          <Text style={styles.buttonText}>{__("Apply")}</Text>
                        </Button>
                      )}
                    </View>
                  </Accordion.Details>
                </Accordion.Item>

                <Accordion.Item>
                  <Accordion.Summary
                    bg={theme.primaryColor}
                    _expanded={{ backgroundColor: theme.darkerColor }}
                  >
                    {__("Play Time Control")}
                    <Accordion.Icon />
                  </Accordion.Summary>
                  <Accordion.Details>
                    {!playTimeLimit || !playTimeLimit.id ? (
                      <Text style={styles.accordionText}>
                        {__(
                          "Enforce a time limit (in minutes) you will be able to play for a selected period of time."
                        )}
                        <Br />
                        <Br />
                        {playTimeLimit.control_type == "automatic"
                          ? __(
                              "You can select per day, per week or per month. The time you select will be the amount of time you will be able to play within each unit of the selected time in total. The limit will be enforced recurringly"
                            )
                          : playTimeLimit.control_type == "manual"
                          ? __(
                              "Set a date range between which the time limit will be enforced. The time you select will be the amount of time you will be able to play within this range in total."
                            )
                          : __(
                              "Please selecte a control type for more options."
                            )}
                      </Text>
                    ) : playTimeLimit.control_type == "automatic" ? (
                      <Text style={styles.accordionText}>
                        {__("Play time limit set as ") +
                          " " +
                          playTimeLimit.time_limit +
                          " " +
                          __("minutes per") +
                          " " +
                          playTimeLimit.time_period}
                      </Text>
                    ) : (
                      <Text style={styles.accordionText}>
                        {__("Play time limit set as") +
                          " " +
                          playTimeLimit.time_limit +
                          " " +
                          __("minutes from") +
                          " " +
                          playTimeLimit.show_start_date +
                          " " +
                          __("to") +
                          " " +
                          playTimeLimit.show_end_date}
                      </Text>
                    )}
                    <View styles={[styles.fullWidth, styles.form]}>
                      <SelectBox
                        label={__("Control Type")}
                        placeholder={__("Control Type")}
                        minWidth={100}
                        selectedValue={playTimeLimit.control_type}
                        onValueChange={(itemValue) =>
                          updatePlayTimeLimit(itemValue, "control_type")
                        }
                        items={autoOrManual}
                      />
                      {playTimeLimit.control_type === "automatic" && (
                        <SelectBox
                          label={__("Restrict For")}
                          placeholder={__("Restrict For")}
                          minWidth={100}
                          selectedValue={playTimeLimit.time_period}
                          onValueChange={(itemValue) =>
                            updatePlayTimeLimit(itemValue, "time_period")
                          }
                          items={dayWeekMonth}
                        />
                      )}
                      {playTimeLimit.control_type === "manual" && (
                        <React.Fragment>
                          <DatePicker
                            label={__("Starts At")}
                            placeholder={__("Starts At")}
                            value={playTimeLimit.start_date}
                            minValue={moment().format("YYYY-MM-DD")}
                            onValueChange={(itemValue) =>
                              updatePlayTimeLimit(itemValue, "start_date")
                            }
                          />
                          <DatePicker
                            label={__("Ends At")}
                            placeholder={__("Ends At")}
                            value={playTimeLimit.end_date}
                            minValue={
                              playTimeLimit.start_date
                                ? playTimeLimit.start_date
                                : moment().format("YYYY-MM-DD")
                            }
                            onValueChange={(itemValue) =>
                              updatePlayTimeLimit(itemValue, "end_date")
                            }
                          />
                        </React.Fragment>
                      )}
                      <InputBox
                        color={theme.color}
                        label={__("In minutes")}
                        value={
                          playTimeLimit.time_limit
                            ? playTimeLimit.time_limit.toString()
                            : "0"
                        }
                        onChangeText={(value) =>
                          updatePlayTimeLimit(value, "time_limit")
                        }
                        placeholder={__("Amount")}
                      />
                      {(!playTimeLimit || !playTimeLimit.id) && (
                        <Button
                          onPress={submitPlayTimeLimit}
                          style={styles.button}
                        >
                          <Text style={styles.buttonText}>{__("Apply")}</Text>
                        </Button>
                      )}
                    </View>
                  </Accordion.Details>
                </Accordion.Item>

                <Accordion.Item>
                  <Accordion.Summary
                    bg={theme.primaryColor}
                    _expanded={{ backgroundColor: theme.darkerColor }}
                  >
                    {__("Access Control")}
                    <Accordion.Icon />
                  </Accordion.Summary>
                  <Accordion.Details>
                    {!accessLimitConfirm ? (
                      <React.Fragment>
                        <Text style={styles.accordionText}>
                          {__(
                            "Please specify the time period that you will not be able to access your account."
                          )}
                        </Text>

                        <View styles={[styles.fullWidth, styles.form]}>
                          <DatePicker
                            label={__("Starts At")}
                            placeholder={__("Starts At")}
                            startYear={2021}
                            value={accessLimit.start_date}
                            minValue={moment().format("YYYY-MM-DD")}
                            onValueChange={(itemValue) =>
                              updateAccessLimit(itemValue, "start_date")
                            }
                          />
                          <DatePicker
                            label={__("Ends At")}
                            placeholder={__("Ends At")}
                            startYear={2021}
                            value={accessLimit.end_date}
                            minValue={
                              accessLimit.start_date
                                ? accessLimit.start_date
                                : moment().format("YYYY-MM-DD")
                            }
                            onValueChange={(itemValue) =>
                              updateAccessLimit(itemValue, "end_date")
                            }
                          />
                          {(!accessLimit || !accessLimit.id) && (
                            <Button
                              onPress={submitAccessLimit}
                              style={styles.button}
                            >
                              <Text style={styles.buttonText}>
                                {__("Apply")}
                              </Text>
                            </Button>
                          )}
                        </View>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Text
                          style={[
                            styles.accordionText,
                            { fontFamily: "GilroySemiBold" },
                          ]}
                        >
                          {accessLimitConfirmText.title}
                        </Text>
                        <Text style={styles.accordionText}>
                          {accessLimitConfirmText.message}
                        </Text>

                        <View styles={[styles.fullWidth, styles.form]}>
                          {(!accessLimit || !accessLimit.id) && (
                            <View style={styles.splitButtons}>
                              <View style={styles.splitButtonContainer}>
                                <Button
                                  onPress={() => setAccessLimitConfirm(false)}
                                  style={[styles.button, styles.splitButton]}
                                >
                                  <Text style={styles.buttonText}>
                                    {__("Cancel")}
                                  </Text>
                                </Button>
                              </View>
                              <View style={styles.splitButtonContainer}>
                                <Button
                                  onPress={submitAccessLimit}
                                  style={[
                                    styles.button,
                                    styles.splitButton,
                                    styles.dangerButton,
                                  ]}
                                >
                                  <Text style={styles.buttonText}>
                                    {__("Confirm")}
                                  </Text>
                                </Button>
                              </View>
                            </View>
                          )}
                        </View>
                      </React.Fragment>
                    )}
                  </Accordion.Details>
                </Accordion.Item>

                <Accordion.Item>
                  <Accordion.Summary
                    bg={theme.primaryColor}
                    _expanded={{ backgroundColor: theme.darkerColor }}
                  >
                    {__("Account Control")}
                    <Accordion.Icon />
                  </Accordion.Summary>
                  <Accordion.Details>
                    <View styles={[styles.fullWidth, styles.form]}>
                      <CheckBox
                        type="checkbox"
                        style={styles.bonusCheckbox}
                        onPress={() => {
                          toggleAccountControl("real_money_wagering_exclusion");
                        }}
                        checked={accountConrols.real_money_wagering_exclusion}
                      >
                        <Text style={styles.checkboxLabel}>
                          {__("Exclude your account from real money wagering.")}
                        </Text>
                      </CheckBox>

                      <CheckBox
                        type="checkbox"
                        style={styles.bonusCheckbox}
                        onPress={() => {
                          toggleAccountControl("player_accepts_bonuses");
                        }}
                        checked={!accountConrols.player_accepts_bonuses}
                      >
                        <Text style={styles.checkboxLabel}>
                          {__("Exclude your account from receiving bonuses")}
                        </Text>
                      </CheckBox>

                      <CheckBox
                        type="checkbox"
                        style={styles.bonusCheckbox}
                        onPress={() => {
                          toggleAccountControl("player_accepts_bonuses");
                        }}
                        checked={!accountConrols.player_accepts_bonuses}
                      >
                        <Text style={styles.checkboxLabel}>
                          {__("Exclude your account from receiving free spins")}
                        </Text>
                      </CheckBox>

                      <CheckBox
                        type="checkbox"
                        style={styles.bonusCheckbox}
                        onPress={() => {
                          toggleAccountControl("player_accepts_subscr");
                        }}
                        checked={accountConrols.player_accepts_subscr}
                      >
                        <Text style={styles.checkboxLabel}>
                          {__("Receive e-mail newsletters (and promotions)")}
                        </Text>
                      </CheckBox>

                      {/*<CheckBox
                        type="checkbox"
                        style={styles.bonusCheckbox}
                        onPress={() => {
                          toggleAccountControl("player_receive_text_message");
                        }}
                        checked={accountConrols.player_receive_text_message}
                      >
                        <Text style={styles.checkboxLabel}>
                          {__("Receive SMS text messages (promotions)")}
                        </Text>
                      </CheckBox>*/}
                    </View>
                  </Accordion.Details>
                </Accordion.Item>
              </Accordion>
            </Box>
          </ScrollView>
          <FooterMenu />

          {idUploadSelected && (
            <Box style={styles.uploadButtonsModalOverlay}>
              <Box style={styles.uploadButtonsModal}>
                <Text style={styles.uploadModalHeading}>
                  {__("Upload")}{" "}
                  {idUploadSelected === "id"
                    ? __("ID Document")
                    : idUploadSelected === "poa"
                    ? __("Proof of Address")
                    : __("Other Documents")}
                </Text>

                <Button
                  onPress={selectIdImage}
                  style={{ ...styles.button, ...styles.modalButton }}
                >
                  <Text style={styles.buttonText}>
                    <FontAwesome5 style={styles.buttonIcon} name="images" />{" "}
                    {__("Upload Image")}
                  </Text>
                </Button>

                <Button
                  onPress={() => selectIdDocument()}
                  style={{ ...styles.button, ...styles.modalButton }}
                >
                  <Text style={styles.buttonText}>
                    <FontAwesome5 style={styles.buttonIcon} name="file-pdf" />{" "}
                    {__("Upload PDF")}
                  </Text>
                </Button>

                <Button
                  onPress={cancelIdUpload}
                  style={styles.modalCloseButton}
                >
                  <FontAwesome5
                    style={styles.modalIconText}
                    name="times-circle"
                  />
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
