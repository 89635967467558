import React, { useState, forwardRef, useRef, useEffect, memo } from "react";
import { StyleSheet, Platform } from "react-native";
import { Text, Input, Box, View } from "native-base";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import theme from "services/theme";

const CountryAndPhoneInput = (props, ref) => {
  const phoneCodeValue = props.phoneCodeValue;
  const phoneValue = props.phoneValue;
  
  const phoneCodes = props.phoneCodes;

  const onCodeChange = (newCode) => {
    if (props.onCodeChange) {
      props.onCodeChange(newCode);
    }
  };
  const onPhoneChange = (newVal) => {
    if (props.onPhoneChange) {
      props.onPhoneChange(newVal);
    }
  };

  var styles = StyleSheet.create({
    multiCheckBox: {
      flexDirection: "row",
      borderColor: theme.primaryColor,
      borderWidth: 1,
      borderRadius: 30,
      width: "100%",
    },
    multiCheckBoxDivider: {
      width: 1,
      borderRightWidth: 1,
      marginTop: 8,
      marginBottom: 8,
      borderColor: theme.primaryColor,
    },
    inputBox: {
      width: "100%",
      paddingTop: props.noPadding ? 0 : Platform.OS === "web" ? "9px" : 12,
      marginTop: props.label ? 5 : 0,
    },
    label: {
      fontSize: 14,
      color: theme.primaryColor,
      position: "absolute",
      top: 0,
      marginLeft: 30,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 5,
      paddingRight: 5,
      backgroundColor: "white",
      zIndex: 4,
    },
    innerInputBox: {
      borderWidth: 0,
      borderColor: "white",
    },
  });

  return (
    <Box style={styles.inputBox}>
      {props.label && <Text style={styles.label}>{props.label}</Text>}
      <Box style={styles.multiCheckBox}>
        <View style={{ width: "49%" }}>
          <SelectBox
            borderWidth={0}
            noPadding={true}
            placeholder="Code"
            selectedValue={phoneCodeValue}
            minWidth={40}
            onValueChange={(itemValue) => {
              onCodeChange(itemValue);
            }}
            items={phoneCodes}
          />
        </View>
        <View style={styles.multiCheckBoxDivider}></View>
        <View style={{ width: "49%" }}>
          <Input
            onChangeText={onPhoneChange}
            value={phoneValue}
            fullWidth={true}
            variant="unstyled"
            _focus={styles.innerInputBox}
            _light={{
              placeholderTextColor: theme.placeholderColor + ".400",
            }}
          />
        </View>
      </Box>
    </Box>
  );
};

export default memo(forwardRef(CountryAndPhoneInput));
