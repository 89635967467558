import React, { useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { SafeAreaView, ScrollView } from "react-native";
import RefreshControl from "services/RefreshControl";
import getStyles from "styles/HistoryPages";
import theme from "services/theme";
import api from "services/api";
import { StdAlert, BinaryAlert } from "elements/Alert";
import download from "services/download";
import { View, Button, Text, Box, KeyboardAvoidingView } from "native-base";
import InputBox from "elements/InputBox";
import Hr from "elements/Hr";
import SubPageHeader from "elements/SubPageHeader";
import { Entypo, Feather } from "@expo/vector-icons";
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";
import Pagination from "elements/Pagination";
import NoRecordsFound from "elements/NoRecordsFound";
import Spinner from "elements/Spinner";

export default function FreeSpins(props) {
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);
  const [spinner, setSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [results, setResults] = useState([]);
  const [resultsFetched, setResultsFetched] = useState(false);

  const onPageChange = (page) => {
    doSearch(page);
  };

  const onRefresh = () => {
    doSearch(currentPage);
  };

  const navigation = props.navigation;

  const doSearch = async (page) => {
    setSpinner(true);
    setCurrentPage(page);
    var response = await api.post("/list/free-spins", {
      page: page,
    });
    setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
      setResultsFetched(true);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  useFocusEffect(
    useCallback(() => {
      if (!resultsFetched) {
        doSearch(currentPage);
      }
    }, [resultsFetched])
  );

  const toggleResultExpanded = (index) => {
    var resultsClone = JSON.parse(JSON.stringify(results));
    resultsClone.splice(index, 1, {
      ...results[index],
      expanded: !results[index].expanded,
    });
    setResults(resultsClone);
  };

  const SearchIcon = () => {
    return (
      <Button
        style={styles.appHeaderSearchBtn}
        onPress={() => doSearch(currentPage)}
      >
        <Feather style={styles.appHeaderSearchBtnText} name="refresh-cw" />
      </Button>
    );
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={spinner} />
        <Box style={styles.fullHeight}>
          <AppHeader />
          <ScrollView
            refreshControl={
              <RefreshControl refreshing={spinner} onRefresh={onRefresh} />
            }
          >
            <SubPageHeader
              pageName={__("Free Spins")}
              rightIcon={<SearchIcon />}
              style={styles.subpageHeader}
            />

            <View style={[styles.fullWidth, styles.form]}>
              {!results.length && !spinner && <NoRecordsFound />}

              <View style={styles.searchResults}>
                {results.map((result, index) => {
                  return (
                    <View key={index} style={styles.searchResult}>
                      <Button
                        style={styles.searchArrow}
                        onPress={() => {
                          toggleResultExpanded(index);
                        }}
                      >
                        {result.expanded ? (
                          <Entypo
                            style={styles.searchArrowText}
                            name="chevron-thin-up"
                          />
                        ) : (
                          <Entypo
                            style={styles.searchArrowText}
                            name="chevron-thin-down"
                          />
                        )}
                      </Button>
                      <View style={styles.resultsContainer}>
                        <View style={styles.resultsRow}>
                          <Text style={styles.resultLabel}>{__("Title")}</Text>
                          <Text style={styles.resultText}>{result.title}</Text>
                        </View>
                        <View style={styles.resultsRow}>
                          <Text style={styles.resultLabel}>
                            {__("Remaining Spins")}
                          </Text>
                          <Text style={styles.resultText}>
                            {result.free_spins}
                          </Text>
                        </View>
                        {result.expanded && (
                          <View>
                            <View style={styles.resultsRow}>
                              <Text style={styles.resultLabel}>
                                {__("Awarded Spins")}
                              </Text>
                              <Text style={styles.resultText}>
                                {result.original_free_spins
                                  ? result.original_free_spins
                                  : result.free_spins}
                              </Text>
                            </View>
                            <View style={styles.resultsRow}>
                              <Text style={styles.resultLabel}>
                                {__("Expiry")}
                              </Text>
                              <Text style={styles.resultText}>
                                {result.expiry_date
                                  ? result.expiry_date
                                  : __("No expiry")}
                              </Text>
                            </View>
                            <View style={styles.resultsRow}>
                              <Text style={styles.resultLabel}>
                                {__("Games")}
                              </Text>
                              {!result.games.length && (
                                <Text style={styles.resultText}>
                                  {__("Any Game")}
                                </Text>
                              )}
                              {result.games.map((game, index) => (
                                <Text
                                  onPress={() => {
                                    navigation.navigate("Play", {
                                      slug: game.slug,
                                      mode: "live",
                                    });
                                  }}
                                  style={[
                                    styles.resultText,
                                    { color: theme.primaryColor },
                                  ]}
                                >
                                  {game.game_name},
                                </Text>
                              ))}
                            </View>
                          </View>
                        )}
                      </View>
                    </View>
                  );
                })}
              </View>

              <View style={{ width: "100%", height: 20 }}></View>
            </View>
            <Pagination data={pagination} onPressPage={onPageChange} />
          </ScrollView>
          <FooterMenu />
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
