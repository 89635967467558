import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";

export default function getStyles() {
  var styles = StyleSheet.create({
    container: {
      flexDirection: "row",
      marginTop: 15,
    },
    page: {
      // backgroundColor: "transparent",
      borderColor: theme.primaryColor,
      borderWidth: 1,
      height: 35,
      borderRadius: 0,
      borderRightWidth: 0,
      paddingLeft: 14,
      paddingRight: 14,
      paddingTop: 0,
      paddingBottom: 0
    },
    iconPage: {
      paddingLeft: 12,
      paddingRight: 12,
    },
    prevPage: {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    nextPage: {
      borderRightWidth: 1,
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    pageText: {
      fontFamily: "GilroyLight",
      fontSize: 14,
      lineHeight: 32,
      color: theme.primaryColor,
    },
    prevIcon: {
      fontSize: 18,
    },
    nextIcon: {
      fontSize: 18,
    },
    currentPage: {
      // backgroundColor: theme.primaryColor,
    },
    currentPageText: {
      color: theme.buttonTextColor,
    },
  });

  return styles;
}
