import firebase from "firebase/app";
import "@firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyA3OBPKkCu6jTjjeA-HwCjWJ4VN2L9-T3g",
  authDomain: "casino-xbit.firebaseapp.com",
  projectId: "casino-xbit",
  storageBucket: "casino-xbit.appspot.com",
  messagingSenderId: "475892414275",
  appId: "1:475892414275:web:c509fdeee6eea236c521f6",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export { firebase };
