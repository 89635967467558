import React, { useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { SafeAreaView, ScrollView } from "react-native";
import { StdAlert } from "elements/Alert";
import getStyles from "styles/Profile";
import theme from "services/theme";
import api from "services/api";
import ls from "services/ls";
import RefreshControl from "services/RefreshControl";
import {
  View,
  Center,
  Button,
  Text,
  Box,
  KeyboardAvoidingView,
} from "native-base";
import InputBox from "elements/InputBox";
import Hr from "elements/Hr";
import SubPageHeader from "elements/SubPageHeader";
import CheckBox from "elements/CheckBox";
import SelectBox from "elements/SelectBox";
import DatePicker from "elements/DatePicker";
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";
import ProgressBar from "elements/ProgressBar";
import CountryAndPhoneInput from "elements/CountryAndPhoneInput";
import Image from "elements/Image";
import Spinner from "elements/Spinner";

import LevelBeginnerIcon from "assets/images/level-beginner.svg";

export default function Profile(props) {
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);
  const [spinner, setSpinner] = useState(false);

  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const currencies = [{ label: "XBTU", value: "USD" }];
  const [phoneCodes, setPhoneCodes] = useState([]);

  const [balanceObj, setBalanceObj] = useState({});

  useEffect(() => {
    ls.on("balanceObj", (newVal) => {
      setBalanceObj(newVal);
    });
  }, []);

  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200) {
      setCountries(
        response.data.map((val) => {
          return { label: val.nicename, value: val.code };
        })
      );
      setPhoneCodes(
        response.data.map((val) => {
          return {
            label: "+" + val.phonecode + " (" + val.code + ")",
            value: "+" + val.phonecode,
          };
        })
      );
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const getLanguages = async () => {
    var response = await api.get("/languages");
    if (response.status === 200) {
      setLanguages(
        response.data.map((val) => {
          return { label: val.language, value: val.code };
        })
      );
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const getProfile = async () => {
    setSpinner(true);
    var response = await api.get("/profile");
    setSpinner(false);
    if (response.status === 200) {
      setForm(response.data);
      setIsDataFetched(true);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const submitProfile = async () => {
    setSpinner(true);
    var response = await api.post("/profile/save", {
      oldPassword: form.oldPassword,
      password: form.password,
      repassword: form.repassword,
    });
    setSpinner(false);
    if (response.status === 200) {
      StdAlert("Success!!", response.data.message);
      setForm({
        ...form,
        oldPassword: "",
        password: "",
        repassword: "",
      });
      setIsDataFetched(true);
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    dateOfBirth: "",
    gender: "",
    currency: "",
    language: "",
    phoneCode: "",
    phone: "",
  });

  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getAllData = async (force) => {
    if (!isDataFetched || force) {
      setSpinner(true);
      await getCountries();
      await getLanguages();
      await getProfile();
      // setSpinner(false);
    }
  };

  useFocusEffect(
    useCallback(() => {
      console.log("isDataFetched", isDataFetched);
      try {
        getAllData(false);
      } catch (ex) {}
    }, [isDataFetched])
  );

  const onRefresh = () => {
    getAllData(true);
  };

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        behavior="height"
        style={{ backgroundColor: "white" }}
      >
        <Spinner show={spinner} />
        <Box style={styles.fullHeight} onLayout={onLayoutChange}>
          <AppHeader />
          <ScrollView
            refreshControl={
              <RefreshControl refreshing={spinner} onRefresh={onRefresh} />
            }
          >
            <SubPageHeader
              pageName={__("My Account")}
              style={styles.subpageHeader}
            />
            <Box style={styles.levelProgress}>
              <Text style={styles.levelHeading}>{__("My Level")}</Text>
              <Center width="100%">
                <View style={[styles.flexBox, styles.levelRow]}>
                  <Image
                    type="svg"
                    source={LevelBeginnerIcon}
                    style={{ width: 20, height: 20 }}
                  />
                  <Text>{balanceObj.vip_level}</Text>
                </View>
              </Center>
              <ProgressBar
                size={balanceObj.account_progress}
                style={styles.progressBar}
                color={theme.primaryColor}
              />
              <Text style={styles.progressPercentage}>
                {balanceObj.account_progress}%
              </Text>
            </Box>
            <View style={[styles.fullWidth, styles.form]}>
              <View>
                <Text style={styles.stepHeading}>{__("Personal Details")}</Text>
                <InputBox
                  color={theme.color}
                  value={form.firstName}
                  placeholder={__("First Name")}
                  label={__("First Name")}
                />
                <InputBox
                  color={theme.color}
                  value={form.lastName}
                  placeholder={__("Last Name")}
                  label={__("Last Name")}
                />
                <InputBox
                  color={theme.color}
                  value={form.address}
                  placeholder={__("Address")}
                  label={__("Address")}
                />
                <InputBox
                  color={theme.color}
                  value={form.city}
                  placeholder={__("City")}
                  label={__("City")}
                />
                <InputBox
                  color={theme.color}
                  value={form.state}
                  placeholder={__("State")}
                  label={__("State")}
                />
                <InputBox
                  color={theme.color}
                  value={form.zip}
                  placeholder={__("Zip Code")}
                  label={__("Zip Code")}
                />
                <SelectBox
                  selectedValue={form.country}
                  label={__("Country")}
                  minWidth={200}
                  placeholder={__("Country")}
                  items={countries}
                />
                <DatePicker
                  label={__("Date of Birth")}
                  value={form.dateOfBirth}
                />

                <SelectBox
                  selectedValue={form.gender}
                  label={__("Gender")}
                  minWidth={200}
                  placeholder={__("Gender")}
                  items={[
                    { label: __("Male"), value: "Male" },
                    { label: __("Female"), value: "Female" },
                    { label: __("Other"), value: "Other" },
                  ]}
                />

                <SelectBox
                  selectedValue={form.language}
                  label={__("Language")}
                  minWidth={200}
                  placeholder={__("Language")}
                  items={languages}
                />

                <CountryAndPhoneInput
                  label={__("Phone Number")}
                  phoneCodes={phoneCodes}
                  phoneCodeValue={form.phoneCode}
                  phoneValue={form.phone}
                />
              </View>

              <Hr style={styles.divider} />

              <View>
                <Text style={styles.stepHeading}>{__("Account Details")}</Text>

                <SelectBox
                  selectedValue={form.currency}
                  label={__("Curency of Game Play")}
                  minWidth={200}
                  placeholder={__("Curency of Game Play")}
                  items={[{ label: "XBTU", value: "USD" }]}
                />

                <InputBox
                  label={__("Your Identification Number")}
                  color={theme.color}
                  value={form.pin}
                  placeholder={__("Your Identification Number")}
                />
              </View>

              <Hr style={styles.divider} />

              <View>
                <Text style={styles.stepHeading}>{__("Update Password")}</Text>

                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "oldPassword");
                  }}
                  type="password"
                  color={theme.color}
                  value={form.oldPassword}
                  placeholder={__("Old Password")}
                />

                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "password");
                  }}
                  type="password"
                  color={theme.color}
                  value={form.password}
                  placeholder={__("New Password")}
                />

                <InputBox
                  onChangeText={(value) => {
                    updateForm(value, "repassword");
                  }}
                  type="password"
                  color={theme.color}
                  value={form.repassword}
                  placeholder={__("Re-type Password")}
                />
              </View>

              <Center width="100%">
                <Button onPress={submitProfile} style={styles.submitBtn}>
                  {__("Update Password")}
                </Button>
              </Center>
            </View>
          </ScrollView>
          <FooterMenu />
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
