import React, { Component, useState, useCallback, useEffect } from "react";
import {
  useFocusEffect,
  useNavigation,
  NavigationContainer,
} from "@react-navigation/native";
import { Platform, BackHandler, Alert, SafeAreaView } from "react-native";
import getStyles from "styles/AppHeader";
import { MenuButton, BackButton } from "elements/Buttons";
import AppContext from "contexts/AppContext";
import { View, Box, Button, Text, Icon } from "native-base";
import auth from "services/auth";
import api from "services/api";
import ls from "services/ls";
import moment from "moment";
import Constants from "expo-constants";
import { FontAwesome5 } from "@expo/vector-icons";
import theme from "services/theme";
import Image from "elements/Image";
import Logo from "assets/images/logo.svg";

export default function AppHeader(props) {
  const navigation = useNavigation();
  const routes = navigation.getState().routes;
  const currentRoute = routes[routes.length - 1];
  const [routeName, setRouteName] = useState(currentRoute.name);
  const [user, setUser] = useState(null);
  const [balanceObj, setBalanceObj] = useState({});

  const [styles, setStyles] = useState(getStyles());

  const { updateBalance } = React.useContext(AppContext);

  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  useEffect(() => {
    const backAction = () => {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        Alert.alert("Hold on!", "Are you sure you want to quit?", [
          {
            text: "Cancel",
            onPress: () => null,
            style: "cancel",
          },
          { text: "YES", onPress: () => BackHandler.exitApp() },
        ]);
      }
      return true;
    };

    ls.on("userObj", (newVal) => {
      setUser(newVal);
    });

    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      backAction
    );

    return () => backHandler.remove();
  }, []);

  const getUser = async () => {
    var user = await auth.getUser();
    setUser(user);
  };

  const getBalance = async () => {
    var response = await api.get("/get-balance");
    if (response.status === 200) {
      setBalanceObj(response.data);
      updateBalance(response.data);
    } else {
      setBalanceObj({});
      updateBalance({});
    }
  };

  const openLeftMenu = () => {
    //left menu is 1 level up from the innermost Navigator
    //But we don't need getParent here,
    //because innermost navigator is a stack navigator
    //It will not be able to handle openDrawer and automatically bubble to the parent
    navigation.openDrawer();
  };
  const openRightMenu = () => {
    //Right menu is two levels up from the innermost navigator
    //We need to specify the exact level here, otherwise the second level drawer (leftmenu)
    //will handle the openDrawer event and not bubble up
    try {
      navigation.getParent().getParent().openDrawer();
    } catch (ex) {
      //Non logged
      navigation.openDrawer();
    }
  };

  useFocusEffect(
    useCallback(() => {
      try {
        setRouteName(currentRoute.name);
        getUser();
      } catch (ex) {}
    }, [currentRoute.name])
  );

  useFocusEffect(
    useCallback(() => {
      try {
        if (user) {
          getBalance();
          var balanceFetchInterval = setInterval(() => {
            getBalance();
          }, 10000);
        }
      } catch (ex) {}
      return () => {
        if (balanceFetchInterval) {
          clearInterval(balanceFetchInterval);
        }
      };
    }, [routeName, user])
  );

  return (
    <Box
      style={styles.header}
      onLayout={() => {
        onLayoutChange();
      }}
    >
      <View>
        <Button
          colorScheme="light"
          style={styles.mainMenuButton}
          onPress={openLeftMenu}
        >
          <FontAwesome5 style={styles.icon} name="bars" />
        </Button>
      </View>

      <View style={styles.smallLogoContainer}>
        <Image source={Logo} type="svg" style={styles.smallLogo} />
      </View>
      {user && (
        <View style={styles.balanceContainer}>
          <Text style={styles.balance}>XBU {balanceObj.total_balance}</Text>
        </View>
      )}
      {user && (
        <View>
          <Button
            colorScheme="light"
            style={styles.userMenuButton}
            onPress={openRightMenu}
          >
            <FontAwesome5
              style={[styles.icon, styles.userMenuIcon]}
              name="user-alt"
            />
          </Button>
        </View>
      )}
    </Box>
  );
}
