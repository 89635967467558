import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import { windowHeight, windowWidth, scaleFactor } from "services/scaleFactor";

export default function getStyles() {
  var styles = StyleSheet.create({
    drawerLeft: {
      backgroundColor: theme.primaryColor,
      paddingTop: 20
    },
    drawerRight: {
      backgroundColor: theme.primaryColor,
      paddingTop: 20
    },
    flexBox: {
      flexDirection: "row",
    },
    userName: {
      fontFamily: "GilroyLight",
      paddingLeft: 20,
      color: theme.buttonTextColor,
      fontSize: scaleFactor(18, 20),
    },
    closeBtn: {
      backgroundColor: "transparent",
      marginLeft: "auto",
      marginRight: 10,
    },
    closeBtnIcon: {
      fontSize: scaleFactor(25, 30),
      marginTop: scaleFactor(-12, null, -12),
      color: theme.buttonTextColor,
    },
    infoItem: {
      marginTop: 15,
      paddingLeft: 20,
    },
    infoItemIcon: {
      fontSize: scaleFactor(25, 30),
      lineHeight: scaleFactor(26, 30),
      color: theme.buttonTextColor,
      marginRight: 10,
    },
    infoItemBoldText: {
      fontSize: scaleFactor(15, 17),
      color: theme.buttonTextColor,
      fontFamily: "GilroyExtraBold",
      lineHeight: scaleFactor(26, 30),
    },
    infoItemText: {
      fontSize: scaleFactor(15, 17),
      color: theme.buttonTextColor,
      lineHeight: scaleFactor(26, 30),
      fontFamily: "GilroyLight",
    },
    cashierButton: {
      fontFamily: "GilroyLight",
      fontSize: 13,
      marginTop: 20,
      paddingLeft: 50,
      paddingRight: 50,
      borderRadius: 15,
    },
    cashierButtonText: {
      color: theme.textColor,
    },
    menu: {
      marginTop: 20,
    },
    menuItem: {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 20,
      paddingRight: 20,
      borderColor: theme.shadowColor,
      borderBottomWidth: 1,
    },
    menuText: {
      color: theme.buttonTextColor,
      fontFamily: "GilroyLight",
      fontSize: scaleFactor(15, 17),
      lineHeight: scaleFactor(26, 30),
    },
    logoutIcon: {
      marginRight: 0,
      marginLeft: "auto",
    },
  });

  return styles;
}
// export default styles;
