import React, { Component, useState, useCallback, useEffect } from "react";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import getStyles from "styles/AppHeaderLight";
import { View, Box, Button, Text, Icon, Center } from "native-base";
import { FontAwesome5 } from "@expo/vector-icons";
import theme from "services/theme";

export default function AppHeaderLight(props) {
  const navigation = useNavigation();
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  const openLeftMenu = () => {
    navigation.openDrawer();
  };

  return (
    <Box
      style={styles.header}
      onLayout={() => {
        onLayoutChange();
      }}
    >
      <View>
        <Button style={styles.mainMenuButton} onPress={openLeftMenu}>
          <FontAwesome5 style={styles.icon} name="bars" />
        </Button>
      </View>

      <View style={styles.headerTextBox}>
        <Text style={styles.headerText}>{props.pageName}</Text>
      </View>
    </Box>
  );
}
