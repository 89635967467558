import api from "services/api";
import * as Device from "expo-device";
import { Platform } from "react-native";
import ls from "services/ls";

async function savePushToken(pushToken) {
  if (pushToken === undefined) {
    pushToken = await ls.get("pushToken");
  } else {
    await ls.set("pushToken", pushToken);
  }
  if (pushToken) {
    var formData = {};
    formData.push_token = pushToken;
    formData.platform = Platform.OS;

    formData.model = Platform.select({
      ios: Platform.isPad ? "iPad" : "iPhone",
      default: Device.modelName,
    });
    formData.os = Platform.select({
      ios: "iOS",
      default: Device.osName,
    });

    formData.brand = formData.os === "iOS" ? "apple" : Device.brand;

    formData.os_version = Platform.select({
      ios: Platform.Version,
      default: Device.osVersion,
    });
    formData.isTesting = Platform.isTesting;
    api.post("/save-push-token", formData);
  }
}

export { savePushToken };
