import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import {
  windowHeight,
  windowWidth,
  scaleFactor,
  scrollViewStyle,
} from "services/scaleFactor";

export default function getStyles() {
  var styles = StyleSheet.create({
    flexBox: {
      flexDirection: "row",
    },
    fullHeight: {
      height: Platform.OS === "web" ? window.innerHeight : "100%",
    },
    fullWidth: {
      width: Platform.OS === "web" ? "100vw" : "100%",
    },
    scrollView: { ...scrollViewStyle() },
    tabs: {
      flexDirection: "row",
      borderColor: theme.primaryColor,
      borderWidth: 1,
      borderRadius: 25,
      padding: 1.5,
      marginTop: 15,
      marginBottom: 15,
      overflow: "hidden",
      height: 45,
    },
    tab: {
      borderRadius: 25,
      overflow: "hidden",
      paddingLeft: 13,
      paddingRight: 13,
      paddingTop: 0,
      paddingBottom: 0,
    },
    tabText: {
      fontFamily: "GilroyLight",
      fontSize: 16,
    },
    tabActive: {
      backgroundColor: theme.primaryColor,
    },
    tabTextActive: {
      color: theme.buttonTextColor,
    },
    promotionsMain: {
      paddingLeft: 10,
      paddingRight: 10,
    },
    promotionsMainHeader: {
      fontFamily: "GilroyLight",
      fontSize: 18,
      marginBottom: 8,
    },
    promotionsMainText: {
      fontFamily: "GilroyRegular",
      fontSize: 15,
      marginBottom: 40,
    },
    promotionsSection: {
      paddingLeft: 10,
      paddingRight: 10,
      marginTop: 30,
    },
    promotionSectionImage: {
      borderRadius: 15,
      width: windowWidth() - 20,
      height: (windowWidth() - 20) * 0.408,
    },
    promotionSectionHeader: {
      fontFamily: "GilroySemiBold",
      fontSize: 16,
      color: theme.darkerColor,
      marginTop: 10,
      marginBottom: 7,
    },
    promotionSectionText: {
      fontSize: 14,
      color: theme.textColor,
      marginBottom: 10,
    },
    promotionReadMore: {
      color: theme.primaryColor,
    },
    promotionSectionButtons: {
      flexDirection: "row",
      marginBottom: 20,
      marginTop: 10,
    },
    promotionSectionButton: {
      borderRadius: 20,
      paddingLeft: 20,
      paddingRight: 20,
      marginLeft: 5,
      marginRight: 5,
    },
    promotionSectionButtonText: {
      fontFamily: "GilroySemiBold",
      fontSize: 16,
      color: theme.buttonTextColor,
    },
  });

  return styles;
}
// export default styles;
