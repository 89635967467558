import React from "react";
import { fireabase as firebaseInit } from "services/firebase/config";

import firebase from "firebase/app";
import "firebase/messaging";
import { savePushToken } from "services/pushToken";

export default class PushController extends React.Component {
  componentDidMount() {
    if (firebase.messaging.isSupported()) {
      let pushToken;
      const messaging = firebase.messaging();
      messaging
        .getToken()
        .then((currentToken) => {
          if (currentToken) {
            // console.log("TOKEN:", currentToken);
            pushToken = currentToken;
            savePushToken(pushToken);
          } else {
            console.log("No Token available");
          }
        })
        .catch((error) => {
          console.log("An error ocurred while retrieving token. ", error);
        });

      messaging.onMessage((payload) => {
        console.log("NOTIFICATION:", payload);
        const { title, ...options } = payload.notification;
        navigator.serviceWorker.register("firebase-messaging-sw.js");
        function showNotification() {
          Notification.requestPermission(function (result) {
            if (result === "granted") {
              navigator.serviceWorker.ready.then(function (registration) {
                registration.showNotification(payload.notification.title, {
                  body: payload.notification.body,
                  tag: payload.notification.tag,
                });
              });
            }
          });
        }
        showNotification();
      });
    } else {
      console.log("Push messaging is not supported on this browser");
    }
  }

  render() {
    return null;
  }
}
