import React, { Component, useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Platform, ScrollView, SafeAreaView } from "react-native";
import { WebView } from "react-native-webview";
import * as Linking from "expo-linking";
import getStyles from "styles/Play";
import theme from "services/theme";
import config from "root/config";
import api from "services/api";
import ls from "services/ls";
import auth from "services/auth";
import {
  Center,
  Button,
  Text,
  Box,
  KeyboardAvoidingView,
  Modal,
} from "native-base";
import Br from "elements/Br";
import { StdAlert, BinaryAlert } from "elements/Alert";
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";
import Spinner from "elements/Spinner";

import { orientation } from "services/scaleFactor";

export default function Play(props) {
  const navigation = props.navigation;
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  const { __ } = React.useContext(require("contexts/LangContext").default);

  const gameSlug = props.route.params.slug;
  const gameMode = props.route.params.mode;
  const [gameDetails, setGameDetails] = useState("");
  const [gameSessionId, setGameSessionId] = useState(null);

  const [spinner, setSpinner] = useState(false);
  const [launchUrl, setLaunchUrl] = useState("");

  const openUrlInBrowser = (url) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
      }
    });
  };

  const initGameSession = async () => {
    setSpinner(true);
    var response = await api.post("/game/initsession", {
      slug: gameSlug,
      mode: gameMode,
    });
    if (response.status === 200) {
      if (Platform.OS === "ios" && config.openGamesInBrowser === true) {
        openUrlInBrowser(response.data.launchUrl);
        navigation.navigate("Games");
      } else {
        setLaunchUrl(response.data.launchUrl);
        setGameSessionId(response.data.sessionId);
        setGameDetails(response.data.details);
      }
    } else {
      setSpinner(false);
      StdAlert("Warning!!", response.data.message, () =>
        navigation.navigate("Games")
      );
    }
  };

  useFocusEffect(
    useCallback(() => {
      initGameSession();

      //For pwa
      if (Platform.OS === "web") {
        window.gotoGamesPage = () => {
          navigation.navigate("Games");
        };
      }
    }, [])
  );

  const onWebViewLoaded = (web) => {
    setTimeout(() => {
      setSpinner(false);
    }, 5000);
  };
  const onWebViewLoadedIframe = () => {
    setTimeout(() => {
      setSpinner(false);
    }, 5000);
  };

  const webViewUrlChanged = (state) => {
    //For naive apps
    var url = decodeURI(state.url).toLowerCase();
    var paramsPart = url.split("?")[0];
    // console.log(paramsPart);
    if (paramsPart.indexOf("back-to-games") !== -1) {
      navigation.navigate("Games");
    }
  };

  const [balanceObj, setBalanceObj] = useState({});
  const [user, setUser] = useState(null);

  const getUser = async () => {
    var user = await auth.getUser();
    setUser(user);
  };

  useFocusEffect(
    useCallback(() => {
      getUser();
      ls.on("balanceObj", (newVal) => {
        setBalanceObj(newVal);
      });
      ls.on("userObj", (newVal) => {
        //On logout, it creates error to produce first name just before redirected
        //So, we won't set null as user, but wait for the redirect to take effect
        if (newVal) {
          setUser(newVal);
        }
      });
    }, [])
  );

  const [
    bonusUnsupportedWarningModal,
    setBonusUnsupportedWarningModal,
  ] = useState({ show: false });

  useFocusEffect(
    useCallback(() => {
      if (
        gameDetails &&
        balanceObj &&
        gameMode !== "demo" &&
        !gameDetails.bonus_supported &&
        parseFloat(balanceObj.cash_balance) === 0.0
      ) {
        setBonusUnsupportedWarningModal({ show: true });
      } else {
        setBonusUnsupportedWarningModal({ show: false });
      }
    }, [gameMode, balanceObj.cash_balance, gameDetails])
  );

  const closeAllModals = () => {
    setGameHubMessageModal({ show: false });
    setBonusUnsupportedWarningModal({ show: false });
  };

  //GameHub message functionality
  const [gameHubMessageModal, setGameHubMessageModal] = useState({
    title: "",
    message: "",
    show: false,
  });

  const loadGameHubMessage = async () => {
    var response = await api.post("/load-game-hub_message", {
      provider: gameDetails.game_aggregator,
      game_session_id: gameSessionId,
    });
    if (response.status === 200) {
      if (response.data.type == "found") {
        //There is a message to show.
        var modalData = {
          ...modalData,
          show: true,
          title: "Warning!!",
          message: response.data.gamehub_message.message,
          message_type: response.data.gamehub_message.message_type,
        };

        setGameHubMessageModal(modalData);
      } else if (response.data.type == "session_created") {
        //Session type changed. Message to show and game area to reload
        setGameHubMessageModal({
          ...gameHubMessageModal,
          message_type: response.data.gamehub_message.message_type,
          title: __("Balance type changed!"),
          message: response.data.gamehub_message.message,
          show: true,
        });
        setGameSessionId(response.data.newSession.sessionId);
        setLaunchUrl(response.data.newSession.launchUrl);
      }
    }
  };

  useFocusEffect(
    useCallback(() => {
      var interval = setInterval(function () {
        if (gameMode !== "demo") {
          loadGameHubMessage();
        }
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }, [gameSessionId, gameDetails, user, gameMode])
  );

  const showBonusGames = () => {
    closeAllModals();
    navigation.push("Games", { gameType: "bonus" });
  };

  const navigateTo = (screenName) => {
    props.navigation.push(screenName);
  };
  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={spinner} />

        <Box style={styles.fullHeight}>
          {orientation() === "portrait" ? <AppHeader /> : null}
          <Box style={styles.webView}>
            {launchUrl
              ? Platform.select({
                  web: (
                    <iframe
                      src={launchUrl}
                      style={styles.webView}
                      onLoad={onWebViewLoadedIframe}
                    />
                  ),
                  default: (
                    <Box style={styles.webView}>
                      <WebView
                        flex={1}
                        source={{
                          uri: launchUrl,
                        }}
                        onNavigationStateChange={webViewUrlChanged}
                        onLoad={onWebViewLoaded}
                      />
                    </Box>
                  ),
                })
              : null}
          </Box>
          {orientation() === "portrait" ? <FooterMenu /> : null}
        </Box>

        <Modal
          isOpen={gameHubMessageModal.show}
          onClose={closeAllModals}
          style={{ backgroundColor: "white" }}
        >
          <Modal.CloseButton />
          <Modal.Header>
            <Text>
              {gameHubMessageModal.message_type ==
              "maximum_cash_wagers_per_round"
                ? __("Maximum cash wager limit exceeded!")
                : gameHubMessageModal.message_type ==
                  "maximum_bonus_wagers_per_round"
                ? __("Maximum bonus wagers limit exceeded!")
                : gameHubMessageModal.message_type ==
                  "bonus_balance_not_support"
                ? __("Insufficient balance!")
                : gameHubMessageModal.message_type == "maximum_wagers"
                ? __("Maximum cash wager limit exceeded!")
                : gameHubMessageModal.message_type == "maximum_loss"
                ? __("Maximum cash loss limit exceeded!")
                : gameHubMessageModal.message_type == "maximum_playing_time"
                ? __("Maximum play time limit exceeded!")
                : gameHubMessageModal.title}
            </Text>
          </Modal.Header>
          <Modal.Body>
            <Box>
              {gameHubMessageModal.message_type ==
              "maximum_cash_wagers_per_round" ? (
                <Text>
                  You have exceeded the maximum bet amount for cash money.
                  Please lower the amount and try again. See our{" "}
                  <Text onClick={() => navigateTo("Terms And Conditions")}>
                    General Terms & Conditions
                  </Text>{" "}
                  for more information.
                </Text>
              ) : gameHubMessageModal.message_type ==
                "maximum_bonus_wagers_per_round" ? (
                <Text>
                  You have exceeded the maximum bet amount for bonus money.
                  Please lower the amount and try again. See our{" "}
                  <Text onClick={() => navigateTo("Bonus Terms")}>
                    Bonus Terms
                  </Text>{" "}
                  for more information.
                </Text>
              ) : gameHubMessageModal.message_type ==
                "bonus_balance_not_support" ? (
                <Text>
                  Bonus play is not supported for this game. You do not have
                  sufficient cash balance to play this game. See our{" "}
                  <Text onClick={() => navigateTo("Bonus Terms")}>
                    Bonus Terms
                  </Text>{" "}
                  for more information. Note: For Live Casino games and Sports
                  Betting, Only cash wagers are accepted (no bonus wagers)
                </Text>
              ) : (
                <Text>{gameHubMessageModal.message}</Text>
              )}
            </Box>
          </Modal.Body>
        </Modal>

        <Modal
          isOpen={bonusUnsupportedWarningModal.show}
          onClose={closeAllModals}
          style={{ backgroundColor: "white" }}
        >
          <Modal.CloseButton />
          <Modal.Header>{__("Game playing with bonus money!")}</Modal.Header>
          <Modal.Body>
            <Box>
              <Text>
                {__(
                  "This game is not available for bonus money. Only cash money is accepted."
                )}
              </Text>
              <Br />
              <Text>
                {__(
                  "Note: All Live Casino games are not available for bonus money wagering."
                )}
              </Text>
              <Br />

              <Text>
                <Text onClick={showBonusGames}>{__("Click here")}</Text>{" "}
                {__(
                  "to see which games are available for Bonus Money playing."
                )}
              </Text>
            </Box>
          </Modal.Body>
        </Modal>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
