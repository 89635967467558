import React, { Component, useState, useCallback } from "react";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import getStyles from "styles/GameItem";
import { View, Center, Text, Button } from "native-base";
import Image from "elements/Image";

export default function GameItem({ game }) {
  const navigation = useNavigation();
  const gameImg = game.game_snapshot_url;
  const gameId = game.id;
  const gameName = game.game_name;
  const gameSlug = game.slug;
  const isDemoSupported = !!game.play_for_fun_supported;
  const { __ } = React.useContext(require("contexts/LangContext").default);
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  const openGame = (slug, mode) => {
    navigation.push("Play", { slug: slug, mode: mode });
  };

  return (
    <View style={styles.singleGame} onLayout={onLayoutChange}>
      <Image style={styles.gameImg} source={{ uri: gameImg }} />
      <Text style={styles.gameName}>{gameName}</Text>
      <View style={styles.gameButtons}>
        <Center width="100%">
          {isDemoSupported && (
            <Button
              onPress={() => {
                openGame(gameSlug, "demo");
              }}
              style={styles.button}
            >
              <Text style={styles.buttonText}>{__("Play Demo")}</Text>
            </Button>
          )}
          <Button
            onPress={() => {
              openGame(gameSlug, "live");
            }}
            style={[
              styles.button,
              styles.lastButton,
              isDemoSupported ? {} : styles.singleButton,
            ]}
          >
            <Text style={styles.buttonText}>{__("Play Live")}</Text>
          </Button>
        </Center>
      </View>
    </View>
  );
}
