import React, { useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Platform, SafeAreaView, ScrollView } from "react-native";
import { WebView } from "react-native-webview";
import { StdAlert, BinaryAlert } from "elements/Alert";
import * as Linking from "expo-linking";
import getStyles from "styles/Cashier";
import theme from "services/theme";
import ls from "services/ls";
import api from "services/api";
import {
  View,
  Center,
  Text,
  Icon,
  Input,
  Box,
  KeyboardAvoidingView,
  Button,
} from "native-base";
import InputBox from "elements/InputBox";
import Image from "elements/Image";
import Hr from "elements/Hr";
import CheckBox from "elements/CheckBox";
import SubPageHeader from "elements/SubPageHeader";
import { windowWidth } from "services/scaleFactor";
import { AntDesign, FontAwesome } from "@expo/vector-icons";
import auth from "services/auth";

//Search configuration Icon
import Banner from "assets/images/cashier-banner.jpg";
import CounousxIcon from "assets/images/counousx.svg";
import AppStore from "assets/images/appstore.png";
import PlayStore from "assets/images/playstore.png";
///////////////////////
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";
import Spinner from "elements/Spinner";

export default function Cashier(props) {
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const { __ } = React.useContext(require("contexts/LangContext").default);
  const [balanceObj, setBalanceObj] = useState({});
  const [modal, setModal] = useState(null);
  const [modalBak, setModalBak] = useState(null);
  const depositAmounts = [10, 20, 50, 100, 200, 500, 1000, 5000];
  const [depositAmount, setDepositAmount] = useState("");
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const [depositMethods, setDepositMethods] = useState([]);
  const [depositMethod, setDepositMethod] = useState(null);
  const [bonusCampaigns, setBonusCampaigns] = useState([]);
  const [bonusCampaign, setBonusCampaign] = useState("no_bonus");
  const [depositFinalData, setDepositFinalData] = useState({});
  const [infoUrl, setInfoUrl] = useState("");

  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [withdrawalWallet, setWithdrawalWallet] = useState("");
  const [withdrawalEmail, setWithdrawalEmail] = useState("");
  const [withdrawalMethods, setWithdrawalMethods] = useState([]);
  const [withdrawalMethod, setWithdrawalMethod] = useState(null);
  const [withdrawalDetails, setWithdrawalDetails] = useState({});
  const [
    withdrawalConfirmationModal,
    setWithdrawalConfirmationModal,
  ] = useState(false);

  const [spinner, setSpinner] = useState(false);

  const deleteBonusConfirm = () => {
    BinaryAlert(
      "Warning!!",
      __("Do you want to delete all bonus balance?"),
      async () => {
        setSpinner(true);
        var response = await api.post("/delete-bonus");
        setSpinner(false);
        StdAlert("Warning!!", response.data.message);
      },
      () => {
        // alert("Negative");
      }
    );
  };

  const openUrlInBrowser = (url) => {
    Linking.canOpenURL(url).then((supported) => {
      if (supported) {
        Linking.openURL(url);
      } else {
      }
    });
  };

  const getDepositMethods = async () => {
    if (depositAmount) {
      setSpinner(true);
      var response = await api.post("/deposit/methods", {
        amount: depositAmount,
      });
      setSpinner(false);
      if (response.status === 200) {
        setDepositMethods(response.data.methods);
        if (
          !response.data.methods.filter(
            (method) => method.method_id === depositMethod
          ).length
        ) {
          setDepositMethod(null);
        }
        setBonusCampaigns(response.data.campaigns);
        if (
          !response.data.campaigns.filter(
            (method) => method.method_id === bonusCampaign
          ).length
        ) {
          setBonusCampaign(null);
        }
      }
    }
  };

  const openDepositModal = () => {
    setModal("deposit");
  };

  const showPaymentMethodInfo = (method) => {
    setInfoUrl(method.info_link);
    setModalBak(modal);
    setModal("webview");
  };

  const openWithdrwalModal = () => {
    setModal("withdrawal");
    getWithdrawalMethods();
  };

  const getWithdrawalMethods = async () => {
    // if (withdrawalAmount) {
    setSpinner(true);
    var response = await api.post("/withdraw/methods", {
      amount: withdrawalAmount,
    });
    setSpinner(false);
    if (response.status === 200) {
      setWithdrawalMethods(response.data.methods);
      if (
        !response.data.methods.filter(
          (method) => method.method_id === withdrawalMethod
        ).length
      ) {
        setWithdrawalMethod(null);
      }
      setWithdrawalDetails(response.data.details);
    }
    // }
  };
  const closeModal = () => {
    if (modalBak) {
      setModal(modalBak);
      setModalBak(null);
      setInfoUrl(null);
    } else {
      setModal(null);
    }
  };

  const submitDeposit = async () => {
    setSpinner(true);
    var response = await api.post("/deposit", {
      amount: depositAmount,
      method: depositMethod,
      campaign: bonusCampaign,
    });
    setSpinner(false);
    if (response.status === 200) {
      if (
        response.data.status === "additional_fields" &&
        response.data.fields_for === "counos"
      ) {
        setDepositFinalData(response.data.data);
        setModalBak(null);
        setModal("counous_deposit_final");
      }
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const submitWithdraw = async () => {
    setSpinner(true);
    var response = await api.post("/withdraw", {
      amount: withdrawalAmount,
      method: withdrawalMethod,
      accNumber: withdrawalWallet,
      email: withdrawalEmail,
    });
    setSpinner(false);
    if (response.status === 200) {
      if (response.data.status === "success") {
        StdAlert("Success!!", response.data.message);
        setWithdrawalAmount("");
        setWithdrawalMethod(null);
        setWithdrawalWallet("");
        getUser();
        closeModal();
      }
    } else {
      StdAlert("Warning!!", response.data.message);
    }
  };

  const getUser = async () => {
    var user = await auth.getUser();
    if (user) {
      setWithdrawalEmail(user.email);
    }
  };

  useFocusEffect(
    useCallback(() => {
      getUser();
    }, [])
  );

  useFocusEffect(
    useCallback(() => {
      getDepositMethods();
    }, [depositAmount])
  );

  useFocusEffect(
    useCallback(() => {
      getWithdrawalMethods();
    }, [withdrawalAmount])
  );

  useEffect(() => {
    ls.on("balanceObj", (newVal) => {
      setBalanceObj(newVal);
    });
  }, []);

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        behavior="height"
      >
        <Spinner show={spinner} />
        <Box style={styles.fullHeight} onLayout={onLayoutChange}>
          <AppHeader />
          <ScrollView>
            <Box style={styles.banner}>
              <SubPageHeader
                pageName="Cashier"
                invertedColors={true}
                style={styles.subpageHeader}
              />
              <Box style={styles.bannerText}>
                <Center width="100%" height="100%">
                  <Text style={styles.totalBalance}>
                    XBU {balanceObj.total_balance}
                  </Text>
                  <Text style={styles.totalBalanceText}>Total Balance</Text>
                </Center>
              </Box>
              <Image
                style={styles.bannerImg}
                source={require("assets/images/cashier-banner.jpg")}
              />
              <Center width="100%">
                <Box style={[styles.flexBox, styles.balanceButtons]}>
                  <Button style={styles.balanceButton}>
                    <Text style={styles.balance}>
                      XBU {balanceObj.cash_balance}
                    </Text>
                    <Text style={styles.balanceText}>{__("Cash Balance")}</Text>
                  </Button>
                  <Box>
                    <Button style={styles.balanceButton}>
                      <Text style={styles.balance}>
                        XBB {balanceObj.bonus_balance}
                      </Text>
                      <Text style={styles.balanceText}>
                        {__("Bonus Balance")}
                      </Text>
                    </Button>
                    <Button
                      onPress={deleteBonusConfirm}
                      colorScheme="red"
                      style={styles.deleteBonusButton}
                    >
                      <Text style={styles.deleteBonusText}>
                        {__("Delete Bonus")}
                      </Text>
                    </Button>
                  </Box>
                </Box>
              </Center>
            </Box>

            <Center width="100%">
              <Box style={[styles.flexBox, styles.cashierButtons]}>
                <Button onPress={openDepositModal} style={styles.cashierButton}>
                  <Text style={styles.cashierText}>{__("Deposit")}</Text>
                </Button>
                <Button
                  onPress={openWithdrwalModal}
                  style={styles.cashierButton}
                >
                  <Text style={styles.cashierText}>{__("Withdraw")}</Text>
                </Button>
              </Box>
            </Center>

            <Box style={styles.cashierPage}>
              <Text style={styles.cashierPageText}>
                {__(
                  "To charge your account with cryptocurrencies, you need to specify the amount and type of cryptocurrency in the system. Each time, given the live price of the chosen cryptocurrency, a new payment request is created."
                )}
              </Text>
              <Text style={styles.cashierPageText}>
                {__(
                  "This payment request will include an address, a usage bar, and the calculated amount."
                )}
              </Text>
              <Text style={styles.cashierPageText}>
                {__("You cannot use an address twice.")}
              </Text>
              <Text style={styles.cashierPageText}>
                {__(
                  "Payments made outside the usual process of the system will be lost and not counted toward your balance."
                )}
              </Text>
              <Text style={styles.cashierPageText}>
                {__(
                  "The payment needs to be made in the exact amount and to the exact same address."
                )}
              </Text>
              <Text style={styles.cashierPageText}>
                {__(
                  "Sending a more or less amount and to a different address will result in your payment being lost and it will not be counted toward your balance."
                )}
              </Text>
              <Text style={styles.cashierPageText}>
                {__(
                  "In order for your payment to be counted toward your balance, it needs to receive enough number of confirmations."
                )}
              </Text>
              <Text style={styles.cashierPageText}>
                {__(
                  "Depending on the speed of different networks, this can take up to 1 hour. So, please be patient. If after 2 hours, your payment is not added to your balance, please contact support."
                )}
              </Text>
              <Text style={styles.cashierPageText}>
                {__(
                  "Full liability and responsibility for all the payments made outside of the process explained above is on the customer alone, and the website will bear no liability or responsibility for payments made outside of due process."
                )}
              </Text>
            </Box>
          </ScrollView>
          <FooterMenu />
          {modal === "deposit" && (
            <Box style={styles.gamesFilterOverlay}>
              <Box style={styles.gamesFilter}>
                <View style={styles.flexBox}>
                  <Text style={styles.filterText}>{__("Deposit")}</Text>
                  <Button style={styles.filterCloseBtn} onPress={closeModal}>
                    <Icon
                      as={<AntDesign name="close" />}
                      size="sm"
                      m={2}
                      color={theme.textColor}
                    />{" "}
                  </Button>
                </View>
                <ScrollView>
                  <Text style={styles.amountText}>{__("Amount")}</Text>
                  <Box style={[styles.amounts, styles.flexBox]}>
                    {depositAmounts.map((val, index) => (
                      <Text
                        key={index}
                        onPress={() => {
                          setIsCustomAmount(false);
                          setDepositAmount(val);
                        }}
                        style={[
                          styles.amountBtn,
                          val === depositAmount && !isCustomAmount
                            ? styles.amountBtnSelected
                            : {},
                        ]}
                      >
                        {val}
                      </Text>
                    ))}

                    <Text
                      onPress={() => {
                        setIsCustomAmount(true);
                      }}
                      style={[
                        styles.amountBtn,
                        isCustomAmount ? styles.amountBtnSelected : {},
                      ]}
                    >
                      {__("Custom Amount")}
                    </Text>
                    {isCustomAmount && (
                      <InputBox
                        value={depositAmount.toString()}
                        onChangeText={(val) => setDepositAmount(val)}
                        placeholder={__("Custom Amount")}
                      />
                    )}
                  </Box>

                  <Text style={styles.amountText}>{__("Payment Method")}</Text>
                  {!!depositAmount && (
                    <Text style={styles.amountTextSub}>
                      {__(
                        "Click on the “i” icon within a logo for more infirmation about a payment method."
                      )}
                    </Text>
                  )}
                  {!depositAmount && (
                    <Text style={styles.amountTextSub}>
                      {__(
                        "Please select an amount to load available deposit methods."
                      )}
                    </Text>
                  )}

                  <Box style={styles.paymentMethods}>
                    {depositMethods.map((method, index) => (
                      <Box key={index} style={styles.paymentMethod}>
                        <CheckBox
                          iconStyle={styles.paymentMethodCheckBox}
                          onPress={() => {
                            setDepositMethod(method.method_id);
                          }}
                          checked={depositMethod === method.method_id}
                        >
                          <Image
                            style={styles.paymentMethodIcon}
                            source={{ uri: method.logo }}
                          />
                          <Text style={styles.paymentMethodLabel}>
                            {method.method}
                          </Text>
                        </CheckBox>
                        <Text style={styles.paymentMethodText}>
                          {__("MIN")} {method.min_deposit} XBU (
                          {__("1 XBU Equivalent to 1 USD")})
                        </Text>
                        <Button
                          colorScheme="clear"
                          style={styles.paymentMethodInfoButton}
                          onPress={() => showPaymentMethodInfo(method)}
                        >
                          <AntDesign
                            name="infocirlce"
                            style={styles.paymentMethodInfoIcon}
                          />
                        </Button>
                      </Box>
                    ))}
                  </Box>

                  <Box style={styles.bonusCampaigns}>
                    <Text style={styles.amountText}>
                      {__("Bonus Campaigns")}
                    </Text>

                    {!!depositAmount && (
                      <Text style={styles.amountTextSub}>
                        {__(
                          "Select an appropriate bonus campaign or select 'No Bonus' if you do not want any."
                        )}
                      </Text>
                    )}
                    {!depositAmount && (
                      <Text style={styles.amountTextSub}>
                        {__(
                          "Please select an amount to load available bonus campaigns."
                        )}
                      </Text>
                    )}

                    {bonusCampaigns.map((campaign, index) => (
                      <CheckBox
                        type="checkbox"
                        key={index}
                        style={styles.bonusCheckbox}
                        onPress={() => {
                          setBonusCampaign(campaign.id);
                        }}
                        checked={campaign.id === bonusCampaign}
                      >
                        <View style={{ width: windowWidth() - 90 }}>
                          <Text style={styles.checkboxLabel}>
                            {campaign.bonus_campaign.title}
                          </Text>
                          <Text style={styles.paymentMethodText}>
                            {campaign.bonus_campaign.campaign_information}
                          </Text>
                        </View>
                      </CheckBox>
                    ))}

                    <CheckBox
                      type="checkbox"
                      style={styles.bonusCheckbox}
                      onPress={() => {
                        setBonusCampaign("no_bonus");
                      }}
                      checked={"no_bonus" === bonusCampaign}
                    >
                      <View style={{ width: windowWidth() - 90 }}>
                        <Text style={styles.checkboxLabel}>no_bonus</Text>
                      </View>
                    </CheckBox>
                  </Box>

                  <Center width="100%">
                    <Button style={styles.submitBtn} onPress={submitDeposit}>
                      {__("Proceed")}
                    </Button>
                  </Center>
                  <View style={{ height: 40 }}></View>
                </ScrollView>
              </Box>
            </Box>
          )}
          {modal === "withdrawal" && (
            <Box style={styles.gamesFilterOverlay}>
              <Box style={styles.gamesFilter}>
                <View style={styles.flexBox}>
                  <Text style={styles.filterText}>{__("Withdraw Funds")}</Text>
                  <Button style={styles.filterCloseBtn} onPress={closeModal}>
                    <Icon
                      as={<AntDesign name="close" />}
                      size="sm"
                      m={2}
                      color={theme.textColor}
                    />{" "}
                  </Button>
                </View>
                <ScrollView>
                  <Text style={styles.amountText}>
                    XBU {withdrawalDetails.total_withdrawal_amount}
                  </Text>
                  <Text style={styles.amountTextSub}>
                    {__("Amount that can be withdrawn")}
                  </Text>
                  <Text style={styles.amountTextSub}>
                    (Counos X: {withdrawalDetails.counos_x_withdrawal_amount},
                    XBIT: {withdrawalDetails.xbt_withdrawal_amount})
                  </Text>

                  <InputBox
                    value={withdrawalAmount.toString()}
                    onChangeText={(val) => setWithdrawalAmount(val)}
                    placeholder={__("Amount")}
                  />

                  <Text style={[styles.amountText, { marginTop: 15 }]}>
                    {__("Payment Method")}
                  </Text>
                  <Text style={styles.amountTextSub}>
                    {__(
                      "Click on the “i” icon within a logo for more infirmation about a payment method."
                    )}
                  </Text>

                  <Box style={styles.paymentMethods}>
                    {withdrawalMethods.map((method, index) => (
                      <Box key={index} style={styles.paymentMethod}>
                        <CheckBox
                          iconStyle={styles.paymentMethodCheckBox}
                          onPress={() => {
                            setWithdrawalMethod(method.method_id);
                          }}
                          checked={withdrawalMethod === method.method_id}
                        >
                          <Image
                            style={styles.paymentMethodIcon}
                            source={{ uri: method.logo }}
                          />
                          <Text style={styles.paymentMethodLabel}>
                            {method.method}
                          </Text>
                        </CheckBox>
                        <Text style={styles.paymentMethodText}>
                          {__("MIN")} {method.min_withdrawal} XBU (
                          {__("1 XBU Equivalent to 1 USD")}) **0%{" "}
                          {__(
                            "Transaction free will be deducted from your withdrawal amount."
                          )}
                        </Text>
                        <Button
                          colorScheme="clear"
                          style={styles.paymentMethodInfoButton}
                          onPress={() => showPaymentMethodInfo(method)}
                        >
                          <AntDesign
                            name="infocirlce"
                            style={styles.paymentMethodInfoIcon}
                          />
                        </Button>
                      </Box>
                    ))}
                  </Box>

                  <InputBox
                    value={withdrawalWallet.toString()}
                    onChangeText={(val) => setWithdrawalWallet(val)}
                    placeholder="Wallet Address"
                  />

                  <InputBox
                    value={withdrawalEmail.toString()}
                    onChangeText={(val) => setWithdrawalEmail(val)}
                    placeholder="Email"
                  />

                  <Center width="100%">
                    <Button style={styles.submitBtn} onPress={submitWithdraw}>
                      {__("Submit")}
                    </Button>
                  </Center>
                  <View style={{ height: 20 }}></View>

                  <Box>
                    <Text style={styles.paymentMethodText}>
                      {__("Total locked bonus amount")}:{" "}
                      {withdrawalDetails.locked_bonus_amount} XBB
                    </Text>

                    <Text style={styles.paymentMethodText}>
                      {__("Total bet turnover")}:{" "}
                      {withdrawalDetails.bet_turnover} XBB
                    </Text>
                    <Text style={styles.paymentMethodText}>
                      {__(
                        "Total bet amount necessary to unlock remaining bonus"
                      )}
                      : {withdrawalDetails.amount_2_unlock} XBB
                    </Text>
                  </Box>
                </ScrollView>
              </Box>
            </Box>
          )}
          {modal === "webview" && (
            <Box style={styles.gamesFilterOverlay}>
              <Box style={styles.gamesFilter}>
                <View style={styles.flexBox}>
                  <Text style={styles.filterText}>{__("Method Details")}</Text>
                  <Button style={styles.filterCloseBtn} onPress={closeModal}>
                    <Icon
                      as={<AntDesign name="close" />}
                      size="sm"
                      m={2}
                      color={theme.textColor}
                    />{" "}
                  </Button>
                </View>
                <ScrollView>
                  {Platform.select({
                    web: <iframe src={infoUrl} style={styles.iframe} />,
                    default: (
                      <WebView
                        source={{
                          uri: infoUrl,
                        }}
                        style={[styles.webView]}
                      />
                    ),
                  })}
                  <View style={{ height: 40 }}></View>
                </ScrollView>
              </Box>
            </Box>
          )}
          {modal === "counous_deposit_final" && (
            <Box style={styles.gamesFilterOverlay}>
              <Box style={styles.gamesFilter}>
                <View style={styles.flexBox}>
                  <Text style={styles.filterText}>
                    {__("Final step of deposit")}
                  </Text>
                  <Button style={styles.filterCloseBtn} onPress={closeModal}>
                    <Icon
                      as={<AntDesign name="close" />}
                      size="sm"
                      m={2}
                      color={theme.textColor}
                    />{" "}
                  </Button>
                </View>
                <ScrollView>
                  <Box style={styles.counousDepositFinalStep}>
                    <View style={styles.qrCodeContainer}>
                      <Image
                        style={styles.qrCode}
                        source={{ uri: depositFinalData.paymentUriQrCode }}
                      />
                    </View>
                    <Text style={styles.starInfo}>
                      {__(
                        "To deposit please scan the above QR Code with your Counos Wallet App"
                      )}
                    </Text>
                    <Text style={styles.starInfoReadMore}>
                      {__("Read Carefully")}
                    </Text>
                    <Box style={styles.paymentDetailsBox}>
                      <Text style={styles.paymentDetailsBoxHeader}>
                        {__("Payment Details")}
                      </Text>
                      <View style={[styles.resultsRow]}>
                        <Text style={styles.resultLabel}>
                          {__("Selected Coin")}
                        </Text>
                        <Text style={styles.resultText}>
                          {depositFinalData.selected_coin}
                        </Text>
                      </View>
                      <View style={[styles.resultsRow, styles.evenRow]}>
                        <Text style={styles.resultLabel}>
                          {__("Transaction ID")}
                        </Text>
                        <Text style={styles.resultText}>
                          {depositFinalData.transaction_id}
                        </Text>
                      </View>
                      <View style={[styles.resultsRow]}>
                        <Text style={styles.resultLabel}>
                          {__("Total Amount")}
                        </Text>
                        <Text style={styles.resultText}>
                          {depositFinalData.main_amount}
                        </Text>
                      </View>
                      <View style={[styles.resultsRow, styles.evenRow]}>
                        <Text style={styles.resultLabel}>
                          {__("Expected Amount")}
                        </Text>
                        <Text style={styles.resultText}>
                          {depositFinalData.expected_amount}{" "}
                          {depositFinalData.selected_coin} -{" "}
                          {depositFinalData.selected_ticker}
                        </Text>
                      </View>
                      <View style={[styles.resultsRow]}>
                        <Text style={styles.resultLabel}>
                          {__("Expected Confirmations")}
                        </Text>
                        <Text style={styles.resultText}>
                          {depositFinalData.expectedConfirmations}
                        </Text>
                      </View>
                      <View style={[styles.resultsRow, styles.evenRow]}>
                        <Text style={styles.resultLabel}>
                          {__("Reached Confirmations")}
                        </Text>
                        <Text style={styles.resultText}>
                          {depositFinalData.paymentConfirmations}
                        </Text>
                      </View>
                      <View style={[styles.resultsRow]}>
                        <Text style={styles.resultLabel}>
                          {__("Wallet Address")}
                        </Text>
                        <Text
                          selectable={true}
                          selectionColor={theme.primaryColor}
                          style={styles.resultText}
                        >
                          {depositFinalData.orderAddress}
                          {/*<FontAwesome
                        name="copy"
                        style={styles.paymentMethodInfoIcon}
                      />*/}
                        </Text>
                      </View>
                    </Box>
                    <Text style={styles.depositInfo}>
                      {__(
                        "You can also use the above details to deposit the expected amount to the defined address. If you visit the page using your mobile device you can click on QR code and deposit by Counos Wallet. On the other hand you can use Counos Wallet APP to scan the QR and pay."
                      )}
                    </Text>
                    <Center width="100%">
                      <FontAwesome
                        name="chevron-down"
                        style={styles.downArrowBold}
                      />
                    </Center>
                    <Box style={styles.appLinkArea}>
                      <Button
                        style={styles.appStoreBtn}
                        onPress={() =>
                          openUrlInBrowser(
                            "https://apps.apple.com/ch/app/counos-mobile-wallet/id1408806845?l=en"
                          )
                        }
                      >
                        <Image style={styles.appStore} source={AppStore} />
                      </Button>
                      <Button
                        style={styles.appStoreBtn}
                        onPress={() =>
                          openUrlInBrowser(
                            "https://play.google.com/store/apps/details?id=com.counos"
                          )
                        }
                      >
                        <Image style={styles.appStore} source={PlayStore} />
                      </Button>
                    </Box>

                    <Box style={styles.footerTextArea}>
                      <Text style={styles.footerText}>
                        **
                        {__(
                          "Based on selected Coin, a QR Code will be generated. Then you can scan it by your Counos wallet. When your payment has been done on wallet, just return to this page and click on Continue button. While your payment gets enough confirmation, the payment will be mark as Paid and your account balance will be changed."
                        )}
                      </Text>
                      <Center width="100%">
                        <Button
                          onPress={() =>
                            navigation.navigate("Transaction History")
                          }
                          style={styles.cashierButton}
                        >
                          {__("Done")}
                        </Button>
                      </Center>
                      <View style={{ height: 40 }}></View>
                    </Box>
                  </Box>
                </ScrollView>
              </Box>
            </Box>
          )}
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
