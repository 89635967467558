import React, { Component, useState, useCallback, useEffect } from "react";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { StyleSheet, Platform } from "react-native";
import { View, Button, Text, Icon, Box } from "native-base";
import {
  windowWidth,
  scaleFactor,
  scaleFactorW,
  orientation,
} from "services/scaleFactor";
import Image from "elements/Image";
import PlayImage from "assets/images/play.svg";
import CashierImage from "assets/images/cashier.svg";
import SportsImage from "assets/images/sports.svg";
import SupportImage from "assets/images/support.svg";
import theme from "services/theme";
import auth from "services/auth";
import ls from "services/ls";

export default function FooterMenu(props) {
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };
  const navigation = useNavigation();
  const { __ } = React.useContext(require("contexts/LangContext").default);
  const navigateTo = (screen) => {
    navigation.navigate(screen);
    // setCurrentScreen(screen);
  };

  const [user, setUser] = useState(null);

  const getUser = async () => {
    var user = await auth.getUser();
    setUser(user);
  };

  const gotoCashier = () => {
    if (user) {
      navigateTo("Cashier");
    } else {
      navigateTo("SignUp");
    }
  };

  useEffect(() => {
    getUser();
    ls.on("userObj", (newVal) => {
      setUser(newVal);
    });
  }, []);

  return (
    <React.Fragment>
      <Box style={styles.placeholderMenu} onLayout={onLayoutChange}></Box>
      <Box style={styles.menu}>
        <Button
          colorScheme="clear"
          style={styles.button}
          onPress={() => navigateTo("Games")}
        >
          <Image source={PlayImage} type="svg" style={styles.buttonIcon} />
          <Text style={styles.btnText}>{__("Play")}</Text>
        </Button>
        <Button colorScheme="clear" style={styles.button} onPress={gotoCashier}>
          <Image source={CashierImage} type="svg" style={styles.buttonIcon} />
          <Text style={styles.btnText}>{__("Cashier")}</Text>
        </Button>
        {/*<Button
          colorScheme="clear"
          style={styles.button}
          onPress={() => navigateTo("Sportsbook")}
        >
          <Image source={SportsImage} type="svg" style={styles.buttonIcon} />
          <Text style={styles.btnText}>{__("Sports")}</Text>
  </Button>*/}
        <Button
          colorScheme="clear"
          style={styles.button}
          onPress={() => navigateTo("Support")}
        >
          <Image
            source={SupportImage}
            type="svg"
            style={{ ...styles.buttonIcon, ...styles.buttonIconSupport }}
          />
          <Text style={styles.btnText}>{__("Support")}</Text>
        </Button>
      </Box>
    </React.Fragment>
  );
}

function getStyles() {
  var styles = {
    placeholderMenu: {
      width: "100%",
      marginBottom: Platform.select({
        web: "env(safe-area-inset-top)",
        default: 0,
      }),
      height:
        orientation() == "landscape"
          ? scaleFactorW(50, 70)
          : scaleFactor(65, 70),
    },
    menu: {
      width: "100%",
      height:
        orientation() == "landscape"
          ? scaleFactorW(50, 70)
          : scaleFactor(65, 70),
      position: "absolute",
      left: 0,
      bottom: Platform.select({
        web: "env(safe-area-inset-top)",
        default: 0,
      }),
      zIndex: 16,
      flexDirection: "row",
      backgroundColor: "white",
      shadowColor: "#333333",
      shadowOffset: { width: 0, height: -2 },
      shadowOpacity: 0.2,
      shadowRadius: 2,
      borderColor: theme.shadowColor,
      borderTopWidth: Platform.select({ android: 1, default: 0 }),
    },
    button: {
      width: "33%",
      // backgroundColor: "transparent",
      textAlign: "center",
    },
    buttonIcon: {
      width:
        orientation() == "landscape"
          ? scaleFactorW(15, 25)
          : scaleFactor(25, 25),
      height:
        orientation() == "landscape"
          ? scaleFactorW(15, 25)
          : scaleFactor(25, 25),
      marginLeft: "auto",
      marginRight: "auto",
    },
    buttonIconSupport: {
      width:
        orientation() == "landscape"
          ? scaleFactorW(20, 30)
          : scaleFactor(30, 30),
    },
    btnText: {
      textAlign: "center",
      width: "100%",
      fontFamily: "GilroyRegular",
      fontSize:
        orientation() == "landscape"
          ? scaleFactorW(10, 15)
          : scaleFactor(13, 15),
    },
  };
  return styles;
}
