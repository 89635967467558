import { extendTheme } from "native-base";
import theme from "services/theme";
const nbtheme = extendTheme({
  colors: {
    // Add new color
    primary: {
      50: theme.lighterColor,
      100: theme.lighterColor,
      200: theme.lighterColor,
      300: theme.lighterColor,
      400: theme.lighterColor,
      500: theme.primaryColor, //Button normal
      600: theme.primaryColor,
      700: theme.darkerColor, //Button Hover
      800: theme.darkerColor,
      900: theme.darkerColor,
    },
    light: {
      50: theme.lighterColor,
      100: theme.lighterColor,
      200: theme.lighterColor,
      300: theme.lighterColor,
      400: theme.lighterColor,
      500: theme.lighterColor, //Button normal
      600: theme.lighterColor,
      700: theme.primaryColor, //Button Hover
      800: theme.primaryColor,
      900: theme.primaryColor,
    },
    clear: {
      50: theme.invertedButtonColor,
      100: theme.invertedButtonColor,
      200: theme.invertedButtonColor,
      300: theme.invertedButtonColor,
      400: theme.invertedButtonColor,
      500: theme.invertedButtonColor, //Button normal
      600: theme.invertedButtonColor,
      700: theme.lighterColor, //Button Hover
      800: theme.lighterColor,
      900: theme.lighterColor,
    },
    red: {
      500: theme.invertedButtonColor, //Button normal
      600: theme.invertedButtonColor,
    },
  },
  components: {
    Select: {
      baseStyle: {
        borderColor: theme.primaryColor,
        borderWidth: 1,
      },
      defaultProps: {},
      variants: {},
      sizes: {},
    },
  },
});

export default nbtheme;
