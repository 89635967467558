import React, { Component, useState, useEffect, useCallback } from "react";
import { useFocusEffect } from "@react-navigation/native";
import { Platform, ScrollView, SafeAreaView } from "react-native";
import getStyles from "styles/Play";
import { Center, Button, Text, Box, KeyboardAvoidingView } from "native-base";
import AppHeader from "elements/AppHeader";
import FooterMenu from "elements/FooterMenu";
import Spinner from "elements/Spinner";

export default function BackToGames(props) {
  const navigation = props.navigation;
  const [styles, setStyles] = useState(getStyles());
  const onLayoutChange = () => {
    setStyles(getStyles());
  };

  const [launchUrl, setLaunchUrl] = useState("");

  useFocusEffect(
    useCallback(() => {
      try {
        window.parent.gotoGamesPage();
      } catch (ex) {
        setTimeout(() => {
          navigation.navigate("Games");
        }, 1000);
        navigation.navigate("Games");
      }
    }, [])
  );

  return (
    <SafeAreaView>
      <KeyboardAvoidingView
        style={{ backgroundColor: "white" }}
        onLayout={onLayoutChange}
      >
        <Spinner show={true} />
        <Box style={styles.fullHeight}>
          <AppHeader />

          <FooterMenu />
        </Box>
      </KeyboardAvoidingView>
    </SafeAreaView>
  );
}
