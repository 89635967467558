import React, { useState, forwardRef, useRef, memo } from "react";
import { StyleSheet, Platform } from "react-native";
import { Text, Input, Box, TextArea, Icon } from "native-base";
import { Entypo } from "@expo/vector-icons";
import theme from "services/theme";

const InputBox = (props, ref) => {
  var styles = {
    inputBox: {
      width: "100%",
      paddingTop: Platform.OS === "web" ? "9px" : 12,
      marginTop: props.label ? 5 : 0,
    },
    input: {
      width: "100%",
      borderColor: theme.primaryColor,
      color: theme.inputTextColor,
      height: 45,
      paddingLeft: 3,
      fontSize: 15,
      ...Platform.select({
        web: {
          boxShadow: "none",
        },
      }),
    },
    inputFocused: {
      color: theme.inputTextColor,
      width: "100%",
      borderColor: theme.darkerColor,
    },
    label: {
      fontSize: 14,
      color: theme.primaryColor,
      position: "absolute",
      top: 0,
      marginLeft: 30,
      paddingTop: 2,
      paddingBottom: 2,
      paddingLeft: 5,
      paddingRight: 5,
      backgroundColor: "white",
      zIndex: 4,
    },
  };

  if (Platform.OS !== "web") {
    styles = StyleSheet.create(styles);
  }

  const doNothing = () => {};

  const [type, setType] = useState(props.type);

  const toggleShowPassword = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  return (
    <Box style={[styles.inputBox, props.boxStyle ? props.boxStyle : {}]}>
      {props.label && <Text style={styles.label}>{props.label}</Text>}
      {props.type && props.type === "textarea" ? (
        <TextArea
          h={40}
          style={{
            borderColor: theme.primaryColor,
            width: "100%",
            borderRadius: 20,
          }}
          _focus={{ ...styles.inputFocused, borderRadius: 20 }}
          _light={{
            ...styles.input,
            placeholderTextColor: theme.placeholderColor + ".400",
            borderRadius: 20,
          }}
          {...props}
        />
      ) : props.type && props.type === "password" ? (
        <Input
          ref={ref}
          fullWidth={true}
          variant="rounded"
          _focus={styles.inputFocused}
          _light={{
            ...styles.input,
            placeholderTextColor: theme.placeholderColor + ".400",
          }}
          InputRightElement={
            <Icon
              onPress={toggleShowPassword}
              as={
                type === "password" ? (
                  <Entypo name="eye" />
                ) : (
                  <Entypo name="eye-with-line" />
                )
              }
              size="sm"
              m={2}
              color={theme.shadowColor}
            />
          }
          {...props}
          type={type}
        />
      ) : (
        <Input
          ref={ref}
          fullWidth={true}
          variant="rounded"
          _focus={styles.inputFocused}
          _light={{
            ...styles.input,
            placeholderTextColor: theme.placeholderColor + ".400",
          }}
          {...props}
        />
      )}
    </Box>
  );
};

export default memo(forwardRef(InputBox));
