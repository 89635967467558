import React from "react";
import RenderHtmlReal from "react-native-render-html";
import DOMPurify from "dompurify";
import { Text } from "native-base";

export default function RenderHtml(props) {
  const htmlString = DOMPurify.sanitize(
    props.html && props.html.length
      ? props.html
          .toString()
          .replaceAll("<p></p>", "")
          .replaceAll("<p>&nbsp;</p>", "")
      : "<p></p>"
  );

  return (
    <>
      <Text style={props.style ? props.style : {}}>
        <RenderHtmlReal
          contentWidth="100%"
          source={{
            html: htmlString,
          }}
        />
      </Text>
    </>
  );
}
