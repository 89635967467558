import { StyleSheet, Platform } from "react-native";
import theme from "services/theme";
import {
  windowHeight,
  windowWidth,
  scaleFactor,
  scaleFactorW,
  orientation,
} from "services/scaleFactor";

export default function getStyles() {
  const styles = StyleSheet.create({
    fullHeight: {
      height: Platform.OS === "web" ? window.innerHeight : "100%",
    },
    flexBox: {
      flexDirection: "row",
    },
    gameTypesScrollView: {
      width: "100%",
    },
    gameTypes: {
      backgroundColor: "white",
      flexDirection: "row",
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
    },
    gameTypeButton: {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
      marginRight: 5,
      backgroundColor: "transparent",
    },
    gameTypeButtonActive: {
      backgroundColor: theme.primaryColor,
    },
    gameTypeIcon: {
      width: 30,
      height: 30,
      marginLeft: "auto",
      marginRight: "auto",
    },
    gameTypeText: {
      fontFamily: "GilroyLight",
      color: theme.primaryColor,
      fontSize: 11,
      textAlign: "center",
    },
    gameTypeTextActive: {
      color: theme.buttonTextColor,
    },
    gameSearchBoxOuter: {
      overflow: "visible",
      position: "relative",
      zIndex: 5,
    },
    gameSearchBox: {
      backgroundColor: "white",
      paddingLeft: 10,
      flexWrap: "wrap",
    },
    searchBar: {
      width: windowWidth() - 80,
      marginRight: 10,
      height: 50,
      paddingTop: 2,
      paddingBottom: 0,
    },
    searchBtn: {
      width: 48,
      height: 48,
      borderRadius: 50,
      backgroundColor: theme.lighterColor,
    },
    searchBtnIcon: {
      width: 25,
      height: 25,
    },
    searchResults: {
      display: "none",
      ...Platform.select({
        android: {
          marginLeft: 10,
          marginTop: 1,
        },
        default: {
          position: "absolute",
          top: 51,
          left: 10,
        },
      }),
      backgroundColor: "white",
      borderColor: theme.primaryColor,
      borderRadius: 25,
      minHeight: 50,
      paddingLeft: 10,
      width: windowWidth() - 80,
      borderWidth: 1,
      paddingTop: 7,
      paddingBottom: 7,
    },
    searchResult: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 7,
      paddingBottom: 7,
    },
    gamesListContainerAll: {
      paddingBottom: 30,
      minHeight: windowHeight(),
    },
    gamesListContainer: {
      backgroundColor: "white",
      paddingRight: 5,
      paddingLeft: 5,
    },
    gameListHeader: {
      marginTop: scaleFactor(30, 30),
      marginBottom: scaleFactor(12, 12),
      flexDirection: "row",
    },
    gameListHeaderText: {
      fontFamily: "GilroyExtraBold",
      fontSize: 18,
      paddingLeft: 5,
    },
    gameListHeaderShowMore: {
      marginLeft: "auto",
      fontFamily: "GilroyLight",
      fontSize: 15,
      paddingRight: 5,
      color: theme.darkerColor,
      textDecorationLine: "underline",
    },
    gameList: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
    gamesFilterOverlay: {
      position: "absolute",
      height: "100%",
      top: 0,
      zIndex: 10,
      left: 0,
      width: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    gamesFilter: {
      marginTop: 50,
      height: windowHeight() - 50,
      width: windowWidth(),
      backgroundColor: "white",
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      padding: 20,
      paddingBottom:
        (orientation() == "landscape"
          ? scaleFactorW(50, 70)
          : scaleFactor(65, 70)) + 30,
      flex: 1,
    },
    filterText: {
      textAlign: "center",
      width: windowWidth() - 140,
      marginLeft: 50,
      fontFamily: "GilroySemiBold",
      fontSize: 20,
    },
    filterCloseBtn: {
      width: 50,
      height: 50,
      borderRadius: 22,
      overflow: "hidden",
    },
    vendorsText: {
      fontFamily: "GilroySemiBold",
      fontSize: 18,
      marginBottom: 10,
    },
    vendors: {
      flexWrap: "wrap",
    },
    vendorBtn: {
      borderColor: theme.primaryColor,
      borderWidth: 1,
      color: theme.textColor,
      paddingLeft: 15,
      paddingRight: 15,
      overflow: "hidden",
      borderRadius: 15,
      marginRight: 5,
      marginBottom: 5,
    },
    vendorBtnSelected: {
      backgroundColor: theme.lighterColor,
    },
    vendorBtnText: {
      fontFamily: "GilroyLight",
    },
    applyFilterBtn: {
      fontFamily: "GilroyLight",
      marginTop: 20,
      width: 200,
      borderRadius: 20,
    },
    applyFilterBtnText: {
      fontSize: 15,
      color: theme.buttonTextColor,
    },
    clearFilterBtn: {
      borderColor: theme.primaryColor,
      borderWidth: 1,
      marginTop: 10,
    },
    clearFilterBtnText: {
      color: theme.textColor,
    },
  });

  return styles;
}
// export default styles;
